import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import App from "./App";
import AppStore from "./Utils/Store";
import "antd/dist/antd.css";

import reportWebVitals from "./reportWebVitals";

ReactDOM.render(
  // <React.StrictMode>
  <Provider store={AppStore}>
    <App />
  </Provider>,
  // </React.StrictMode>
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
