import {
  Button,
  Col,
  Form,
  Input,
  Modal,
  Popconfirm,
  Row,
  Select,
  Typography,
} from "antd";
import { useState, useEffect, useRef } from "react";
import { toGetEntityList, toDeleteEntity, toAddOrUpdateEntity } from "./Api";
import { PlusOutlined } from "@ant-design/icons";

import { BiPencil, BiTrash } from "react-icons/bi";
import moment from "moment";
import { errorMsg, successMsg } from "../../Utils/Utils";
import localization from "moment/locale/fr";
import "../../Styles/TwoTables.scss";

import Search from "../../Utils/Search";
import history from "../../Utils/History";
import cookie from "react-cookies";

moment.updateLocale("fr", localization);
const { Option } = Select;

const Entity = (props) => {
  const [createModalVisible, setCreateModalVisible] = useState(false);
  const [filterModalVisible, setFilterModalVisible] = useState(false);
  const [Entitys, setEntitys] = useState([]);
  const [title, setTitle] = useState("");
  const [id, setId] = useState(-1);
  const [form] = Form.useForm();
  const search = useRef(null);

  const fields = [
    { name: "Date de création", field: "createdDate", type: "date" },
    { name: "Titre", field: "title", type: "varchar" },
    { name: "Crée par", field: "createdBy", type: "varchar" },
  ];

  const okFilter = () => {
    search.current.getRules();
  };

  const closeCreateModal = () => {
    if (title == "") {
      errorMsg("veuillez remplir tous les champs obligatoires!");
      return;
    }
    toAddOrUpdateEntity(title, id).then((res) => {
      successMsg(
        id != -1
          ? "Entity mis à jours avec succées!"
          : "Entity ajoutée avec succées!"
      );
      if (id == -1) {
        setEntitys([...Entitys, res]);
      } else {
        const EntityIndex = Entitys.findIndex((e) => e.id == id);
        const newEntitys = [...Entitys];
        newEntitys[EntityIndex] = {
          ...Entitys[EntityIndex],
          ...res,
        };
        setEntitys(newEntitys);
      }
    });
    setId(-1);
    setCreateModalVisible(false);
    setTitle("");
  };

  useEffect(() => {
    if (cookie.load("role") != "Super Admin") history.push("/");

    toGetDatas();
  }, []);
  const toGetDatas = () => {
    toGetEntityList().then((res) => {
      setEntitys(res);
    });
  };
  const toDelete = (id) => {
    toDeleteEntity(id).then((res) => {
      let newEntitys = Entitys.filter((e) => e.id != id);
      setEntitys(newEntitys);
    });
  };

  const toEdit = (id, name) => {
    setId(id);
    setTitle(title);
    form.setFieldsValue({ name: name });
    setCreateModalVisible(true);
  };
  const toAdd = () => {
    setId(-1);
    setTitle("");
    form.setFieldsValue({ name: "" });
    setCreateModalVisible(true);
  };
  const exitCreateModal = () => {
    setId(-1);
    setTitle("");

    setCreateModalVisible(false);
  };

  return (
    <>
      <>
        <Row>
          <Col span="12">
            <Typography.Title level="2">Entités</Typography.Title>
          </Col>
          <Col span="12" style={{ textAlign: "right" }}>
            <Button
              icon={<PlusOutlined />}
              onClick={(e) => toAdd()}
              type="primary"
            >
              Ajouter une entité
            </Button>
          </Col>
        </Row>

        <Row
          style={{
            overflowY: "hidden",
          }}
        >
          <Col span="24">
            <div className="table-wrapper auto--overflow">
              <table className="ant-table" style={{ width: "100%" }}>
                <thead className="ant-table-thead">
                  <tr>
                    <th className="ant-table-cell">Date création</th>
                    <th className="ant-table-cell">Titre</th>
                    <th className="ant-table-cell">Crée par</th>
                    <th className="ant-table-cell"></th>
                  </tr>
                </thead>
                <tbody className="ant-table-tbody ">
                  {null != Entitys &&
                    Entitys.length > 0 &&
                    Entitys.map((Entity, i) => (
                      <tr key={i}>
                        <td className="ant-table-cell">
                          {moment(Entity.createdDate).format("D MMMM YYYY")}
                        </td>
                        <td className="ant-table-cell">{Entity.title}</td>
                        <td className="ant-table-cell">{Entity.createdBy}</td>
                        <td
                          className="ant-table-cell"
                          style={{ float: "right" }}
                        >
                          <Button
                            onClick={() => toEdit(Entity.id, Entity.title)}
                            icon={<BiPencil />}
                          />
                          <Popconfirm
                            title={`Voulez vous vraiment supprimer ${Entity.title}?`}
                            onConfirm={(e) => toDelete(Entity.id)}
                            okText="Oui"
                            cancelText="Non"
                          >
                            <Button icon={<BiTrash />} />
                          </Popconfirm>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </Col>
        </Row>
      </>

      <Modal
        className="modal-small"
        title={"Filtrer"}
        visible={filterModalVisible}
        onOk={okFilter}
        onCancel={(e) => setFilterModalVisible(false)}
      >
        <Search ref={search} fields={fields} list={Entitys} />
      </Modal>

      <Modal
        title={id != -1 ? "Modifier une entité" : "Ajouter une nouvelle entité"}
        visible={createModalVisible}
        onOk={closeCreateModal}
        onCancel={exitCreateModal}
      >
        <Form name="basic" form={form}>
          <Form.Item
            label="Nom"
            name="name"
            rules={[{ required: true, message: "Champ obligatoire" }]}
          >
            <Input value={title} onChange={(e) => setTitle(e.target.value)} />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default Entity;
