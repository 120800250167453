import { AUTH_ACTIONS } from "../Actions/Actions";

const initialState = {
  user: null,
  loaded: false,
};

const AuthReducer = (state = initialState, { type, data }) => {
  switch (type) {
    case AUTH_ACTIONS.LOGIN:
      return {
        ...state,
        user: data.user,
      };
    case AUTH_ACTIONS.LOGOUT:
      return {
        ...state,
        user: null,
      };
    case AUTH_ACTIONS.LOAD_USER:
      return {
        ...state,
        loaded: true,
        user: data.user != null ? data.user : null,
      };
    default:
      return state;
  }
};

export default AuthReducer;
