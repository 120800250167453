import { Typography, Card, Statistic, Tabs, Row, Col, Divider } from "antd";
import { useEffect, useState } from "react";
import cookie from "react-cookies";
import { toGetStatClient, toGetStatSales } from "../Vente/APIs";
import { toGetStatProviders } from "./APIs";
const Achat = () => {
  const colors = ["#1b9e4d", "darkorange", "#ef5350"];
  const [data, setData] = useState(null);
  const [dataSales, setDataSales] = useState(null);
  useEffect(() => {
    toGetStatProviders().then((res) => {
      setData(res);
    });
  }, []);

  return cookie.load("role") == "Super Admin" ? (
    <div className="site-statistic-demo-card">
      <Tabs defaultActiveKey="1">
        <Tabs.TabPane tab="Aujourd'hui" key="1">
          <Typography.Title>Top 3 Fournisseurs</Typography.Title>
          <Row gutter={8}>
            <Col span={8}>
              <Card bordered type="inner" title="Chiffre d'affaires">
                {data &&
                  data[0].ca.today.map((el, i) => (
                    <Statistic
                      title={el.s__name}
                      value={el.value}
                      precision={2}
                      valueStyle={{ color: colors[i] }}
                      suffix=" DT"
                    />
                  ))}
              </Card>
            </Col>
            <Col span={8}>
              <Card bordered type="inner" title="Nombre de bon de réceptions">
                {data &&
                  data[1].nb.today.map((el, i) => (
                    <Statistic
                      title={el.s__name}
                      value={el.value}
                      valueStyle={{ color: colors[i] }}
                      suffix=" "
                    />
                  ))}
              </Card>
            </Col>
          </Row>
        </Tabs.TabPane>
        <Tabs.TabPane tab="Ce mois" key="2">
          <Typography.Title>Top 3 Fournisseurs</Typography.Title>

          <Row gutter={8}>
            <Col span={8}>
              <Card bordered type="inner" title="Chiffre d'affaires">
                {data &&
                  data[0].ca.this_month.map((el, i) => (
                    <Statistic
                      title={el.s__name}
                      value={el.value}
                      precision={2}
                      valueStyle={{ color: colors[i] }}
                      suffix=" DT"
                    />
                  ))}
              </Card>
            </Col>
            <Col span={8}>
              <Card bordered type="inner" title="nombre de bon de réceptions">
                {data &&
                  data[1].nb.this_month.map((el, i) => (
                    <Statistic
                      title={el.s__name}
                      value={el.value}
                      valueStyle={{ color: colors[i] }}
                      suffix=" "
                    />
                  ))}
              </Card>
            </Col>
          </Row>
        </Tabs.TabPane>
        <Tabs.TabPane tab="Dernier 6 mois" key="3">
          <Typography.Title>Top 3 Fournisseurs</Typography.Title>

          <Row gutter={8}>
            <Col span={8}>
              <Card bordered type="inner" title="Chiffre d'affaires">
                {data &&
                  data[0].ca.six_month.map((el, i) => (
                    <Statistic
                      title={el.s__name}
                      value={el.value}
                      precision={2}
                      valueStyle={{ color: colors[i] }}
                      suffix=" DT"
                    />
                  ))}
              </Card>
            </Col>
            <Col span={8}>
              <Card bordered type="inner" title="nombre de bon de réceptions">
                {data &&
                  data[1].nb.six_month.map((el, i) => (
                    <Statistic
                      title={el.s__name}
                      value={el.value}
                      valueStyle={{ color: colors[i] }}
                      suffix=" "
                    />
                  ))}
              </Card>
            </Col>
          </Row>
        </Tabs.TabPane>
      </Tabs>
    </div>
  ) : (
    <></>
  );
};

export default Achat;
