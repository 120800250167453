import {
  PageHeader,
  Button,
  Empty,
  InputNumber,
  Typography,
  Form,
  Descriptions,
  Col,
  Row,
} from "antd";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { FakeInput } from "./FakeInput";

import { CloseOutlined } from "@ant-design/icons";
import {
  removeItem,
  setItemDiscount,
  setEmplacement,
  setItemMarge,
  setItemQuantity,
  setTotal,
  setAchat,
  setTotalDiscount,
  setUnitPriceHT,
  setUnitPriceTTC,
  setSelectedItem,
  setDetails,
} from "../Actions/ItemsSelectionAtions";
import { toGetCode } from "../Pages/Compte/Api";
import history from "../Utils/History";
import ArticleModal from "../Pages/Gestions/ArticleModal";
import {
  toAddOrUpdateArticle,
  toGetArticleDetail,
} from "../Pages/Gestions/Api";
import { successMsg } from "../Utils/Utils";

const ItemsSelection = ({
  achat,
  items,
  discount,
  total,
  totalDiscount,
  selectedItem,
  displayMarge,
  ...props
}) => {
  const [quantityFocused, setQuantityFocused] = useState(false);
  const [totalFocused, setTotalFocused] = useState(false);
  const [disccountTotalFocused, setDisccountTotalFocused] = useState(false);
  const [pDiscountFocused, setPDiscountFocused] = useState(null);
  const [pMargeFocused, setPMargeFocused] = useState(null);
  const [uPriceHtFocused, setUPriceHtFocused] = useState(null);
  const [uPriceTTCFocused, setUPriceTTCFocused] = useState(null);
  const [percentage, setPercentage] = useState(0);
  const [createModalVisible, setCreateModalVisible] = useState(false);

  const [articleDetails, setArticleDetails] = useState({
    title: "",
    reference: "",
    marque: "",
    type: "Origine",
    categorie: "",
  });

  useEffect(() => {
    getTotalMarge();
    toGetCode().then((res) => {
      setPercentage(res.percentage);
    });
  }, [items, total, achat]);

  const exitCreateModal = () => {
    setCreateModalVisible(false);
  };

  const saveArticleItem = (id, article) => {
    toAddOrUpdateArticle(id, article).then((newArticle) => {
      props.setDetails(newArticle);
      successMsg("Article modifié avec succés");

      setCreateModalVisible(false);
    });
  };

  const toEdit = (e, selected) => {
    e.preventDefault();

    if (e.type == "contextmenu") {
      return;
    }

    toGetArticleDetail(selected.article.id).then((article) => {
      setArticleDetails({
        id: article.id,
        titre: article.s__firstTitle,
        titre2: article.s__secondTitle,
        reference: article.s__reference,
        code: article.s__barCode,
        type: article.s__type,
        unite: article.unite,
        marque: article.marqueTitle,
        categorie: article.categoryTitle,
        emplacement:
          null != article.emplacement ? article.emplacement.title : "",
        seuil: article.l__seuil,
        tva: article.tva,
        prixAchatHT: article.l__prixAchatHT,
        prixAchatTTC: article.l__prixAchatTTC,
        data: article.data,
        b__tecdoc: article.b__tecdoc,
        marge: article.l__marge,
        margeValue: article.l__margeValue,
        prixVenteHT: article.l__prixVenteHT,
        prixVenteTTC: article.l__prixVenteTTC,

        margeGros: article.l__margeGros,
        margeGrosValue: article.l__margeGrosValue,
        prixVenteGrosHT: article.l__prixVenteGrosHT,
        prixVenteGrosTTC: article.l__prixVenteGrosTTC,
      });
      setCreateModalVisible(true);
    });
  };

  const togglePDiscountFocused = (ref) => {
    setPDiscountFocused(ref);
    if (ref && ref != selectedItem.ref) {
      props.setSelectedItem(ref);
    }
  };

  const togglePMargeFocused = (ref) => {
    setPMargeFocused(ref);
    if (ref && ref != selectedItem.ref) {
      props.setSelectedItem(ref);
    }
  };

  const toggleQuantityFocused = (ref) => {
    setQuantityFocused(ref);
    if (ref && ref != selectedItem.ref) {
      props.setSelectedItem(ref);
    }
  };

  const toggleUPriceHTFocused = (ref) => {
    setUPriceHtFocused(ref);
    if (ref && ref != selectedItem.ref) {
      props.setSelectedItem(ref);
    }
  };

  const toggleUPriceTTCFocused = (ref) => {
    setUPriceTTCFocused(ref);
    if (ref && ref != selectedItem.ref) {
      props.setSelectedItem(ref);
    }
  };

  const toggleTotalFocused = () => {
    setTotalFocused(!totalFocused);
  };

  const toggleDiscountTotalFocused = () => {
    setDisccountTotalFocused(!disccountTotalFocused);
  };

  const totalDiscountIsHigh = () => {
    const totalAchat = items.reduce((a, b) => b.prix_achat + a, 0);

    const min = (totalAchat * (100 + percentage)) / 100;
    return total < min && achat;
  };

  const getTotalMarge = () => {
    var margeTotal = 0;
    if (items.length > 0) {
      items.map(
        (item) =>
          (margeTotal += parseInt(
            (((item.uPriceTTC * (100 - item.discount)) / 100 -
              item.prix_achat) /
              item.prix_achat) *
              100
          ))
      );

      if (margeTotal > 0) {
        props.getMarge(parseInt(margeTotal / items.length));
      } else {
        props.getMarge(0);
      }
    } else {
      props.getMarge(-1);
    }
  };

  const getTotalHT = () => {
    var total = items.reduce(
      (a, b) => a + ((b.uPriceHt * (100 - b.discount)) / 100) * b.quantity,
      0
    );
    return total;
  };

  const getTotalTTC = () => {
    var total = items.reduce(
      (a, b) => a + ((b.uPriceTTC * (100 - b.discount)) / 100) * b.quantity,
      0
    );
    return total;
  };

  return items != null && items.length > 0 ? (
    <>
      <ArticleModal
        saveArticle={saveArticleItem}
        details={articleDetails}
        createModalVisible={createModalVisible}
        closeCreateModal={exitCreateModal}
      />
      {null != selectedItem && (
        <div style={{ backgroundColor: "#fff" }}>
          <div className="site-page-header-ghost-wrapper" style={{}}>
            <Descriptions size="small" column={4}>
              <Descriptions.Item label="Réf">
                <Typography.Title level={5}>
                  {selectedItem.ref}
                </Typography.Title>
              </Descriptions.Item>
              <Descriptions.Item label="">
                <Typography.Title level={5}>
                  <Typography.Link
                    onClick={(e) => toEdit(e, selectedItem)}
                    onContextMenu={(e) => toEdit(e, selectedItem)}
                  >
                    {selectedItem.titre}
                  </Typography.Link>
                </Typography.Title>
              </Descriptions.Item>
              <Descriptions.Item label="Stock actuel">
                <Typography.Title level={5}>
                  <Typography.Link>
                    <span
                      className={
                        selectedItem.qte_stock <= 0
                          ? "auto--color-danger"
                          : selectedItem.qte_stock < selectedItem.seuil
                          ? "auto--color-warning"
                          : "auto--color-success"
                      }
                    >
                      {selectedItem.qte_stock}
                    </span>
                  </Typography.Link>
                </Typography.Title>
              </Descriptions.Item>
              <Descriptions.Item label="P.vte HT">
                <Typography.Title level={5}>
                  <Typography.Link>
                    {selectedItem.prix_unitaire.toFixed(3)}
                  </Typography.Link>
                </Typography.Title>
              </Descriptions.Item>
            </Descriptions>
          </div>
        </div>
      )}
      <div className="table-wrapper">
        <table className="ant-table">
          <thead className="ant-table-thead">
            <tr>
              <th className="ant-table-cell">Article</th>
              <th className="ant-table-cell">Code à barre</th>
              <th className="ant-table-cell" style={{ width: 90 }}>
                Quantité
              </th>

              <th
                className="ant-table-cell"
                style={{ paddingRight: 0, paddingLeft: 0 }}
              ></th>
            </tr>
          </thead>
          <tbody className="ant-table-tbody">
            {items.map((item, i) => (
              <tr
                key={i}
                className={
                  item.id === selectedItem.id
                    ? achat
                      ? "selected-line-item-achat"
                      : "selected-line-item-vente"
                    : ""
                }
              >
                <td className="ant-table-cell">{item.titre}</td>
                <td className="ant-table-cell">{item.barcode}</td>

                <td className="ant-table-cell" style={{ width: 90 }}>
                  <FakeInput
                    className="tight"
                    condition={quantityFocused === item.ref}
                    value={item.quantity}
                    onBlur={() => toggleQuantityFocused(null)}
                    onClick={() => toggleQuantityFocused(item.ref)}
                    onChange={(value) => props.setItemQuantity(value, item)}
                  />
                </td>

                <td
                  className="ant-table-cell"
                  style={{ paddingRight: 10, paddingLeft: 10 }}
                >
                  <Button
                    icon={<CloseOutlined />}
                    onClick={() => props.removeItem(item)}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div style={{ backgroundColor: "#f0f2f5" }}>
        <div
          className="site-page-header-ghost-wrapper"
          style={{
            backgroundColor: "#f0f2f5",
            border: "2px solid #f0f2f5",
            padding: "1vh 0",
            borderRadius: "25px",
          }}
        ></div>
      </div>
    </>
  ) : (
    <>
      <div
        style={{
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Empty description="Aucun article ajouté"></Empty>
      </div>
      <Row justify="end" gutter={8}>
        <Col xl={6} lg={8}>
          <Button block danger onClick={() => history.goBack()}>
            Retour
          </Button>
        </Col>
      </Row>
    </>
  );
};

const mapStateToProps = ({ itemSelectionState }) => {
  return {
    items: itemSelectionState.items,
    discount: itemSelectionState.discount,
    total: itemSelectionState.total,
    totalDiscount: itemSelectionState.totalDiscount,
    selectedItem: itemSelectionState.selectedItem,
    achat: itemSelectionState.achat,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setTotal: (total) => dispatch(setTotal(total)),
    setAchat: (achat) => dispatch(setAchat(achat)),
    setEmplacement: (achat) => dispatch(setEmplacement(achat)),
    setTotalDiscount: (totalDiscount) =>
      dispatch(setTotalDiscount(totalDiscount)),
    setSelectedItem: (ref) => dispatch(setSelectedItem(ref)),
    setDetails: (article) => dispatch(setDetails(article)),
    removeItem: (item) => dispatch(removeItem(item)),
    setItemQuantity: (quantity, item) =>
      dispatch(setItemQuantity(quantity, item)),
    setItemDiscount: (discount, item) =>
      dispatch(setItemDiscount(discount, item)),
    setItemMarge: (marge, item) => dispatch(setItemMarge(marge, item)),
    setUnitPriceHT: (value, item) => dispatch(setUnitPriceHT(value, item)),
    setUnitPriceTTC: (value, item) => dispatch(setUnitPriceTTC(value, item)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ItemsSelection);
