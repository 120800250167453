import React, { useEffect, useState } from "react";
import Ticker from "react-ticker";
import { toGetUnPaiedBL } from "../Pages/Vente/APIs";
import Parser from "html-react-parser";
import BonLivraisonModal from "../Pages/Vente/BL/BonLivraisonModal";

const GetRatesFromAPI = ({ rates, move, setMove }) => {
  return rates ? (
    <div onMouseOver={() => setMove(false)} onMouseLeave={() => setMove(true)}>
      <p style={{ whiteSpace: "nowrap" }}>{Parser(rates.join(" *** "))} *** </p>
    </div>
  ) : (
    <p style={{ visibility: "hidden" }}>Placeholder</p>
  );
};

function StockTicker() {
  const [move, setMove] = useState(true);
  const [rates, setRates] = useState("");
  const [blToInspect, setBlToInspect] = useState(null);

  useEffect(() => {
    async function fetchData() {
      const ratesFromAPI = await toGetUnPaiedBL();
      setRates(ratesFromAPI);
    }
    fetchData();
  }, []);
  return (
    <>
      <Ticker move={move}>
        {() => <GetRatesFromAPI move={move} rates={rates} setMove={setMove} />}
      </Ticker>

      <BonLivraisonModal
        entete={false}
        id={blToInspect}
        onClose={() => setBlToInspect(null)}
      />
    </>
  );
}

export default StockTicker;
