import {
  Button,
  Col,
  Form,
  Input,
  Modal,
  Popconfirm,
  Row,
  Typography,
  Skeleton,
} from "antd";
import { useState, useEffect } from "react";
import { toGetUniteList, toDeleteUnite, toAddOrUpdateUnite } from "./Api";
import { BiPencil, BiTrash } from "react-icons/bi";
import moment from "moment";
import { errorMsg, successMsg } from "../../Utils/Utils";
import localization from "moment/locale/fr";
import "../../Styles/TwoTables.scss";
import { PlusOutlined } from "@ant-design/icons";

moment.updateLocale("fr", localization);

const Unite = () => {
  const [loading, setLoading] = useState(true);
  const [createModalVisible, setCreateModalVisible] = useState(false);
  const [unites, setUnites] = useState([]);
  const [title, setTitle] = useState("");
  const [id, setId] = useState(-1);
  const [form] = Form.useForm();

  const closeCreateModal = () => {
    if (title == "") {
      errorMsg("veuillez remplir tous les champs obligatoires!");
      return;
    }
    toAddOrUpdateUnite(title, id).then((res) => {
      successMsg(
        id != -1
          ? "Unite mis à jours avec succées!"
          : "Unite ajoutée avec succées!"
      );
      if (id == -1) {
        setUnites([...unites, res]);
      } else {
        const uniteIndex = unites.findIndex((u) => u.id == id);
        const newUnites = [...unites];
        newUnites[uniteIndex] = { ...unites[uniteIndex], ...res };
        setUnites(newUnites);
      }
    });
    setId(-1);
    setCreateModalVisible(false);
    setTitle("");
  };

  useEffect(() => {
    toGetDatas();
  }, []);
  const toGetDatas = () => {
    toGetUniteList().then((res) => {
      setUnites(res);
      setLoading(false);
    });
  };
  const toDelete = (id) => {
    toDeleteUnite(id).then((res) => {
      let newUnites = unites.filter((u) => u.id != id);
      setUnites(newUnites);
    });
  };

  const toEdit = (id, title) => {
    setId(id);
    setTitle(title);
    form.setFieldsValue({ title: title });
    setCreateModalVisible(true);
  };
  const toAdd = () => {
    setId(-1);
    setTitle("");
    form.setFieldsValue({ title: "" });
    setCreateModalVisible(true);
  };
  const exitCreateModal = () => {
    setId(-1);
    setTitle("");

    setCreateModalVisible(false);
  };

  return (
    <>
      <Row>
        <Col span="12">
          <Typography.Title level="2">Entités</Typography.Title>
        </Col>
        <Col span="12" style={{ textAlign: "right" }}>
          <Button
            icon={<PlusOutlined />}
            onClick={(e) => toAdd()}
            type="primary"
            size="large"
          >
            Ajouter une unité
          </Button>
        </Col>
      </Row>

      <Row
        style={{
          overflowY: "hidden",
        }}
      >
        <Col span="24">
          <div className="table-wrapper auto--overflow">
            <table className="ant-table" style={{ width: "100%" }}>
              <thead className="ant-table-thead">
                <tr>
                  <th className="ant-table-cell">Date création</th>
                  <th className="ant-table-cell">Titre</th>
                  <th className="ant-table-cell">Crée par</th>
                  <th className="ant-table-cell"></th>
                </tr>
              </thead>
              <Skeleton active loading={loading} />

              <tbody className="ant-table-tbody ">
                {null != unites &&
                  unites.length > 0 &&
                  unites.map((unite, i) => (
                    <tr key={i}>
                      <td className="ant-table-cell">
                        {moment(unite.createdDate).format("D MMMM YYYY")}
                      </td>
                      <td className="ant-table-cell">{unite.title}</td>
                      <td className="ant-table-cell">{unite.createdBy}</td>
                      <td className="ant-table-cell" style={{ float: "right" }}>
                        <Button
                          onClick={() => toEdit(unite.id, unite.title)}
                          icon={<BiPencil />}
                        />

                        <Popconfirm
                          title={`Voulez vous vraiment supprimer ${unite.title}?`}
                          onConfirm={(e) => toDelete(unite.id)}
                          okText="Oui"
                          cancelText="Non"
                        >
                          <Button icon={<BiTrash />} />
                        </Popconfirm>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </Col>
      </Row>
      <Modal
        title={id != -1 ? "Modifier un Unite" : "Ajouter un nouveau Unite"}
        visible={createModalVisible}
        onOk={closeCreateModal}
        onCancel={exitCreateModal}
      >
        <Form name="basic" form={form}>
          <Form.Item
            label="Nom"
            name="title"
            rules={[{ required: true, message: "Champ obligatoire" }]}
          >
            <Input onChange={(e) => setTitle(e.target.value)} />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default Unite;
