import {
  Button,
  Col,
  Row,
  Typography,
  Form,
  Input,
  Popconfirm,
  Skeleton
} from "antd";
import { useEffect, useState } from "react";
import { InfoCircleTwoTone } from "@ant-design/icons";
import { BiTrash, BiPencil } from "react-icons/bi";

import { toGetClientsList, toDeleteClient } from "../APIs";
import ClientModal from "./ClientModal";
import ClientDetail from "./ClientDetail";
import ClientHistorique from "./ClientHistorique";
import Client from "../../../Models/Client";
import { customFilter, successMsg, toConvertDate } from "../../../Utils/Utils";
import moment from "moment";
import localization from "moment/locale/fr";
import ls from "localstorage-ttl";
import cookie from "react-cookies";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import printJS from "print-js";
import StateTag from "../../../Components/StateTag";

moment.updateLocale("fr", localization);

const colors = [
  "#00aeef",
  "#72cbf2",
  "#4eb4e6",
  "#2696d3",
  "#108d9a",
  "#58c5cc",
  "#19b4bb",
  "#086d72",
  "#5c3494",
  "#846eb1",
  "#694a9e",
  "#432c79"
];
const Clients = ({ AppStore, ...Props }) => {
  const [clients, setClients] = useState([]);
  const [clientsAll, setClientsAll] = useState([]);
  const [loading, setLoading] = useState(true);
  const [historique, setHistorique] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [state, setState] = useState({
    createModalVisible: false,
    clientForm: new Client(),
    readonly: false
  });

  const [detailState, setDetailState] = useState({
    client: new Client(),
    visible: false
  });

  const openCreateModal = () =>
    setState({
      clientForm: new Client(),
      createModalVisible: true,
      readonly: false
    });

  const openReadOnlyModal = (client) =>
    setState({ clientForm: client, createModalVisible: true, readonly: true });

  const closeCreateModal = () => {
    setState({ createModalVisible: false, clientForm: null, readonly: false });
  };

  const editClient = (client) => {
    setDetailState({ client: new Client(), visible: false });
    setState({ createModalVisible: true, clientForm: client, readonly: false });
  };

  const confirmDelete = (client) => {
    toDeleteClient(client.id).then(() => {
      let newClients = clients.filter((c) => c.id !== client.id);
      setClients(newClients);
    });
  };

  const onModalConfirm = (client) => {
    if (state.clientForm.id == null) {
      if (client) setClients([client, ...clients]);
      closeCreateModal();
      successMsg("Client créé avec succés");
    } else {
      const clientIndex = clients.findIndex((c) => c.id === client.id);
      const newClients = [...clients];
      newClients[clientIndex] = { ...client };
      setClients(newClients);
      successMsg("Client modifié avec succés");
      closeCreateModal();
    }
  };

  const onSearch = (e) => {
    setSearchValue(e.target.value);
    const updatedList = customFilter(e.target.value, clientsAll, [
      "s__name",
      "s__code",
      "s__phoneNumber",
      "vehicules[0].s__model",
      "vehicules[0].s__matricule",
      "vehicules[0].s__vin"
    ]);
    setClients(updatedList);
  };

  useEffect(() => {
    AppStore.setFirst(1);
    AppStore.setSecond(6);

    if (null != ls.get("clients")) {
      setClients(ls.get("clients"));
      setClientsAll(ls.get("clients"));
      setLoading(false);
    }
    toGetClientsList().then((_clients) => {
      setClients(_clients);
      setClientsAll(_clients);
      setLoading(false);
    });
  }, []);

  const openClientDetail = (client) => {
    setDetailState({ client: client, visible: true });
  };
  const dismissClientModalDetail = () => {
    setDetailState({ client: new Client(), visible: false });
  };
  const getClients = () => {
    var res = [];
    var items = [];
    var x = 0;
    clients.forEach((element, i) => {
      x++;
      if (x > 12) {
        x = 0;
      }
      items.push(
        <Col span="6">
          <div
            className="auto--pointer"
            onClick={() => openClientDetail(element)}
          >
            <div style={{ position: "absolute", width: "100%", top: "" }}>
              <div>
                <div
                  style={{ position: "absolute" }}
                  className="auto--circle"
                  onClick={() => openClientDetail(element)}
                  style={{ backgroundColor: colors[x] }}
                >
                  {element.s__code}
                </div>
                <span
                  style={{
                    position: "absolute"
                  }}
                  className="auto--client-name"
                  onClick={() => editClient(element)}
                  style={{
                    backgroundColor:
                      element.d__threshold > 0 &&
                      element.d__threshold < element.unpaied
                        ? "red"
                        : element.b__billPerMonth
                        ? "#69c0ff"
                        : element.b__billPerWeek
                        ? "#846eb1"
                        : "#fafafa",
                    marginTop: "10px"
                  }}
                >
                  {element.s__name}
                </span>
              </div>
            </div>
            <div
              style={{
                position: "relative",
                width: "150px",
                height: "150px",
                marginTop: "50px"
              }}
            >
              <CircularProgressbar
                styles={buildStyles({
                  pathColor: `#7193ca`
                })}
                onClick={() => openClientDetail(element)}
                strokeWidth={5}
                value={element.percentage__quote}
              />
            </div>
          </div>
        </Col>
      );
      if ((i + 1) % 4 == 0 || i + 1 == clients.length) {
        res.push(<Row style={{ marginTop: "2vh" }}>{items}</Row>);
        items = [];
      }
    });
    return res;
  };

  const setBLocked = (id) => {
    const l__clients = [...clients];
    const i = l__clients.findIndex((c) => c.id == id);
    l__clients[i].b__locked = !l__clients[i].b__locked;
    setClients(l__clients);
    openClientDetail(l__clients[i]);
  };

  return (
    <div id="vente">
      {/* <Row gutter={24} justify="center">
        <Col span={6}>
          <div class="auto--circle">
            <span style={{ marginTop: "70px" }}>001</span>
          </div>
        </Col>
        <Col span={6}>
          <div class="auto--circle"></div>
        </Col>
        <Col span={6}>
          <div class="auto--circle"></div>
        </Col>
        <Col span={6}>
          <div class="auto--circle"></div>
        </Col>
      </Row> */}
      <Row className="auto--custom-header">
        <Col span="7">
          <Typography.Title level="2">Clients</Typography.Title>
        </Col>
        <Col span="5" style={{ textAlign: "right" }} justify="end">
          <Form.Item label="Recherche">
            <Input.Search
              onInput={onSearch}
              value={searchValue}
              placeholder="Recherche par référence, client, total..."
              enterButton
            />
          </Form.Item>
        </Col>
        <Col span="10" style={{ textAlign: "right" }}>
          <Button
            style={{ marginLeft: "1vh" }}
            onClick={() => {
              const data = clientsAll.map((cl) => {
                return { code: cl.s__code, nom: cl.s__name };
              });

              printJS({
                printable: data,
                style: "body { margin: 0; font-size: 12px; }",
                properties: ["code", "nom"],
                type: "json"
              });
            }}
            type="primary"
            size="large"
          >
            Exporter
          </Button>
          <Button
            onClick={openCreateModal}
            style={{ marginLeft: "1vh" }}
            type="primary"
            size="large"
          >
            Ajouter un client
          </Button>

          {cookie.load("role") == "Super Admin" && (
            <Button
              onClick={() => setHistorique(true)}
              type="primary"
              style={{ marginLeft: "1vh" }}
              size="large"
            >
              Historique
            </Button>
          )}
        </Col>
      </Row>
      {/* <div className="table-wrapper auto--overflow">{getClients()}</div> */}
      <Row>
        <Col span="24">
          <div className="table-wrapper auto--overflow">
            <table className="ant-table" style={{ width: "100%" }}>
              <thead className="ant-table-thead">
                <tr>
                  <th className="ant-table-cell">Date création</th>
                  <th className="ant-table-cell">Nom</th>
                  <th className="ant-table-cell">Code</th>
                  <th className="ant-table-cell">Téléphone</th>

                  <th className="ant-table-cell">TVA</th>
                  <th className="ant-table-cell">Timbre</th>

                  <th className="ant-table-cell">Gros</th>
                  <th className="ant-table-cell"></th>
                </tr>
              </thead>
              <Skeleton active loading={loading} />

              <tbody className="ant-table-tbody">
                {clients &&
                  clients.map((client, i) => (
                    <tr
                      key={i}
                      style={{
                        backgroundColor: client.b__billPerMonth
                          ? "#69c0ff"
                          : "white"
                      }}
                    >
                      <td className="ant-table-cell">
                        {moment(client.created__date).format("D MMMM YYYY")}
                      </td>
                      <td
                        className="ant-table-cell"
                        style={{ cursor: "pointer" }}
                        onClick={() => openReadOnlyModal(client)}
                        style={{ cursor: "pointer" }}
                      >
                        {client.s__name}
                      </td>
                      <td className="ant-table-cell">{client.s__code}</td>
                      <td className="ant-table-cell">
                        {client.s__phoneNumber}
                      </td>

                      <td className="ant-table-cell">
                        <StateTag state={client.b__tva} />
                      </td>
                      <td className="ant-table-cell">
                        <StateTag state={client.b__stamp} />
                      </td>

                      <td className="ant-table-cell">
                        <StateTag state={client.b__wholesaler} />
                      </td>
                      <td className="ant-table-cell">
                        <Button
                          icon={<InfoCircleTwoTone />}
                          onClick={() => openClientDetail(client)}
                        />
                        <Button
                          icon={<BiPencil />}
                          onClick={() => editClient(client)}
                        />
                        <Popconfirm
                          title={`Voulez vous vraiment supprimer ${client.s__name}?`}
                          onConfirm={() => confirmDelete(client)}
                          okText="Oui"
                          cancelText="Non"
                        >
                          <Button
                            disabled={client.b__locked}
                            icon={<BiTrash />}
                          />
                        </Popconfirm>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </Col>
      </Row>
      {state.clientForm && (
        <>
          <ClientModal
            clientForm={state.clientForm}
            visible={state.createModalVisible}
            onCancel={closeCreateModal}
            onClientSaved={onModalConfirm}
            readonly={state.readonly}
          />
        </>
      )}
      <ClientDetail
        setBLocked={setBLocked}
        client={detailState.client}
        visible={detailState.visible}
        dismiss={dismissClientModalDetail}
      />

      <ClientHistorique
        clients={clients}
        visible={historique}
        dismiss={() => setHistorique(false)}
      />
    </div>
  );
};

export default Clients;
