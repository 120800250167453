import { Layout } from "antd";

const CenteredLayout = ({ children }) => {
    return (
        <Layout style={{ minHeight: "100vh" }}>
            <Layout.Content style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                {children}
            </Layout.Content>
        </Layout>
    )
}


export default CenteredLayout;