export default class Preferences {
  id;
  s__adress = "";
  s__phone = "";
  s__mobile = "";
  s__fax = "";
  s__email = "";
  s__mF = "";
  s__rC = "";
  s__cCB = "";
  s__fB = "";
}
