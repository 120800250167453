import { useState } from "react";

import {
  Input,
  Button,
  Row,
  Select,
  Space,
  DatePicker,
  InputNumber,
} from "antd";
import locale from "antd/lib/locale/fr_FR";
import { PlusOutlined, CloseOutlined, SearchOutlined } from "@ant-design/icons";
import moment from "moment";

const Search = (props) => {
  const { Option } = Select;
  const tab = props.fields;
  const list = props.list;
  const rule = {
    firstSelect: "0",
    secondSelect: "0",
    thirdSelected: "0",
    value: "",
    isValid: true,
  };
  const [rules, setRules] = useState([rule]);

  const toCheckRules = () => {
    let newrules = [...rules];
    var isValid = true;

    for (let index = 0; index < newrules.length; index++) {
      const element = newrules[index];

      if (element.value == "" && tab[element.firstSelect].type != "boolean") {
        newrules[index].isValid = false;
        isValid = false;
      } else {
        newrules[index].isValid = true;
      }
    }
    setRules(newrules);
    return isValid;
  };

  const getOperators = (i) => {
    const StringOperations = [
      { value: "Commence par", key: 0 },
      { value: "Se termine par", key: 1 },
      { value: "Contient", key: 2 },
      { value: "Ne contient pas", key: 3 },
    ];

    const DateOperations = [
      { value: "Supérieur à", key: 0, op: " > " },
      { value: "Inférieur à", key: 1, op: " < " },
      { value: "Entre", key: 2, op: " <> " },
      { value: "Année", key: 3, op: "==" },
      { value: "Mois", key: 4, op: "==" },
      { value: "Jours", key: 5, op: "==" },
      //{ value: "Aujourd'hui", key: 6, op: "==" },
      //{ value: "Hier", key: 7, op: "==" },
    ];

    const BooleanOperations = [
      { value: "Oui", key: 0, op: true },
      { value: "Non", key: 1, op: false },
    ];

    const NumberOperations = [
      { value: "Egal à", key: 0, op: "==" },
      { value: "Supérieur à", key: 1, op: ">" },
      { value: "Supérieur égal à", key: 2, op: ">=" },
      { value: "Inférieur à", key: 3, op: "<" },
      { value: "Inférieur égal à", key: 4, op: "<=" },
      { value: "Entre", key: 5, op: "<>" },
    ];

    switch (i) {
      //case String
      case "varchar":
        return StringOperations;
      //case Date
      case "date":
        return DateOperations;
      //case boolean
      case "boolean":
        return BooleanOperations;
      case "number":
        return NumberOperations;

      default:
        return [];
    }
  };
  const onFirstChange = (e, x) => {
    let newArr = [...rules];
    newArr[x].firstSelect = e;
    newArr[x].secondSelect = 0;
    newArr[x].thirdSelected = 0;
    newArr[x].value = "";
    setRules([]);

    setRules(newArr);
  };

  const onSecondChange = (e, x) => {
    let newArr = [...rules];
    newArr[x].secondSelect = e;
    setRules(newArr);
  };

  const removeRule = (e, x) => {
    let newArr = [...rules];
    newArr.splice(x, 1);
    setRules(newArr);
  };

  const addRule = (e) => {
    let newArr = [...rules];
    newArr.push(rule);
    setRules([]);
    setRules(newArr);
  };
  const getAdequatInput = (i, x) => {
    //simple input
    if (i == "varchar") return 0;
    if (i == "number") return 10;
    //date
    if (i == "date") {
      if (x == 0 || x == 1) {
        return 1;
      }
      return x;
    }
  };

  const onThirdChange = (e, x) => {
    let newArr = [...rules];
    newArr[x].thirdSelected = e;
    setRules(newArr);
  };

  const onChangeMonth = (value, j) => {
    let newArr = [...rules];
    newArr[j].value = moment(value).month() + 1;
    setRules(newArr);
  };

  const onChange = (value, j) => {
    let newArr = [...rules];
    newArr[j].value = value;
    setRules(newArr);
  };

  const search = () => {
    if (!toCheckRules()) return;
    var filtredList = [...list];
    rules.map((rule, i) => {
      switch (tab[rule.firstSelect].type) {
        case "varchar":
          filtredList = getVarcharResult(rule, filtredList);
          break;
        case "number":
          filtredList = getNumberResult(rule, filtredList);
          break;
        case "date":
          filtredList = getDateResult(rule, filtredList);
          break;
        case "boolean":
          filtredList = getBooleanResult(rule, filtredList);
          break;

        default:
          break;
      }
    });
    props.toUpdateData(filtredList);
  };

  const reset = () => {
    setRules([rule]);
    props.toUpdateData(props.list);
  };

  const getVarcharResult = (rule, list) => {
    var field = tab[rule.firstSelect].field;

    switch (rule.secondSelect) {
      case "0":
        //commence par

        return list.filter(
          (element) =>
            element[field].startsWith(rule.value.toUpperCase()) ||
            element[field].startsWith(rule.value.toLowerCase())
        );

      case "1":
        //se termine par
        return list.filter(
          (element) =>
            element[field].endsWith(rule.value.toUpperCase()) ||
            element[field].endsWith(rule.value.toLowerCase())
        );

      case "2":
        //contient
        return list.filter(
          (element) =>
            element[field] &&
            (element[field].includes(rule.value.toUpperCase()) ||
              element[field].includes(rule.value.toLowerCase()))
        );

      case "3":
        //ne contient pas
        return list.filter(
          (element) =>
            !element[field].includes(rule.value.toUpperCase()) &&
            !element[field].includes(rule.value.toLowerCase())
        );

      default:
        break;
    }
  };

  const getNumberResult = (rule, list) => {
    var field = tab[rule.firstSelect].field;

    return list.filter((element) =>
      eval(
        element[field] +
          getOperators(tab[rule.firstSelect].type)[rule.secondSelect].op +
          rule.value
      )
    );
  };

  const getBooleanResult = (rule, list) => {
    var field = tab[rule.firstSelect].field;
    return list.filter(
      (element) =>
        element[field] ==
        getOperators(tab[rule.firstSelect].type)[rule.secondSelect].op
    );
  };
  const getDateResult = (rule, list) => {
    var field = tab[rule.firstSelect].field;

    switch (rule.secondSelect) {
      case "0":
        //supérieur

        return list.filter((element) => element[field] > rule.value);

      case "1":
        //inférieur
        return list.filter((element) => element[field] < rule.value);

      case "2":
        //entre
        return list.filter(
          (element) =>
            element[field] > rule.value[0] && element[field] < rule.value[1]
        );
      case "3":
        //année
        return list.filter(
          (element) => moment(element[field]).year() == rule.value
        );

      case "4":
        //mois
        return list.filter(
          (element) => moment(element[field]).month() + 1 == rule.value
        );

      case "5":
        //jours
        return list.filter(
          (element) => moment(element[field]).format("D") == rule.value
        );
      case "6":
        //aujourd'hui
        return list.filter(
          (element) =>
            moment(element[field]).format("d-M-y") ==
            moment(new Date()).format("d-M-y")
        );
      case "7":
        //hier
        return list.filter(
          (element) =>
            moment(element[field]).format("d-M-y") ==
            moment().subtract(1, "days").format("d-M-y")
        );

      default:
        break;
    }
  };

  return (
    <div className="auto--overflow">
      <Row>
        {rules.map((rule, j) => (
          <div
            className={
              rule.isValid
                ? "ant-table-footer "
                : "ant-table-footer auto-border-red"
            }
            style={{
              marginLeft: "2vh",
              marginTop: "1vh",
              borderRadius: "1vh",
            }}
          >
            <Space>
              {rules.length > 1 && (
                <Button
                  danger
                  shape="circle"
                  size="default"
                  style={{ marginLeft: "1vh" }}
                  onClick={(e) => removeRule(e, j)}
                  icon={<CloseOutlined />}
                />
              )}
              <Select
                onChange={(e) => onFirstChange(e, j)}
                defaultValue={tab[0].name}
                style={{ width: "25vh" }}
              >
                {tab.map((element, i) => (
                  <Option selected={rule.firstSelect === i} key={i} value={i}>
                    {element.name}
                  </Option>
                ))}
              </Select>

              <Select
                style={{ width: "25vh" }}
                defaultValue={getOperators(tab[rule.firstSelect].type)[0].value}
                onChange={(ev) => onSecondChange(ev, j)}
              >
                {getOperators(tab[rule.firstSelect].type).map((op, t) => (
                  <Option selected={rule.secondSelect === t} key={t}>
                    {op.value}
                  </Option>
                ))}
              </Select>

              {getAdequatInput(
                tab[rule.firstSelect].type,
                rule.secondSelect
              ) === 0 && (
                <Input onChange={(e) => onChange(e.target.value, j)} />
              )}
              {getAdequatInput(
                tab[rule.firstSelect].type,
                rule.secondSelect
              ) === 1 && (
                <DatePicker
                  style={{ width: "25vh" }}
                  onChange={(e, date) => onChange(date, j)}
                />
              )}
              {getAdequatInput(tab[rule.firstSelect].type, rule.secondSelect) ==
                2 && (
                <DatePicker.RangePicker
                  onChange={(e, date) => onChange(date, j)}
                />
              )}

              {getAdequatInput(tab[rule.firstSelect].type, rule.secondSelect) ==
                3 && (
                <DatePicker.YearPicker
                  style={{ width: "25vh" }}
                  onChange={(e, date) => onChange(date, j)}
                />
              )}

              {getAdequatInput(tab[rule.firstSelect].type, rule.secondSelect) ==
                4 && (
                <DatePicker.MonthPicker
                  style={{ width: "25vh" }}
                  locale={locale}
                  onChange={(e, date) => onChangeMonth(date, j)}
                />
              )}

              {getAdequatInput(tab[rule.firstSelect].type, rule.secondSelect) ==
                5 && (
                <InputNumber
                  style={{ width: "25vh" }}
                  size="large"
                  min={1}
                  max={31}
                  onChange={(e) => onChange(e, j)}
                />
              )}
              {getAdequatInput(tab[rule.firstSelect].type, rule.secondSelect) ==
                10 && (
                <InputNumber
                  style={{ width: "25vh" }}
                  size="large"
                  min={1}
                  defaultValue={1}
                  onChange={(e) => onChange(e, j)}
                />
              )}

              {/*rules.length !== j + 1 && (
                <Select
                  defaultValue="Et"
                  style={{ width: "10vh" }}
                  onChange={(e) => onThirdChange(e, j)}
                >
                  <Option selected={rule.thirdSelected === 1} key="1" value="1">
                    Et
                  </Option>
                  <Option selected={rule.thirdSelected === 2} key="2" value="2">
                    Ou
                  </Option>
                </Select>
              )*/}
            </Space>
          </div>
        ))}
        <Button
          type="primary"
          shape="circle"
          size="default"
          style={{ marginLeft: "1vh", marginTop: "3vh" }}
          onClick={(e) => addRule()}
          icon={<PlusOutlined />}
        />
      </Row>
      <br />
      <Button
        icon={<SearchOutlined />}
        onClick={search}
        style={{ marginLeft: "1vh", textAlign: "center" }}
        type="primary"
        size="large"
      >
        Rechercher
      </Button>
      <Button
        danger
        onClick={reset}
        style={{ marginLeft: "1vh", textAlign: "center" }}
        type="primary"
        size="large"
      >
        Reset
      </Button>
    </div>
  );
};
export default Search;
