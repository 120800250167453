import {
  Button,
  Col,
  Form,
  Input,
  Modal,
  Popconfirm,
  Row,
  Typography,
  Skeleton,
} from "antd";
import { useState, useEffect, useRef } from "react";
import {
  toGetEmplacementList,
  toDeleteEmplacement,
  toAddOrUpdateEmplacement,
} from "./Api";
import { PlusOutlined } from "@ant-design/icons";

import { BiPencil, BiTrash } from "react-icons/bi";
import { errorMsg, successMsg, toConvertDate } from "../../Utils/Utils";
import "../../Styles/TwoTables.scss";

import Search from "../../Utils/Search";

const Emplacement = (props) => {
  const [loading, setLoading] = useState(true);
  const [createModalVisible, setCreateModalVisible] = useState(false);
  const [filterModalVisible, setFilterModalVisible] = useState(false);
  const [emplacements, setEmplacements] = useState([]);
  const [title, setTitle] = useState("");
  const [id, setId] = useState(-1);
  const [form] = Form.useForm();
  const search = useRef(null);

  const fields = [
    { name: "Date de création", field: "createdDate", type: "date" },
    { name: "Titre", field: "title", type: "varchar" },
    { name: "Crée par", field: "createdBy", type: "varchar" },
  ];

  const okFilter = () => {
    search.current.getRules();
  };

  const closeCreateModal = () => {
    if (title == "") {
      errorMsg("veuillez remplir tous les champs obligatoires!");
      return;
    }
    toAddOrUpdateEmplacement(title, id).then((res) => {
      successMsg(
        id != -1
          ? "Emplacement mis à jours avec succées!"
          : "Emplacement ajoutée avec succées!"
      );
      if (id == -1) {
        setEmplacements([...emplacements, res]);
      } else {
        const emplacementIndex = emplacements.findIndex((e) => e.id == id);
        const newEmplacements = [...emplacements];
        newEmplacements[emplacementIndex] = {
          ...emplacements[emplacementIndex],
          ...res,
        };
        setEmplacements(newEmplacements);
      }
    });
    setId(-1);
    setCreateModalVisible(false);
    setTitle("");
  };

  useEffect(() => {
    toGetDatas();
  }, []);
  const toGetDatas = () => {
    toGetEmplacementList().then((res) => {
      setEmplacements(res);
      setLoading(false);
    });
  };
  const toDelete = (id) => {
    toDeleteEmplacement(id).then((res) => {
      let newEmplacements = emplacements.filter((e) => e.id != id);
      setEmplacements(newEmplacements);
    });
  };

  const toEdit = (id, name) => {
    setId(id);
    setTitle(title);
    form.setFieldsValue({ name: name });
    setCreateModalVisible(true);
  };
  const toAdd = () => {
    setId(-1);
    setTitle("");
    form.setFieldsValue({ name: "" });
    setCreateModalVisible(true);
  };
  const exitCreateModal = () => {
    setId(-1);
    setTitle("");

    setCreateModalVisible(false);
  };

  return (
    <>
      {props.display && (
        <>
          <Row>
            <Col span="12">
              <Typography.Title level="2">Emplacements</Typography.Title>
            </Col>
            <Col span="12" style={{ textAlign: "right" }}>
              <Button
                type="primary"
                style={{ marginRight: "5px" }}
                onClick={(e) => setFilterModalVisible(true)}
              >
                Filtrer
              </Button>
              <Button
                icon={<PlusOutlined />}
                onClick={(e) => toAdd()}
                type="primary"
              >
                Ajouter un emplacement
              </Button>
            </Col>
          </Row>

          <Row
            style={{
              overflowY: "hidden",
            }}
          >
            <Col span="24">
              <div className="table-wrapper auto--overflow">
                <table className="ant-table" style={{ width: "100%" }}>
                  <thead className="ant-table-thead">
                    <tr>
                      <th className="ant-table-cell">Date création</th>
                      <th className="ant-table-cell">Titre</th>
                      <th className="ant-table-cell">Crée par</th>
                      <th className="ant-table-cell"></th>
                    </tr>
                  </thead>
                  <Skeleton active loading={loading} />

                  <tbody className="ant-table-tbody ">
                    {null != emplacements &&
                      emplacements.length > 0 &&
                      emplacements.map((emplacement, i) => (
                        <tr key={i}>
                          <td className="ant-table-cell">
                            {toConvertDate(emplacement.createdDate)}
                          </td>
                          <td className="ant-table-cell">
                            {emplacement.title}
                          </td>
                          <td className="ant-table-cell">
                            {emplacement.createdBy}
                          </td>
                          <td
                            className="ant-table-cell"
                            style={{ float: "right" }}
                          >
                            <Button
                              onClick={() =>
                                toEdit(emplacement.id, emplacement.title)
                              }
                              icon={<BiPencil />}
                            />
                            <Popconfirm
                              title={`Voulez vous vraiment supprimer ${emplacement.title}?`}
                              onConfirm={(e) => toDelete(emplacement.id)}
                              okText="Oui"
                              cancelText="Non"
                            >
                              <Button icon={<BiTrash />} />
                            </Popconfirm>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </Col>
          </Row>
        </>
      )}

      <Modal
        className="modal-small"
        title={"Filtrer"}
        visible={filterModalVisible}
        onOk={okFilter}
        onCancel={(e) => setFilterModalVisible(false)}
      >
        <Search ref={search} fields={fields} list={emplacements} />
      </Modal>

      <Modal
        title={
          id != -1
            ? "Modifier un emplacement"
            : "Ajouter un nouveau emplacement"
        }
        visible={createModalVisible}
        onOk={closeCreateModal}
        onCancel={exitCreateModal}
      >
        <Form name="basic" form={form}>
          <Form.Item
            label="Nom"
            name="name"
            rules={[{ required: true, message: "Champ obligatoire" }]}
          >
            <Input value={title} onChange={(e) => setTitle(e.target.value)} />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default Emplacement;
