import { Divider, Col, Form, Input, Modal, Row, Select, Button, InputNumber, Switch } from "antd";

import {
  toGetUniteList,
  toGetEmplacementList,
  toGetTVAList,
  toGetMarqueList,
  toGetCategoryList,
  toAddOrUpdateUnite,
  toAddOrUpdateMarque,
  toAddOrUpdateEmplacement,
  toAddOrUpdateCategory,
} from "./Api";
import { useState, useEffect } from "react";
import { PlusOutlined } from "@ant-design/icons";
import { errorMsg, successMsg } from "../../Utils/Utils";
import cookie from "react-cookies";
import TecdocLiaison from "./TecdocLiaison";

const { Option } = Select;

const ArticleModal = (props) => {
  const [tva, setTVA] = useState(0);
  const [unites, setUnites] = useState([]);
  const [marques, setMarques] = useState([]);
  const [categories, setCategories] = useState([]);
  const [emplacements, setEmplacements] = useState([]);
  const [tvas, setTVAs] = useState([]);

  const [margeValue, setMargeValue] = useState("%");
  const [margeValueGros, setMargeValueGros] = useState(props.details.margeGrosValue);

  const [id, setId] = useState(-1);

  const [form] = Form.useForm();

  const [uniteToAdd, setUniteToAdd] = useState("");
  const [marqueToAdd, setMarqueToAdd] = useState("");
  const [categoryToAdd, setCategoryToAdd] = useState("");
  const [emplacementToAdd, setEmplacementToAdd] = useState("");
  const [tecdocLiaison, setTecdocLiaison] = useState(false);
  const [selectedArticle, setSelectedArticle] = useState("");
  const [automaticCode, setAutomaticCode] = useState(false);

  useEffect(() => {
    toGetUnite();
    toGetEmplacement();
    toGetTVA();
    toGetMarque();
    toGetCategories();
    setMargeValue("%");
    setMargeValueGros(props.details.margeGrosValue);
    setId(props.details.id);
    form.setFieldsValue(props.details);
  }, [props.details]);

  const addItemUnite = () => {
    toAddOrUpdateUnite(uniteToAdd, -1).then((unite) => {
      if (null != unite) {
        successMsg("Unite ajoutée avec succées!");
        setUnites([...unites, unite]);
        setUniteToAdd("");
      }
    });
  };
  const addItemMarque = (marque) => {
    toAddOrUpdateMarque(null != marque ? marque : marqueToAdd, null != marque ? marque : "", -1).then((marque) => {
      if (null != marque) {
        successMsg("Marque ajoutée avec succées!");
        setMarques([...marques, marque]);
        setMarqueToAdd("");
      }
    });
  };

  const addItemCategory = (category) => {
    toAddOrUpdateCategory(null != category ? category : categoryToAdd, "", -1).then((category) => {
      if (null != category) {
        successMsg("Catégorie ajoutée avec succées!");
        setCategories([...categories, category]);
        setCategoryToAdd("");
      }
    });
  };

  const addItemEmplacement = () => {
    toAddOrUpdateEmplacement(emplacementToAdd, -1).then((emplacement) => {
      if (null != emplacement) {
        successMsg("Emplacement ajoutée avec succées!");
        setEmplacements([...emplacements, emplacement]);
        setEmplacementToAdd("");
      }
    });
  };

  const toGetCategories = () => {
    toGetCategoryList().then((res) => {
      setCategories(res);
      if (props.details.categorie == "") {
        //form.setFieldsValue({ categorie: res[0].title });
      } else {
        let nb = res.filter((e) => e.title == props.details.categorie).length;
        if (nb == 0) {
          addItemCategory(props.details.categorie);
        }
        form.setFieldsValue({ categorie: props.details.categorie });
      }
    });
  };

  const toGetMarque = () => {
    toGetMarqueList().then((res) => {
      setMarques(res);
      if (props.details.marque == "") {
        form.setFieldsValue({ marque: res[0].title });
      } else {
        let nb = res.filter((e) => e.title == props.details.marque).length;
        if (nb == 0) {
          addItemMarque(props.details.marque);
        }
        form.setFieldsValue({ marque: props.details.marque });
      }
    });
  };

  const toGetEmplacement = () => {
    toGetEmplacementList().then((res) => {
      setEmplacements(res);
    });
  };
  const toGetUnite = () => {
    toGetUniteList().then((res) => {
      form.setFieldsValue({ unite: res[0].title });
      setUnites(res);
    });
  };
  const toGetTVA = () => {
    toGetTVAList().then((res) => {
      if (res.length > 0) {
        setTVA(res[0].title);
        form.setFieldsValue({ tva: res[0].title });
        setTVAs(res);
      }
    });
  };

  const toUpdateFirstLigne = (index, value) => {
    var l__tva = parseFloat(form.getFieldValue("tva"));
    var l__prixAchatHT = parseFloat(form.getFieldValue("prixAchatHT"));
    var l__prixAchatTTC = parseFloat(form.getFieldValue("prixAchatTTC"));
    //vente
    var l__marge = parseFloat(form.getFieldValue("marge"));
    var l__prixVenteHT = parseFloat(form.getFieldValue("prixVenteHT"));
    var l__prixVenteTTC = parseFloat(form.getFieldValue("prixVenteTTC"));
    //vente gros
    var l__margeGros = parseFloat(form.getFieldValue("margeGros"));
    var l__prixVenteGrosHT = parseFloat(form.getFieldValue("prixVenteGrosHT"));
    var l__prixVenteGrosTTC = parseFloat(form.getFieldValue("prixVenteGrosTTC"));

    switch (index) {
      //tva
      case 0:
        l__prixAchatTTC = ((100 + l__tva) * l__prixAchatHT) / 100;
        break;
      case 1:
        l__prixAchatTTC = ((100 + l__tva) * l__prixAchatHT) / 100;
        break;
      case 2:
        l__prixAchatHT = (l__prixAchatTTC / (100 + l__tva)) * 100;
        break;

      default:
        break;
    }
    l__prixVenteHT = ((100 + l__marge) * l__prixAchatHT) / 100;
    l__prixVenteTTC = ((100 + l__marge) * l__prixAchatTTC) / 100;

    form.setFieldsValue({
      prixAchatHT: Number(Math.round(l__prixAchatHT + "e3") + "e-3"),
      prixAchatTTC: Number(Math.round(l__prixAchatTTC + "e3") + "e-3"),

      prixVenteHT: Number(Math.round(l__prixVenteHT + "e3") + "e-3"),
      prixVenteTTC: Number(Math.round(l__prixVenteTTC + "e3") + "e-3"),
      prixVenteGrosHT: Number(Math.round(l__prixVenteGrosHT + "e3") + "e-3"),
      prixVenteGrosTTC: Number(Math.round(l__prixVenteGrosTTC + "e3") + "e-3"),
      marge: l__marge,
      margeGros: l__margeGros,
    });
  };

  const toUpdateSecondLigne = (index, value) => {
    var l__tva = parseFloat(form.getFieldValue("tva"));
    var l__prixAchatHT = parseFloat(form.getFieldValue("prixAchatHT"));
    var l__prixAchatTTC = parseFloat(form.getFieldValue("prixAchatTTC"));
    //vente
    var l__marge = parseFloat(form.getFieldValue("marge"));
    var l__prixVenteHT = parseFloat(form.getFieldValue("prixVenteHT"));
    var l__prixVenteTTC = parseFloat(form.getFieldValue("prixVenteTTC"));
    //vente gros
    var l__margeGros = parseFloat(form.getFieldValue("margeGros"));
    var l__prixVenteGrosHT = parseFloat(form.getFieldValue("prixVenteGrosHT"));
    var l__prixVenteGrosTTC = parseFloat(form.getFieldValue("prixVenteGrosTTC"));

    switch (index) {
      //tva
      case 0:
        if (margeValue == "%") {
          l__prixVenteHT = ((100 + l__marge) * l__prixAchatHT) / 100;
          l__prixVenteTTC = ((100 + l__marge) * l__prixAchatTTC) / 100;
        } else {
          l__prixVenteHT = l__prixAchatHT + l__marge;
          l__prixVenteTTC = l__prixAchatTTC + l__marge;
        }
        break;
      case 1:
        if (margeValue == "%") {
          l__marge = ((l__prixVenteHT - l__prixAchatHT) / l__prixAchatHT) * 100;
          l__prixVenteTTC = ((100 + l__marge) * l__prixAchatTTC) / 100;
        } else {
          l__marge = l__prixVenteHT = l__prixAchatHT;
          l__prixVenteTTC = l__prixAchatTTC + l__marge;
        }
        break;
      case 2:
        if (margeValue == "%") {
          l__marge = ((l__prixVenteTTC - l__prixAchatTTC) / l__prixAchatTTC) * 100;
          l__prixVenteHT = ((100 + l__marge) * l__prixAchatHT) / 100;
        } else {
          l__marge = l__prixVenteTTC - l__prixVenteHT;
          l__prixVenteHT = l__prixAchatHT + l__marge;
        }
        break;

      default:
        break;
    }

    if (margeValueGros == "%") {
      l__prixVenteGrosHT = ((100 + l__margeGros) * l__prixAchatHT) / 100;
      l__prixVenteGrosTTC = ((100 + l__margeGros) * l__prixAchatTTC) / 100;
    } else {
      l__prixVenteGrosHT = l__prixAchatHT + l__margeGros;
      l__prixVenteGrosTTC = l__prixAchatTTC + l__margeGros;
    }

    form.setFieldsValue({
      prixAchatHT: Number(Math.round(l__prixAchatHT + "e3") + "e-3"),
      prixAchatTTC: Number(Math.round(l__prixAchatTTC + "e3") + "e-3"),

      prixVenteHT: Number(Math.round(l__prixVenteHT + "e3") + "e-3"),
      prixVenteTTC: Number(Math.round(l__prixVenteTTC + "e3") + "e-3"),
      prixVenteGrosHT: Number(Math.round(l__prixVenteGrosHT + "e3") + "e-3"),
      prixVenteGrosTTC: Number(Math.round(l__prixVenteGrosTTC + "e3") + "e-3"),
      marge: l__marge,
      margeGros: l__margeGros,
    });
  };

  const toUpdateThirdLigne = (index, value) => {
    var l__tva = parseFloat(form.getFieldValue("tva"));
    var l__prixAchatHT = parseFloat(form.getFieldValue("prixAchatHT"));
    var l__prixAchatTTC = parseFloat(form.getFieldValue("prixAchatTTC"));
    //vente
    var l__marge = parseFloat(form.getFieldValue("marge"));
    var l__prixVenteHT = parseFloat(form.getFieldValue("prixVenteHT"));
    var l__prixVenteTTC = parseFloat(form.getFieldValue("prixVenteTTC"));
    //vente gros
    var l__margeGros = parseFloat(form.getFieldValue("margeGros"));
    var l__prixVenteGrosHT = parseFloat(form.getFieldValue("prixVenteGrosHT"));
    var l__prixVenteGrosTTC = parseFloat(form.getFieldValue("prixVenteGrosTTC"));
    switch (index) {
      //tva
      case 0:
        if (margeValueGros == "%") {
          l__prixVenteGrosHT = ((100 + l__margeGros) * l__prixAchatHT) / 100;
          l__prixVenteGrosTTC = ((100 + l__margeGros) * l__prixAchatTTC) / 100;
        } else {
          l__prixVenteGrosHT = l__prixAchatHT + l__margeGros;
          l__prixVenteGrosTTC = l__prixAchatTTC + l__margeGros;
        }
        break;
      case 1:
        if (margeValueGros == "%") {
          l__margeGros = ((l__prixVenteGrosHT - l__prixAchatHT) / l__prixAchatHT) * 100;
          l__prixVenteGrosTTC = ((100 + l__margeGros) * l__prixAchatTTC) / 100;
        } else {
          l__margeGros = l__prixVenteHT = l__prixAchatHT;
          l__prixVenteGrosTTC = l__prixAchatTTC + l__margeGros;
        }
        break;
      case 2:
        if (margeValueGros == "%") {
          l__margeGros = ((l__prixVenteGrosTTC - l__prixAchatTTC) / l__prixAchatTTC) * 100;
          l__prixVenteGrosHT = ((100 + l__margeGros) * l__prixAchatHT) / 100;
        } else {
          l__margeGros = l__prixVenteGrosTTC - l__prixVenteGrosHT;
          l__prixVenteGrosHT = l__prixAchatHT + l__margeGros;
        }
        break;

      default:
        break;
    }

    if (margeValueGros == "%") {
      l__prixVenteGrosHT = ((100 + l__margeGros) * l__prixAchatHT) / 100;
      l__prixVenteGrosTTC = ((100 + l__margeGros) * l__prixAchatTTC) / 100;
    } else {
      l__prixVenteGrosHT = l__prixAchatHT + l__margeGros;
      l__prixVenteGrosTTC = l__prixAchatTTC + l__margeGros;
    }

    form.setFieldsValue({
      prixAchatHT: Number(Math.round(l__prixAchatHT + "e3") + "e-3"),
      prixAchatTTC: Number(Math.round(l__prixAchatTTC + "e3") + "e-3"),

      prixVenteHT: Number(Math.round(l__prixVenteHT + "e3") + "e-3"),
      prixVenteTTC: Number(Math.round(l__prixVenteTTC + "e3") + "e-3"),
      prixVenteGrosHT: Number(Math.round(l__prixVenteGrosHT + "e3") + "e-3"),
      prixVenteGrosTTC: Number(Math.round(l__prixVenteGrosTTC + "e3") + "e-3"),
      marge: l__marge,
      margeGros: l__margeGros,
    });
  };

  const toUpdateMarge = (value) => {
    var l__marge = parseFloat(form.getFieldValue("marge"));
    var l__prixVenteHT = parseFloat(form.getFieldValue("prixVenteHT"));
    var l__prixVenteTTC = parseFloat(form.getFieldValue("prixVenteTTC"));

    var l__prixAchatHT = parseFloat(form.getFieldValue("prixAchatHT"));
    var l__prixAchatTTC = parseFloat(form.getFieldValue("prixAchatTTC"));
    if (value == "%") {
      l__prixVenteHT = ((100 + l__marge) * l__prixAchatHT) / 100;
      l__prixVenteTTC = ((100 + l__marge) * l__prixAchatTTC) / 100;
    } else {
      l__prixVenteHT = l__prixAchatHT + l__marge;
      l__prixVenteTTC = l__prixAchatTTC + l__marge;
    }
    form.setFieldsValue({
      prixVenteHT: Number(Math.round(l__prixVenteHT + "e3") + "e-3"),
      prixVenteTTC: Number(Math.round(l__prixVenteTTC + "e3") + "e-3"),
    });
  };

  const toUpdateMargeGros = (value) => {
    var l__marge = parseFloat(form.getFieldValue("margeGros"));
    var l__prixVenteGrosHT = parseFloat(form.getFieldValue("prixVenteGrosHT"));
    var l__prixVenteGrosTTC = parseFloat(form.getFieldValue("prixVenteGrosTTC"));

    var l__prixAchatHT = parseFloat(form.getFieldValue("prixAchatHT"));
    var l__prixAchatTTC = parseFloat(form.getFieldValue("prixAchatTTC"));
    if (value == "%") {
      l__prixVenteGrosHT = ((100 + l__marge) * l__prixAchatHT) / 100;
      l__prixVenteGrosTTC = ((100 + l__marge) * l__prixAchatTTC) / 100;
    } else {
      l__prixVenteGrosHT = l__prixAchatHT + l__marge;
      l__prixVenteGrosTTC = l__prixAchatTTC + l__marge;
    }
    form.setFieldsValue({
      prixVenteGrosHT: Number(Math.round(l__prixVenteGrosHT + "e3") + "e-3"),
      prixVenteGrosTTC: Number(Math.round(l__prixVenteGrosTTC + "e3") + "e-3"),
    });
  };

  const toUpdateData = (index, value) => {
    //achat
    var l__tva = parseFloat(form.getFieldValue("tva"));
    var l__prixAchatHT = parseFloat(form.getFieldValue("prixAchatHT"));
    var l__prixAchatTTC = parseFloat(form.getFieldValue("prixAchatTTC"));
    //vente
    var l__marge = parseFloat(form.getFieldValue("marge"));
    var l__prixVenteHT = parseFloat(form.getFieldValue("prixVenteHT"));
    var l__prixVenteTTC = parseFloat(form.getFieldValue("prixVenteTTC"));
    //vente gros
    var l__margeGros = parseFloat(form.getFieldValue("margeGros"));
    var l__prixVenteGrosHT = parseFloat(form.getFieldValue("prixVenteGrosHT"));
    var l__prixVenteGrosTTC = parseFloat(form.getFieldValue("prixVenteGrosTTC"));
    switch (index) {
      case 0:
        l__prixAchatTTC = (l__prixAchatHT * (100 + l__tva)) / 100;
        if (margeValue == "%") {
          l__prixVenteHT = ((l__marge + 100) * l__prixAchatHT) / 100;
          l__prixVenteTTC = ((l__marge + 100) * l__prixAchatTTC) / 100;
        } else {
          l__prixVenteHT = parseFloat(l__marge) + parseFloat(l__prixAchatHT);
          l__prixVenteTTC = parseFloat(l__marge) + parseFloat(l__prixAchatTTC);
        }

        if (margeValue == "%") {
          l__prixVenteGrosHT = ((l__margeGros + 100) * l__prixAchatHT) / 100;
          l__prixVenteGrosTTC = ((l__margeGros + 100) * l__prixAchatTTC) / 100;
        } else {
          l__prixVenteGrosHT = parseFloat(l__margeGros) + parseFloat(l__prixAchatHT);
          l__prixVenteGrosTTC = parseFloat(l__margeGros) + parseFloat(l__prixAchatTTC);
        }

        break;

      case 2:
        l__prixAchatHT = (l__prixAchatTTC / (100 + l__tva)) * 100;
        if (margeValue == "%") {
          l__prixVenteHT = ((l__marge + 100) * l__prixAchatHT) / 100;
          l__prixVenteTTC = ((l__marge + 100) * l__prixAchatTTC) / 100;
        } else {
          l__prixVenteHT = parseFloat(l__marge) + parseFloat(l__prixAchatHT);
          l__prixVenteTTC = parseFloat(l__marge) + parseFloat(l__prixAchatTTC);
        }

        if (margeValue == "%") {
          l__prixVenteGrosHT = ((l__margeGros + 100) * l__prixAchatHT) / 100;
          l__prixVenteGrosTTC = ((l__margeGros + 100) * l__prixAchatTTC) / 100;
        } else {
          l__prixVenteGrosHT = parseFloat(l__margeGros) + parseFloat(l__prixAchatHT);
          l__prixVenteGrosTTC = parseFloat(l__margeGros) + parseFloat(l__prixAchatTTC);
        }
        break;

      case 3:
        if (margeValue == "%") {
          l__prixVenteHT = ((l__marge + 100) * l__prixAchatHT) / 100;
          l__prixVenteTTC = ((l__marge + 100) * l__prixAchatTTC) / 100;
        } else {
          l__prixVenteHT = parseFloat(l__marge) + parseFloat(l__prixAchatHT);
          l__prixVenteTTC = parseFloat(l__marge) + parseFloat(l__prixAchatTTC);
        }
        break;

      case 5:
        if (margeValue == "%") {
          l__marge = ((l__prixVenteHT - l__prixAchatHT) / l__prixAchatHT) * 100;
          l__prixVenteTTC = ((l__marge + 100) * l__prixAchatTTC) / 100;
        } else {
          l__marge = l__prixVenteHT - l__prixAchatHT;
          l__prixVenteTTC = parseFloat(l__marge) + parseFloat(l__prixAchatTTC);
        }
        break;

      case 6:
        if (margeValue == "%") {
          l__marge = ((l__prixVenteTTC - l__prixAchatTTC) / l__prixAchatTTC) * 100;
          l__prixVenteHT = ((l__marge + 100) * l__prixAchatHT) / 100;
        } else {
          l__marge = l__prixVenteTTC - l__prixAchatTTC;
          l__prixVenteHT = parseFloat(l__marge) + parseFloat(l__prixAchatHT);
        }
        break;

      case 7:
        if (value == "%") {
          l__prixVenteGrosHT = ((l__margeGros + 100) * l__prixAchatHT) / 100;
          l__prixVenteGrosTTC = ((l__margeGros + 100) * l__prixAchatTTC) / 100;
        } else {
          l__prixVenteGrosHT = parseFloat(l__margeGros) + parseFloat(l__prixAchatHT);
          l__prixVenteGrosTTC = parseFloat(l__margeGros) + parseFloat(l__prixAchatTTC);
        }
        break;

      case 9:
        if (margeValue == "%") {
          l__margeGros = ((l__prixVenteGrosHT - l__prixAchatHT) / l__prixAchatHT) * 100;
          l__prixVenteGrosHT = ((l__margeGros + 100) * l__prixAchatHT) / 100;
        } else {
          l__margeGros = l__prixVenteGrosHT - l__prixAchatHT;
          l__prixVenteGrosHT = parseFloat(l__margeGros) + parseFloat(l__prixAchatHT);
        }
        break;

      case 10:
        if (margeValue == "%") {
          l__margeGros = ((l__prixVenteGrosTTC - l__prixAchatTTC) / l__prixAchatTTC) * 100;
          l__prixVenteGrosHT = ((l__margeGros + 100) * l__prixAchatHT) / 100;
        } else {
          l__marge = l__prixVenteTTC - l__prixAchatTTC;
          l__prixVenteHT = parseFloat(l__marge) + parseFloat(l__prixAchatHT);
        }
        break;

      default:
        break;
    }
    form.setFieldsValue({
      prixAchatHT: Number(Math.round(l__prixAchatHT + "e3") + "e-3"),
      prixVenteHT: Number(Math.round(l__prixVenteHT + "e3") + "e-3"),
      prixVenteTTC: Number(Math.round(l__prixVenteTTC + "e3") + "e-3"),
      prixAchatTTC: Number(Math.round(l__prixAchatTTC + "e3") + "e-3"),
      prixVenteGrosHT: Number(Math.round(l__prixVenteGrosHT + "e3") + "e-3"),
      prixVenteGrosTTC: Number(Math.round(l__prixVenteGrosTTC + "e3") + "e-3"),
      marge: l__marge,
      margeGros: l__margeGros,
    });
  };

  const toDataUrl = (url, callback) => {
    const xhr = new XMLHttpRequest();
    xhr.onload = () => {
      const reader = new FileReader();
      reader.onloadend = () => {
        callback(reader.result);
      };
      reader.readAsDataURL(xhr.response);
    };
    xhr.open("GET", url);
    xhr.responseType = "blob";
    xhr.send();
  };

  const closeCreateModal = () => {
    form
      .validateFields()
      .then((values) => {
        if (parseFloat(values.marge) == 0 || parseFloat(values.margeGros) == 0) {
          errorMsg("La marge ne peut pas être un 0");
          return;
        }
        values.margeValue = margeValue;
        values.margeGrosValue = margeValueGros;
        values.fromTecdoc = false;
        values.referenceTecdoc = "";

        if (null == props.details.data) {
          props.saveArticle(id, values, automaticCode);
        } else {
          values.data = props.details.data;
          values.fromTecdoc = props.details.fromTecdoc;
          values.referenceTecdoc = props.details.referenceTecdoc;

          props.saveArticle(id, values, automaticCode);
        }
      })
      .catch((errorInfo) => {
        if (errorInfo["errorFields"].length > 0) {
          errorMsg(errorInfo["errorFields"][0]["name"] + " " + errorInfo["errorFields"][0]["errors"]);
        }
      });
  };

  const cancelCreateModal = () => {
    props.closeCreateModal();
  };

  const selectAfter = (
    <Select
      name='margeValue'
      onChange={(e) => {
        setMargeValue(e);
        toUpdateMarge(e);
      }}
      defaultValue='%'
      className='select-after'
    >
      <Option value='%'>%</Option>
      <Option value='DT'>DT</Option>
    </Select>
  );

  const afterBind = (j, json) => {
    var article = props.details;
    article.data = json.info;
    article.titre = json.name;
    article.b__tecdoc = json.referenceTecdoc == "" ? false : true;
    form.setFieldsValue(article);

    setTecdocLiaison(false);
  };

  return (
    <>
      <Modal
        className='smtv-modal modal-large'
        title={
          id == -1 ? (
            "Ajouter un nouveau article"
          ) : (
            <>
              Modifier article
              <img
                style={{ marginLeft: "2vh" }}
                onClick={() => {
                  setTecdocLiaison(true);
                  setSelectedArticle(props.details);
                }}
                src='/images/tecdoc.png'
                className={props.details.b__tecdoc ? "auto-tecdoc-img" : "auto-tecdoc-img-gray"}
                alt='smtv-logo'
              />
            </>
          )
        }
        visible={props.createModalVisible}
        maskClosable={false}
        onCancel={(e) => cancelCreateModal()}
        footer={[
          <Button key='back' onClick={(e) => cancelCreateModal()}>
            Annuler
          </Button>,
          <Button key='submit' type='primary' onClick={(e) => closeCreateModal()}>
            Enregistrer l'article
          </Button>,
        ]}
        onOk={closeCreateModal}
      >
        <Form form={form} layout='vertical' name='basic' initialValues={{ remember: true }}>
          <Divider>Détails de l'article</Divider>
          <Row gutter={24}>
            <Col span={6}>
              <Form.Item label='Titre' name='titre' rules={[{ required: true, message: "Champ obligatoire" }]}>
                <Input />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item label='Titre 2' name='titre2' rules={[{ required: true, message: "Champs obligatoire" }]}>
                <Input />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item label='Référence' name='reference' rules={[{ required: true, message: "Champ obligatoire" }]}>
                <Input />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item label='Référence origine' name='refOrigine'>
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={5}>
              <Form.Item label='Code à barre' name='code'>
                <Input disabled={automaticCode} />
              </Form.Item>
            </Col>
            <Col span={3}>
              <Form.Item label='automatique'>
                <Switch checked={automaticCode} onChange={() => setAutomaticCode(!automaticCode)}></Switch>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label='Type' name='type' rules={[{ required: true, message: "Champ obligatoire" }]}>
                <Select showSearch defaultValue={"Origine"}>
                  <Select.Option value='Origine'>Origine</Select.Option>
                  <Select.Option value='Adaptable'>Adaptable</Select.Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label='Unité' name='unite' rules={[{ required: true, message: "Champs obligatoire" }]}>
                <Select
                  showSearch
                  style={{ width: "100%" }}
                  defaultValue={unites.length > 0 && unites[0].title}
                  dropdownRender={(menu) => (
                    <div>
                      <Divider style={{ margin: "4px 0" }} />
                      <div
                        style={{
                          display: "flex",
                          flexWrap: "nowrap",
                          padding: 8,
                        }}
                      >
                        <Input style={{ flex: "auto" }} value={uniteToAdd} onChange={(e) => setUniteToAdd(e.target.value)} />
                        <a
                          style={{
                            flex: "none",
                            padding: "8px",
                            display: "block",
                            cursor: "pointer",
                          }}
                          onClick={(e) => uniteToAdd != "" && addItemUnite()}
                        >
                          <PlusOutlined /> Nouveau
                        </a>
                      </div>
                      {menu}
                    </div>
                  )}
                >
                  {unites.map((unite, i) => (
                    <Option value={unite.title} key={i}>
                      {unite.title}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={24}>
            <Col span={8}>
              <Form.Item label='Marque' name='marque' rules={[{ required: true, message: "Champ obligatoire" }]}>
                <Select
                  showSearch
                  style={{ width: "100%" }}
                  defaultValue={marques.length > 0 && marques[0].title}
                  dropdownRender={(menu) => (
                    <div>
                      <Divider style={{ margin: "4px 0" }} />
                      <div
                        style={{
                          display: "flex",
                          flexWrap: "nowrap",
                          padding: 8,
                        }}
                      >
                        <Input style={{ flex: "auto" }} value={marqueToAdd} onChange={(e) => setMarqueToAdd(e.target.value)} />
                        <a
                          style={{
                            flex: "none",
                            padding: "8px",
                            display: "block",
                            cursor: "pointer",
                          }}
                          onClick={(e) => marqueToAdd != "" && addItemMarque()}
                        >
                          <PlusOutlined /> Nouveau
                        </a>
                      </div>
                      {menu}
                    </div>
                  )}
                >
                  {marques.map((marque, i) => (
                    <Option value={marque.title} key={i}>
                      {marque.title}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item label='Catégorie' name='categorie' rules={[{ required: true, message: "Champ obligatoire" }]}>
                <Select
                  defaultValue={categories.length > 0 && categories[0].title}
                  showSearch
                  style={{ width: "100%" }}
                  dropdownRender={(menu) => (
                    <div>
                      <Divider style={{ margin: "4px 0" }} />
                      <div
                        style={{
                          display: "flex",
                          flexWrap: "nowrap",
                          padding: 8,
                        }}
                      >
                        <Input style={{ flex: "auto" }} value={categoryToAdd} onChange={(e) => setCategoryToAdd(e.target.value)} />
                        <a
                          style={{
                            flex: "none",
                            padding: "8px",
                            display: "block",
                            cursor: "pointer",
                          }}
                          onClick={(e) => categoryToAdd != "" && addItemCategory()}
                        >
                          <PlusOutlined /> Nouveau
                        </a>
                      </div>
                      {menu}
                    </div>
                  )}
                >
                  {categories.map((category, i) => (
                    <Option value={category.title} key={i}>
                      {category.title}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label='Emplacement' name='emplacement'>
                <Select
                  showSearch
                  style={{ width: "100%" }}
                  dropdownRender={(menu) => (
                    <div>
                      <Divider style={{ margin: "4px 0" }} />
                      <div
                        style={{
                          display: "flex",
                          flexWrap: "nowrap",
                          padding: 8,
                        }}
                      >
                        <Input style={{ flex: "auto" }} value={emplacementToAdd} onChange={(e) => setEmplacementToAdd(e.target.value)} />
                        <a
                          style={{
                            flex: "none",
                            padding: "8px",
                            display: "block",
                            cursor: "pointer",
                          }}
                          onClick={(e) => emplacementToAdd != "" && addItemEmplacement()}
                        >
                          <PlusOutlined /> Nouveau
                        </a>
                      </div>
                      {menu}
                    </div>
                  )}
                >
                  <Option value='' key='-1'></Option>
                  {emplacements.map((emplacement, i) => (
                    <Option value={emplacement.title} key={i}>
                      {emplacement.title}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={8}>
              <Form.Item label='Seuil' name='seuil'>
                <Input type='number' min={0} decimalSeparator={","} />
              </Form.Item>
            </Col>
          </Row>
          <Divider>Prix de l'article</Divider>
          <Row gutter={24}>
            <Col span={8}>
              <Form.Item label='TVA' name='tva' rules={[{ required: true, message: "Champ obligatoire" }]}>
                <Select
                  addonAfter='%'
                  defaultValue={tvas.length > 0 && tvas[0].title}
                  onChange={(e) => {
                    toUpdateFirstLigne(0);
                  }}
                >
                  {tvas.map((tva, y) => (
                    <Select.Option value={tva.title} key={y}>
                      {tva.title}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item label='Prix achat HT' name='prixAchatHT' rules={[{ required: true, message: "Champ obligatoire" }]}>
                <InputNumber
                  style={{ width: "100%" }}
                  addonAfter='DT'
                  decimalSeparator={","}
                  onChange={(e) => {
                    toUpdateFirstLigne(0);
                  }}
                />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item label='Prix achat TTC' name='prixAchatTTC' rules={[{ required: true, message: "Champ obligatoire" }]}>
                <InputNumber
                  style={{ width: "100%" }}
                  addonAfter='DT'
                  decimalSeparator={","}
                  onChange={(e) => {
                    toUpdateFirstLigne(2);
                  }}
                  addonAfter='DT'
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={24}>
            <Col span={8}>
              <Form.Item label='Marge' name='marge' rules={[{ required: true, message: "Champ obligatoire" }]}>
                <Input
                  onChange={(e) => {
                    toUpdateSecondLigne(0);
                  }}
                  type='number'
                  addonAfter='%'
                />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item
                label='Prix vente HT'
                name='prixVenteHT'
                onChange={(e) => {
                  toUpdateSecondLigne(1);
                }}
                rules={[{ required: true, message: "Champ obligatoire" }]}
              >
                <Input type='number' addonAfter='DT' />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item
                label='Prix vente TTC'
                name='prixVenteTTC'
                onChange={(e) => {
                  toUpdateSecondLigne(2);
                }}
                rules={[{ required: true, message: "Champ obligatoire" }]}
              >
                <Input type='number' addonAfter='DT' />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
      {id != -1 && (
        <Modal
          className='smtv-modal modal-large'
          visible={tecdocLiaison}
          maskClosable={false}
          title={selectedArticle.titre2 + " " + selectedArticle.reference}
          onCancel={(e) => setTecdocLiaison(false)}
          footer={[
            <Button key='back' onClick={(e) => setTecdocLiaison(false)}>
              Fermer
            </Button>,
          ]}
        >
          <TecdocLiaison selectedArticle={selectedArticle} setTecdocLiaison={(j, json) => afterBind(j, json)} />
        </Modal>
      )}
    </>
  );
};
export default ArticleModal;
