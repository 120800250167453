import axios from "axios";
import cookie from "react-cookies";
import { errorMsg } from "../Utils/Utils";
import AppStore from "./Store";
import { logout } from "../Actions/AuthActions";

// Custom axios
const CAxios = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  validateStatus: (status) => status < 400,
});

CAxios.interceptors.request.use((config) => {
  let { url, headers } = config;

  if (url.startsWith("/api/") && cookie.load("token") != null) {
    headers.common["Authorization"] = "Bearer " + cookie.load("token");
  }
  return config;
});

CAxios.interceptors.response.use(null, function (error) {
  if (error.response && error.response.status === 401) {
    AppStore.dispatch(logout());
  }
  if (error.response && error.response.status === 303) {
    errorMsg("cette entité existe déjà");
  }

  if (error.response && error.response.status === 500) {
    errorMsg(
      "Quelque chose s'est mal passé! Veuillez contacer l'administrateur"
    );
  }
  return Promise.reject(error);
});

export default CAxios;
