import Modal from "antd/lib/modal/Modal";
import { Button, Row, Col, Skeleton } from "antd";
import { toPrintFacture } from "../APIs";
import { PrinterOutlined } from "@ant-design/icons";
import { toConvertDate } from "../../../Utils/Utils";
import { useEffect, useState } from "react";
import { toGetFacture } from "../APIs";
import printJS from "print-js";

const Retenu = ({ id, onClose, visible, facture }) => {
  const [data, setData] = useState(null);
  useEffect(() => {
    if (null != facture) {
      toGetFacture(facture.id).then((res) => {
        setData(res);
      });
    }
  }, [facture]);
  const printFacture = () => {
    printJS({
      printable: "retenu",
      type: "html",
      style: '.info-box { border: "#bfbfbf 1px solid" }',
    });
  };

  return (
    <Modal
      visible={visible}
      width="800px"
      onCancel={onClose}
      title="Retenu à la source"
      footer={[
        <Button key="close" onClick={onClose}>
          Fermer
        </Button>,
        <Button
          key="submit"
          type="primary"
          icon={<PrinterOutlined />}
          onClick={printFacture}
        >
          Imprimer
        </Button>,
      ]}
    >
      {facture && data && (
        <div id="retenu">
          <Row>
            <Col span="12">
              REPUBLIQUE TUNISIENNE
              <br />
              MINISTERE DES FINANCES
              <br />
              DIRECTION GENERALE DU CONTORLE FISCALE
              <br />
            </Col>

            <Col span="12" style={{ textAlign: "center" }}>
              CERTIFICAT DE RETENU D'IMPOT
              <br />
              SUR LE REVENU
              <br />
              OU D'IMPOT SUR LES SOCIÉTÉS
              <br />
            </Col>
          </Row>
          <ul style={{ marginTop: "2vh", border: "1px solid black" }}>
            <li> A/ ORGANISME PAYEUR</li>
            <ul>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
            </ul>
          </ul>
          <ul style={{ marginTop: "2vh" }}>
            <li> B/ DESIGNATION DU BENEFICIAIRE</li>
            <ul>
              <li>{data.client.s__name}</li>
              <li>{data.client.s__region}</li>
              <li>{data.client.s__city}</li>
              <li>
                {"Code TVA: " + data.client.s__codeTva &&
                  data.client.s__codeTva}
              </li>
            </ul>
          </ul>
        </div>
      )}
    </Modal>
  );
};

export default Retenu;
