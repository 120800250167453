import { createStore, combineReducers } from "redux";
import thunkSubscriber from "redux-thunk-subscribe";

import AuthReducer from '../Reducers/AuthReducer';
import ItemsReducer from "../Reducers/ItemsReducer";
import UIReducer from "../Reducers/UIReducer";

const rootReducer = combineReducers({
    authState: AuthReducer,
    uiState: UIReducer,
    itemSelectionState: ItemsReducer
});

const buildStore = () => createStore(rootReducer, thunkSubscriber);

const AppStore = buildStore();

export default AppStore;