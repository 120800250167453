import React, { useEffect, useState } from "react";
import "../../../Styles/TwoTables.scss";
import moment from "moment";
import { Modal, Button, Form, Select, DatePicker, Skeleton } from "antd";
import { toGetClientsListIds } from "../APIs";
import { toGenerateFacture } from "../../Achat/APIs";
import cookie from "react-cookies";
import history from "../../../Utils/History";

const FactureAutomatique = ({ visible, onClose }) => {
  const [clients, setClients] = useState([]);
  const [choosenClients, setChoosenClients] = useState(null);
  const [date, setDate] = useState(-1);
  const [factureDate, setFactureDate] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    toGetClientsListIds().then((_clients) => {
      setClients(_clients);

      var list = [];
      _clients.forEach((element) => {
        if (element.checked) {
          list.push(element.name);
        }
      });
      setChoosenClients(list);
    });
  }, []);
  const onChangeClient = (e) => {
    setChoosenClients(e);
  };
  const onChangeDate = (date, dateString) => {
    setDate(dateString);
  };

  const onChangeDateFacture = (date, dateString) => {
    setFactureDate(dateString);
  };

  const generateFacture = () => {
    const ids = [];
    choosenClients.forEach((element) => {
      ids.push(clients.filter((el) => el.name == element)[0].id);
    });
    setLoading(true);
    toGenerateFacture(ids, factureDate, date).then((res) => {
      onClose();
      history.push("/vente/factures");
      cookie.save("selectedMenu", 9, { path: "/" });
    });
  };

  return (
    <Modal
      className="smtv-modal modal-large"
      title="Génération automatique des facture(s) "
      visible={visible}
      footer={[
        <Button key="back" disabled={loading} onClick={(e) => onClose()}>
          Annuler
        </Button>,
        <Button
          key="submit"
          type="primary"
          disabled={loading}
          onClick={() => generateFacture()}
        >
          Générer le(s) facture(s)
        </Button>
      ]}
      onCancel={() => onClose()}
    >
      {loading ? (
        <Skeleton active loading={loading} />
      ) : (
        <Form layout={"vertical"}>
          <Form.Item
            label="Clients"
            rules={[{ required: true, message: "Champ obligatoire" }]}
          >
            {choosenClients && (
              <Select
                showSearch
                onChange={onChangeClient}
                mode="multiple"
                allowClear
                defaultValue={choosenClients}
              >
                {clients.map((c) => (
                  <Select.Option value={c.name}>{c.name}</Select.Option>
                ))}
              </Select>
            )}
          </Form.Item>
          <Form.Item
            label="Date entre"
            rules={[{ required: true, message: "Champ obligatoire" }]}
          >
            <DatePicker.RangePicker onChange={onChangeDate} />
          </Form.Item>

          <Form.Item
            rules={[{ required: true, message: "Champ obligatoire" }]}
            label="Date de facturation(date finale de la facture)"
          >
            <DatePicker onChange={onChangeDateFacture} />
          </Form.Item>
        </Form>
      )}
    </Modal>
  );
};

export default FactureAutomatique;
