import { InputNumber, Tooltip } from "antd";

export const FakeInput = ({
  className,
  condition,
  value,
  prefix,
  onBlur,
  onClick,
  onKeyDown,
  onChange,
  hasError,
  min,
  max,
}) => {
  const extras = {};

  if (min !== undefined) extras.min = min;
  if (max !== undefined) extras.max = max;

  if (hasError) {
    return (
      <Tooltip title="Prix inférieur au prix d'achat">
        {condition ? (
          <InputNumber
            decimalSeparator={","}
            className={className}
            defaultValue={value}
            ref={(ref) => ref && ref.focus()}
            onBlur={onBlur}
            onChange={(value) => onChange(parseFloat(value))}
            style={{ backgroundColor: "#ffccc7" }}
            prefix="%"
            {...extras}
          />
        ) : (
          <div
            className={`ant-input-number ${className}`}
            onClick={onClick}
            onFocus={onClick}
          >
            <div className="ant-input-number-input-wrap">
              <input
                style={{ backgroundColor: hasError ? "#ffccc7" : "white" }}
                className="ant-input-number-input"
                value={`${value} ${prefix}`}
                readOnly
              />
            </div>
          </div>
        )}
      </Tooltip>
    );
  } else {
    return (
      <>
        {condition ? (
          <InputNumber
            decimalSeparator={","}
            className={className}
            defaultValue={value}
            ref={(ref) => ref && ref.focus()}
            {...extras}
            onBlur={onBlur}
            onChange={(value) => onChange(parseFloat(value))}
            style={{ backgroundColor: hasError ? "#ffccc7" : "white" }}
            prefix="%"
          />
        ) : (
          <div
            className={`ant-input-number ${className}`}
            onClick={onClick}
            onFocus={onClick}
          >
            <div className="ant-input-number-input-wrap">
              <input
                style={{ backgroundColor: hasError ? "#ffccc7" : "white" }}
                className="ant-input-number-input"
                value={`${value}${prefix ? " " + prefix : ""}`}
                readOnly
              />
            </div>
          </div>
        )}
      </>
    );
  }
};
