import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Router, Switch, Route, Redirect } from "react-router-dom";
import { loadUIConfig } from "./Actions/UIActions";
import Login from "./Pages/Login";
import { loadUser, logout } from "./Actions/AuthActions";
import { Button, Modal, Input, notification as notifAntd, Form } from "antd";
import cookie from "react-cookies";

import "./Styles/App.scss";
import TopNavLayout from "./Layouts/TopNavigationLayout";
import Admin from "./Pages/Admin";
import history from "./Utils/History";
import { getToken, onMessageListener } from "./Utils/firebase";
import {
  payFactureUpdate,
  toGetBL,
  toGetFactureWithReglements,
  toSendNotification,
  toValidateReglement,
  updatePayBl
} from "./Pages/Vente/APIs";
import BonLivraisonModal from "./Pages/Vente/BL/BonLivraisonModal";
import { successMsg } from "./Utils/Utils";
import AppStore from "./Stores/AppStore";
import Chat from "./Pages/Chat/Chat";
import ReglementBLList from "./Pages/Vente/Reglement/ReglementBLList";
import ReglementFacture from "./Pages/Vente/Reglement/ReglementFacture";

function App(props) {
  var warningModal = false;

  const [isTokenFound, setTokenFound] = useState(null);
  getToken(setTokenFound);
  const [text, settext] = useState("");
  const [isAdminModalVisible, setAdminModalVisible] = useState(false);

  const [form] = Form.useForm();
  const [isOpen, setIsOpen] = useState(false);
  const [show, setShow] = useState(false);
  const [notification, setNotification] = useState({ title: "", body: "" });
  const [blToInspect, setBlToInspect] = useState(null);
  const [id, setId] = useState(-1);
  const [openModal, setOpenModal] = useState(false);
  const [notifText, setNotifText] = useState("");
  const [selectedReglement, setSelectedReglement] = useState(-1);
  const [data, setData] = useState(null);
  const [isBLModalVisible, setisBLModalVisible] = useState(false);
  const [isFactureModalVisible, setisFactureModalVisible] = useState(false);
  const [facture, setFacture] = useState(null);
  const [blToPay, setBlToPay] = useState(true);
  const [remiseChecked, setRemiseChecked] = useState(false);

  onMessageListener()
    .then((payload) => {
      if (payload.data.type == "caisse") {
        setData(payload.data);
        setSelectedReglement(payload.notification.body);
        form.setFieldsValue({ value: payload.data.montant });
        openCaisseNotification(payload);
      } else if (payload.data.type == "seuil") {
        openNotificationSeuil(payload);
      } else {
        setShow(true);
        setNotification({
          title: payload.notification.title,
          body: payload.notification.body
        });

        openNotification(payload);
      }
    })
    .catch((err) => console.log("failed: ", err));

  const openNotificationSeuil = (payload) => {
    const key = `open${Date.now()}`;

    const args = {
      key: key,
      message: payload.notification.title,
      description: payload.notification.body,
      duration: 10
    };
    notifAntd.warning(args);
  };

  const close = (id) => {
    notifAntd.close(id);
    setBlToInspect(id);
  };

  const toOpenModal = (id) => {
    notifAntd.close(id);

    setOpenModal(true);
    setId(id);
  };

  const openNotification = (payload) => {
    const key = `open${Date.now()}`;
    const btn = (
      <>
        <Button
          type="primary"
          size="small"
          onClick={() => close(payload.data.id)}
        >
          Voir détail
        </Button>

        <Button
          type="primary"
          size="small"
          style={{ marginLeft: "2vh" }}
          disabled={payload.data.username != cookie.load("username")}
          onClick={() => toOpenModal(payload.data.id)}
        >
          Répondre
        </Button>
      </>
    );

    const args = {
      key: payload.data.id,
      message: payload.notification.title,
      description: payload.notification.body,
      duration: 0,
      btn,
      className:
        payload.data.username == cookie.load("username") && "auto--border-red"
    };
    notifAntd.error(args);
  };

  const closeCaisseNotf = (id) => {
    notifAntd.close(id);
    setSelectedReglement(-1);
    setIsOpen(false);
  };
  const openCaisseNotification = (payload) => {
    const key = `open${Date.now()}`;
    const btn = (
      <>
        <Button
          size="small"
          style={{ marginLeft: "2vh" }}
          onClick={() => {
            closeCaisseNotf(key);
          }}
        >
          Non
        </Button>
        <Button
          type="primary"
          size="small"
          style={{ marginLeft: "2vh" }}
          onClick={() => {
            notifAntd.destroy(key);
            setIsOpen(true);
          }}
        >
          Oui
        </Button>
      </>
    );

    const args = {
      key: key,
      message: payload.notification.title,
      placement: "topLeft",
      className: "smtv--caisse-popup",
      btn
    };
    notifAntd.warning(args);
  };

  useEffect(() => {
    props.loadUIConfig();
    props.loadUser();
  }, []);

  const sendNotification = () => {
    var bls = [];

    bls.push(id);

    toSendNotification(notifText, bls).then(() => {
      successMsg("Notification envoyée avec succés");

      setOpenModal(false);
      setNotifText("");
      setId(-1);
    });
  };

  const validateCaisse = () => {
    form.validateFields().then((values) => {
      toValidateReglement(values.value, selectedReglement).then((res) => {
        setIsOpen(false);
      });
    });
  };

  const openModalPay = () => {
    setIsOpen(false);
    var el = data.blId;
    if (el == -1) {
      toGetFactureWithReglements(data.factureId).then((res) => {
        setFacture(res);
        setisFactureModalVisible(true);
      });
      return;
    }
    toGetBL(el).then((data) => {
      var bl = JSON.parse(data.bonlivraison);
      var regl = [];
      data.reglements.forEach((element) => {
        regl.push(element.data);
      });
      const blToUpdate = {
        id: bl.id,
        total: bl.d__total,
        discount: bl.d__discount,
        items: [],
        client: bl.client,
        reglements: regl,
        ref: bl.s__ref
      };
      for (let item of bl.items) {
        blToUpdate.items.push({
          ...item,
          ref: item.article.s__reference,
          titre: item.article.s__secondTitle,
          quantity: item.d__quantity,
          uPriceHt: item.d__unitPriceHT,
          uPriceTTC: item.d__unitPriceTTC,
          total: item.d__total,
          discount: item.d__discount,
          prix_achat: item.article.l__prixAchatTTC,
          prix_unitaire: item.article.l__prixVenteHT,
          prix_unitaireTTC: item.article.l__prixVenteTTC,
          prix_unitaire_gros: item.article.l__prixVenteGrosHT,
          prix_unitaire_grosTTC: item.article.l__prixVenteGrosTTC,
          tva: item.article.tva.title
        });
      }
      setBlToPay(blToUpdate);

      setisBLModalVisible(true);
    });
  };

  const toUpdate = (reglements) => {
    validate(-1, reglements);
  };

  const toUpdateTotal = (id) => {
    const bl = { ...blToPay };
    var totalPayé = bl.reglements.reduce((a, b) => b.montant + a, 0);
    bl.discount = (bl.total - totalPayé).toFixed(3);
    setBlToPay(bl);
    validate(id, bl.reglements);
  };

  const validate = (id, reglements) => {
    const bl = { ...blToPay };
    bl.reglements = reglements;
    setBlToPay(bl);
    setAdminModalVisible(false);

    var totalPayé = bl.reglements.reduce((a, b) => b.montant + a, 0);
    if (
      parseFloat(totalPayé).toFixed(3) < parseFloat(bl.total).toFixed(3) &&
      !isAdminModalVisible &&
      !remiseChecked &&
      bl.client.d__threshold == 0
    ) {
      settext("Le BL n'est pas totalement payé!");

      setAdminModalVisible(true);
      return;
    }

    if (
      parseFloat(totalPayé).toFixed(3) < parseFloat(bl.total).toFixed(3) &&
      remiseChecked &&
      !warningModal
    ) {
      warningModal = true;
      Modal.warning({
        okButtonProps: { style: { display: "none" } },

        content: (
          <div>
            <p>
              Voulez vous vraiment ajouter le reste(
              {(bl.total - totalPayé).toFixed(3)} )comme étant une remise!
            </p>
            <div style={{ textAlign: "center", marginTop: "2vh" }}>
              <Button type="primary" onClick={() => toUpdateTotal(id)}>
                Valider
              </Button>
              <Button
                style={{ marginLeft: "2vh" }}
                danger
                onClick={() => {
                  Modal.destroyAll();
                  warningModal = false;
                }}
              >
                Annuler
              </Button>
            </div>
          </div>
        )
      });
      return;
    }
    Modal.destroyAll();
    warningModal = false;
    updatePayBl(blToPay.id, remiseChecked, bl.reglements, id).then((res) => {
      setisBLModalVisible(false);
    });
  };

  const toPayeFacture = (reglements) => {
    payFactureUpdate(facture.id, reglements).then(() => {
      setisFactureModalVisible(false);
    });
  };

  return props.loaded ? (
    <Router history={history}>
      <Switch>
        <Route path="/login">
          {null == props.user ? <Login></Login> : <Redirect to="/" />}
        </Route>
        <Route
          path=""
          exact={true}
          render={({ location }) => (
            <TopNavLayout AppStore={AppStore}>
              <Admin AppStore={AppStore} location={location} />
            </TopNavLayout>
          )}
        ></Route>
        <Route path="*">
          <Redirect to="/login" />
        </Route>
      </Switch>
      <Modal visible={openModal} title="Réponse" footer={[]}>
        <Form.Item>
          <Input
            value={notifText}
            onChange={(e) => setNotifText(e.target.value)}
          />
        </Form.Item>
        <div style={{ textAlign: "center" }}>
          <Button type="primary" onClick={() => sendNotification()}>
            Envoyer
          </Button>
        </div>
      </Modal>
      <BonLivraisonModal
        entete={false}
        id={blToInspect}
        onClose={() => setBlToInspect(null)}
      />

      <Modal
        title={"Caisse"}
        visible={isOpen}
        onOk={() => validateCaisse()}
        footer={[
          <Button type="primary" onClick={() => validateCaisse()}>
            Valider
          </Button>,
          <Button
            type="dashed"
            onClick={() => {
              openModalPay();
            }}
          >
            Modifier
          </Button>,
          <Button type="default" onClick={() => setIsOpen(false)}>
            Annuler
          </Button>
        ]}
        onCancel={(e) => setIsOpen(false)}
      >
        <Form layout="vertical" form={form}>
          <Form.Item
            name="value"
            label="Combien vous avez reçu pour ce règlement?"
            rules={[{ required: true, message: "Champ obligatoire" }]}
          >
            <Input type="number" style={{ width: "100%" }}></Input>
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        className="modal-large"
        visible={isBLModalVisible}
        onCancel={() => setisBLModalVisible(false)}
        footer={[]}
      >
        <ReglementBLList
          bl={blToPay}
          validate={toUpdate}
          setRemiseChecked={(checked) => setRemiseChecked(checked)}
        />
      </Modal>
      <Modal
        className="modal-large"
        visible={isFactureModalVisible}
        onCancel={() => setisFactureModalVisible(false)}
      >
        <ReglementFacture toPayeFacture={toPayeFacture} facture={facture} />
      </Modal>
    </Router>
  ) : (
    <></>
  );
}

const mapStateToProps = ({ authState }) => {
  return {
    loaded: authState.loaded,
    user: authState.user
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loadUIConfig: (config) => dispatch(loadUIConfig(config)),
    loadUser: () => dispatch(loadUser()),
    logout: () => dispatch(logout())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
