import { Divider, Descriptions, Form, Modal, Row, Button, Empty, Col, Image, DatePicker } from "antd";
import { useState, useEffect } from "react";
import ArticleHistory from "./ArticleHistory";
import TecdocLiaison from "./TecdocLiaison";
import { RiseOutlined } from "@ant-design/icons";
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, ReferenceLine, Cell, BarChart, Bar } from "recharts";
import moment from "moment";
import { toGetArticleStat } from "./Api";
var Barcode = require("react-barcode");

const ArticleDetail = ({ article, visible, setDetailVisible }) => {
  const [form] = Form.useForm();
  const [tecdocLiaison, setTecdocLiaison] = useState(false);
  const [selectedArticle, setSelectedArticle] = useState("");
  const [articleHistory, setArticleHistory] = useState(null);
  const [data, setData] = useState([]);
  const [date, setDate] = useState([moment().startOf("year").format("YYYY-MM-DD"), moment().endOf("year").format("YYYY-MM-DD")]);

  useEffect(() => {
    form.setFieldsValue(article);
    if (null != article.id) {
      getArticleStat(article.id, date[0], date[1]);
    }
  }, [article]);

  const getArticleStat = (id, start, end) => {
    toGetArticleStat(id, start, end).then((res) => {
      setData(res);
    });
  };

  const cancelCreateModal = () => {
    setDetailVisible(false, article);
  };
  const afterBind = (j, json) => {
    article.data = json.info;
    article.titre = json.name;
    article.b__tecdoc = json.referenceTecdoc == "" ? false : true;
    form.setFieldsValue(article);

    setTecdocLiaison(false);
  };

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      var month = moment.months(payload[0].payload.date - 1);
      var v = payload[0].payload.bl;
      return (
        <div className='app--graph-tooltip'>
          <p className='label'>{`${month}: ${v}`}</p>
        </div>
      );
    }

    return null;
  };

  return (
    <>
      <Modal
        className='smtv-modal modal-large'
        title={
          article != "" && (
            <>
              {article.titre2}
              <img
                style={{ marginLeft: "2vh" }}
                onClick={() => {
                  setTecdocLiaison(true);
                  setSelectedArticle(article);
                }}
                src='/images/tecdoc.png'
                className={article.b__tecdoc ? "auto-tecdoc-img" : "auto-tecdoc-img-gray"}
                alt='smtv-logo'
              />
            </>
          )
        }
        visible={visible}
        maskClosable={false}
        onCancel={(e) => cancelCreateModal(article)}
        footer={[
          <Button key='back' onClick={(e) => cancelCreateModal()}>
            Ok
          </Button>,
        ]}
        onOk={cancelCreateModal}
      >
        <div>
          <Row gutter={24}>
            <Col span='10'>
              <Button type='primary' size='large' icon={<RiseOutlined />} onClick={(e) => setArticleHistory(article)}>
                Historique
              </Button>
            </Col>
            <Col span='8'>{null != article && article.img != "" && <Image src={article.img} />}</Col>
          </Row>

          <Row gutter={8}>
            <Col span='24'>
              <Divider>Article détails</Divider>
              <Descriptions bordered size='small'>
                <Descriptions.Item label='Titre' className='item-label'>
                  {article.titre}
                </Descriptions.Item>
                <Descriptions.Item label='Titre 2'>{article.titre2}</Descriptions.Item>
                <Descriptions.Item label='Reférence'>{article.reference}</Descriptions.Item>
                <Descriptions.Item label='Reférence Origine'>{article.refOrigine}</Descriptions.Item>
                <Descriptions.Item label='Code'>{article.code}</Descriptions.Item>
                <Descriptions.Item label='Type'>{article.type}</Descriptions.Item>
                <Descriptions.Item label='Unite'>{article.unite}</Descriptions.Item>
                <Descriptions.Item label='Marque'>{article.marque}</Descriptions.Item>
                <Descriptions.Item label='Catégorie'>{article.categorie}</Descriptions.Item>
                <Descriptions.Item label='Emplacement'>{article.emplacement}</Descriptions.Item>
                <Descriptions.Item label='Seuil'>{article.seuil}</Descriptions.Item>
                <Descriptions.Item label='TVA'>{article.tva}</Descriptions.Item>
              </Descriptions>
              <br />
              <br />
            </Col>
          </Row>
          <Divider>Prix</Divider>

          <Descriptions bordered size='default'>
            <Descriptions.Item label='Prix achat HT'>{article.prixAchatHT}</Descriptions.Item>
            <Descriptions.Item label='Prix achat TTC'>{article.prixAchatTTC}</Descriptions.Item>
            <Descriptions.Item label='Marge'>{article.marge + " " + article.margeValue}</Descriptions.Item>
            <Descriptions.Item label='Prix Vente HT'>{article.prixVenteHT}</Descriptions.Item>
            <Descriptions.Item label='Prix Vente TTC'>{article.prixVenteTTC}</Descriptions.Item>
            <Descriptions.Item label='Prix Vente HT gros'>{article.prixVenteGrosHT}</Descriptions.Item>
            <Descriptions.Item label='Prix Vente TTC gros'>{article.prixVenteGrosTTC}</Descriptions.Item>
          </Descriptions>
          <br />
          <br />
          {article.b__tecdoc && article.data != null && article.data.details == null && (
            <>
              <Divider>Détails Tecdoc</Divider>

              <Descriptions column={2} bordered size='default'>
                {article.data.map((element) => (
                  <Descriptions.Item label={element.name}>{element.value}</Descriptions.Item>
                ))}
              </Descriptions>
            </>
          )}
          {article.b__tecdoc && article.data.details != null && (
            <>
              <Divider>Détails Tecdoc</Divider>

              <Descriptions column={2} bordered size='default'>
                {article.data.details.map((element) => (
                  <Descriptions.Item label={element.name}>{element.value}</Descriptions.Item>
                ))}
              </Descriptions>
              {null != article.data.origine && (
                <>
                  <Divider>References d'origine</Divider>

                  <Descriptions column={1} bordered size='default'>
                    {article.data.origine.map((element) => (
                      <Descriptions.Item label={element.name}>{element.value}</Descriptions.Item>
                    ))}
                  </Descriptions>
                </>
              )}
            </>
          )}
          <Divider>Statistiques</Divider>
          <Row justify='center'>
            <DatePicker.RangePicker
              defaultValue={[moment(date[0]), moment(date[1])]}
              onChange={(e, date) => getArticleStat(article.id, date[0], date[1])}
            />
          </Row>
          <Row>
            <Col span='24'>
              <div style={{ height: 600 }}>
                <ResponsiveContainer>
                  <BarChart
                    data={data}
                    margin={{
                      top: 10,
                      right: 30,
                      left: 0,
                      bottom: 0,
                    }}
                  >
                    <CartesianGrid strokeDasharray='3 3' />

                    <XAxis dataKey={(v) => moment.months(v.date - 1)} />
                    <Tooltip cursor={{ fill: "transparent" }} content={<CustomTooltip />} />
                    <Bar width={20} radius={4} dataKey='bl' fill='#5c3494' />

                    <YAxis type='number' dataKey={(v) => parseInt(v.bl)} />
                  </BarChart>
                </ResponsiveContainer>
              </div>
            </Col>
          </Row>
        </div>
      </Modal>

      {null != articleHistory && (
        <ArticleHistory setArticleHistory={() => setArticleHistory(null)} article={articleHistory} visible={articleHistory != null} />
      )}

      <Modal
        className='smtv-modal modal-large'
        visible={tecdocLiaison}
        maskClosable={false}
        title={selectedArticle.titre2 + " " + selectedArticle.reference}
        onCancel={(e) => setTecdocLiaison(false)}
        footer={[
          <Button key='back' onClick={(e) => setTecdocLiaison(false)}>
            Fermer
          </Button>,
        ]}
      >
        <TecdocLiaison selectedArticle={selectedArticle} setTecdocLiaison={(j, json) => afterBind(j, json)} />
      </Modal>
    </>
  );
};
export default ArticleDetail;
