import {
  Button,
  Typography,
  Skeleton,
  Row,
  Col,
  Modal,
  Alert,
  Input,
  Checkbox,
  Tooltip,
  Popconfirm,
  Select,
  DatePicker,
  Form
} from "antd";
import { useEffect, useState } from "react";
import { setItemsState } from "../../../Actions/ItemsSelectionAtions";
import { connect } from "react-redux";
import moment from "moment";
import localization from "moment/locale/fr";
import DataTable from "react-data-table-component";
import ls from "localstorage-ttl";

import {
  PrinterOutlined,
  InfoCircleTwoTone,
  DollarOutlined,
  DeleteOutlined
} from "@ant-design/icons";

import {
  toDeleteFacture,
  toGetFactures,
  toGetProviderList,
  toPrintFacture,
  toGetTotalFactures,
  toGetFacturesAllAchat
} from "../APIs";
import printJS from "print-js";
import PaymentFournisseur from "../ReglementFournisseur/PaymentFournisseur";
import FactureReceptionModal from "./FactureReceptionModal";
import { toGetCode, toGetUsers } from "../../Compte/Api";
import Search from "../../../Utils/Search";
import { customFilter, toConvertDate } from "../../../Utils/Utils";

import Loading from "react-loading-bar";
import "react-loading-bar/dist/index.css";
import { toPrintRetenuAchat } from "../../Vente/APIs";

const { Option } = Select;
moment.updateLocale("fr", localization);

const FacturesList = (props) => {
  const [selectedMethode, setSelectedMethode] = useState(2);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [selectedDate, setSelectedDate] = useState(0);
  const [displayDate, setDisplayDate] = useState(false);
  const [displayDateRange, setDisplayDateRange] = useState(false);
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState("0");
  const [selectedDateValue, setSelectedDateValue] = useState("0");
  const [providers, setProviders] = useState([]);
  const [show, setShow] = useState(true);
  const [selectedProvider, setSelectedProvider] = useState("0");
  const [total, setTotal] = useState(0);
  const [factures, setFactures] = useState([]);
  const [facturesAll, setFacturesAll] = useState([]);
  const [filterModalVisible, setFilterModalVisible] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [facture, setFacture] = useState(null);
  const [factureToInspect, setFactureToInspect] = useState(null);
  const [factureToPay, setFactureToPay] = useState(null);
  const [paymentModalVisible, setPaymentModalVisible] = useState(false);
  const [loading, setLoading] = useState(true);
  const [adminCode, setAdminCode] = useState("");
  const [code, setCode] = useState("");
  const [isAdminModalVisible, setAdminModalVisible] = useState(false);
  const [wrongAdminCode, setWrongAdminCode] = useState(false);
  const [checkedItems, setCheckedItems] = useState([]);

  const fields = [
    { name: "Référence", field: "s__ref", type: "varchar" },
    { name: "Total", field: "total", type: "number" },
    { name: "Fournisseur", field: "providerName", type: "varchar" },
    { name: "Remise", field: "discount", type: "number" },
    { name: "Nombre de bon de réceptions", field: "numberBR", type: "number" },
    { name: "Payé", field: "b__paied", type: "boolean" },
    { name: "Date de création", field: "createdDate", type: "date" }
  ];

  const printRetenu = (id) => {
    toPrintRetenuAchat(id);
  };

  const columns = [
    {
      name: "Fournisseur",
      selector: "providerName",
      sortable: true,
      grow: 1.5
    },

    {
      name: "Crée par",
      selector: "l__createdBy",
      sortable: true,
      grow: 0.75
    },
    {
      name: "Date",
      selector: "createdDate",
      sortable: true,
      grow: 1.25,

      format: (row) => `${toConvertDate(row.createdDate)}`
    },
    {
      name: "Référence",
      selector: "refProvider",
      sortable: true,
      format: (facture) => (
        <span
          className="auto--pointer"
          onClick={() => {
            setFactureToInspect(facture);
          }}
        >
          {facture.refProvider}
        </span>
      )
    },

    {
      name: "Total",
      selector: "total",
      sortable: true,
      grow: 0.75,

      format: (row) => `${row.total.toFixed(3) + " DT"}`
    },
    {
      name: "Reste",
      selector: "reste",
      sortable: true,
      grow: 0.75,

      format: (row) =>
        `${parseFloat(row.total - getReste(row.reglements)).toFixed(3) + "DT"} `
    },
    {
      name: "Payé",
      selector: "paied",
      sortable: true,
      grow: 0.75,

      format: (row) =>
        row.b__paied ? (
          <span className={"auto--color-success"}>Payé</span>
        ) : (
          <span className={"auto--color-danger"}>Non payé</span>
        )
    },
    {
      name: "",
      selector: "id",
      grow: 1.75,

      format: (facture) => (
        <div style={{ float: "right" }}>
          <Tooltip title="Pour imprimer sans entête" color="#2db7f5">
            <Checkbox onChange={() => toCheck(facture)}></Checkbox>
          </Tooltip>

          <Tooltip title="Pour imprimer une facture détaillée" color="#2db7f5">
            <Button
              onClick={(e) => printFacture(e, facture, true)}
              onDoubleClick={(e) => printFacture(e, facture, true)}
              icon={<PrinterOutlined />}
              style={{ marginRight: 1, marginLeft: 5 }}
            ></Button>
          </Tooltip>
          <Tooltip title="Pour imprimer une facture groupée" color="#2db7f5">
            <Button
              icon={<PrinterOutlined />}
              type="link"
              style={{ marginRight: 1 }}
              onClick={(e) => printFacture(e, facture, false)}
            ></Button>
          </Tooltip>
          <Button
            onClick={() => {
              setFactureToInspect(facture);
            }}
            icon={<InfoCircleTwoTone />}
            style={{ marginRight: 1 }}
          ></Button>
          <Button
            disabled={facture.b__paied > 0}
            onClick={() => {
              setPaymentModalVisible(true);
              setFactureToPay(facture);
            }}
            icon={<DollarOutlined />}
            style={{ marginRight: 1 }}
          ></Button>
          <Popconfirm
            title={`Voulez vous vraiment supprimer cette facture?`}
            onConfirm={() => deleteFactureReception(facture)}
            okText="Oui"
            cancelText="Non"
            style={{ marginRight: 1 }}
          >
            <Button
              disabled={facture.b__paied > 0}
              icon={<DeleteOutlined />}
            ></Button>
          </Popconfirm>
          {facture.total > 1000 && (
            <Tooltip
              title="Pour imprimer le retenu à la source"
              color="#58c5cc"
              placement="leftTop"
            >
              <Button
                style={{ marginTop: "2vh" }}
                onClick={() => printRetenu(facture.id)}
              >
                C.R.I
              </Button>
            </Tooltip>
          )}
        </div>
      )
    }
  ];

  const printAllFactures = () => {
    setLoading(true);
    setShow(true);
    toGetFacturesAllAchat(0, 10, {
      paied: selectedMethode,
      provider: selectedProvider,
      user: selectedUser,
      date: selectedDate,
      dateValue: selectedDateValue,
      ref: searchValue
    }).then((data) => {
      var res = [];
      data.res.forEach((element) => {
        res.push({
          chargé: element.l__createdBy,
          date: toConvertDate(element.createdDate),
          ref: element.s__ref,
          fournisseur: element.providerName,
          total: element.total.toLocaleString(
            undefined, // leave undefined to use the visitor's browser
            // locale or a string like 'en-US' to override it.
            { minimumFractionDigits: 3 }
          ),
          etat: element.b__paied ? "Payé" : "non payé"
        });
      });
      printJS({
        printable: res,
        properties: ["ref", "date", "chargé", "fournisseur", "total", "etat"],
        type: "json",
        header: "<h4> Liste des factures </h4>"
      });
      setLoading(false);
      setShow(false);
    });
  };

  useEffect(() => {
    toGetUsers().then((users) => {
      setUsers(users);
    });

    if (null != ls.get("providers")) {
      setProviders(ls.get("providers"));
      setLoading(false);
    }
    toGetProviderList().then((res) => {
      setProviders(res);
      setLoading(false);
    });

    fetchFactures(0, {
      paied: selectedMethode,
      provider: selectedProvider,
      user: selectedUser,
      date: selectedDate,
      dateValue: selectedDateValue,
      ref: searchValue
    });
    toGetCode().then((res) => {
      setCode(res.code);
    });
  }, []);

  const actualise = () => {
    toGetFactures().then((data) => {
      setFactures(data);

      setFacturesAll(data);
      setLoading(false);
      setShow(false);
    });
  };

  const onChangeCredit = (option) => {
    setSelectedMethode(option);
    fetchFactures(0, {
      paied: option,
      provider: selectedProvider,
      user: selectedUser,
      date: selectedDate,
      dateValue: selectedDateValue,
      ref: searchValue
    });
  };

  const onChangeFournisseur = (option) => {
    var id = 0;

    if (option == "Tout") {
      id = 0;
    } else {
      id = providers.filter((el) => el.s__nom == option)[0].id;
    }
    setSelectedProvider(id);
    fetchFactures(0, {
      paied: selectedMethode,
      provider: id,
      user: selectedUser,
      date: selectedDate,
      dateValue: selectedDateValue,
      ref: searchValue
    });
  };

  const onChangeUsers = (option) => {
    var id = "0";
    if (option != "0") {
      id = users.filter((el) => el.username == option)[0].id;
    }
    setSelectedUser(id);
    fetchFactures(0, {
      paied: selectedMethode,
      provider: selectedProvider,
      user: id,
      date: selectedDate,
      dateValue: selectedDateValue,
      ref: searchValue
    });
  };

  const onChangeDate = (option) => {
    if (option == "5") {
      setSelectedDate(option);
      setDisplayDate(true);
      setDisplayDateRange(false);
      return;
    }
    if (option == "6") {
      setSelectedDate(option);
      setDisplayDateRange(true);
      setDisplayDate(false);
      return;
    }
    setDisplayDate(false);

    setSelectedDate(option);

    fetchFactures(0, {
      paied: selectedMethode,
      provider: selectedProvider,
      user: selectedUser,
      date: option,
      dateValue: selectedDateValue,
      ref: searchValue
    });
  };

  const onSearch = (e) => {
    setSearchValue(e);

    setLoading(true);

    fetchFactures(0, {
      paied: selectedMethode,
      provider: selectedProvider,
      user: selectedUser,
      date: selectedDate,
      dateValue: selectedDateValue,
      ref: e
    });
  };

  const handlePageChange = (page) => {
    fetchFactures(page - 1, {
      paied: selectedMethode,
      provider: selectedProvider,
      user: selectedUser,
      date: selectedDate,
      dateValue: selectedDateValue,
      ref: searchValue
    });
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setLoading(true);
    setShow(true);

    toGetFactures(page - 1, newPerPage, {
      paied: selectedMethode,
      provider: selectedProvider,
      user: selectedUser,
      date: selectedDate,
      dateValue: selectedDateValue,
      ref: searchValue
    }).then((data) => {
      setFactures(data);
      setFacturesAll(data);
      setLoading(false);

      setShow(false);
    });

    setPerPage(newPerPage);
    setLoading(false);
  };

  const fetchFactures = async (page, options) => {
    setLoading(true);
    setShow(true);
    setTotal(0);
    toGetFactures(page, perPage, options).then((data) => {
      setFactures(data.res);

      setFacturesAll(data.res);
      setLoading(false);
      setShow(false);
    });
    toGetTotalFactures(options).then((data) => {
      setTotalRows(data.nb);
    });
  };

  const toUpdateData = (values) => {
    setFactures(values);
    setFilterModalVisible(false);
  };

  const toCheck = (facture) => {
    var length = checkedItems.filter((element) => element.id == facture.id)
      .length;
    if (length === 0) {
      setCheckedItems([...checkedItems, facture]);
    } else {
      let items = checkedItems.filter((element) => element.id !== facture.id);
      setCheckedItems(items);
    }
  };
  const toVerifyIfChecked = (facture) => {
    var length = checkedItems.filter((element) => element.id == facture.id)
      .length;
    if (length === 0) {
      return false;
    }
    return true;
  };

  const printFacture = (e, facture, detail) => {
    let id = facture.id;
    if (toVerifyIfChecked(facture)) {
      toPrintFacture(id, false, detail);
    } else {
      toPrintFacture(id, true, detail);
    }
    const factureIndex = factures.findIndex((f) => f.id === id);
    const newFactures = [...factures];
    newFactures[factureIndex].b__locked = 1;
    setFactures(newFactures);
  };
  const deleteFactureReception = async (facture) => {
    setFacture(facture);
    if (facture.b__locked && !isAdminModalVisible) {
      setAdminModalVisible(true);
      return;
    }
    if (isAdminModalVisible) {
      try {
        await verifyAdminCode(adminCode);
      } catch (e) {
        setWrongAdminCode(true);
        return;
      }
    }

    toDeleteFacture(facture.id);
    setAdminModalVisible(false);
    setFactures(factures.filter((f) => f.id !== facture.id));
    setFacturesAll(factures.filter((f) => f.id !== facture.id));
  };
  const verifyAdminCode = (l__code) => {
    if (l__code == code) return Promise.resolve();
    else return Promise.reject();
  };
  const toSavePayment = () => {
    fetchFactures(0, {
      paied: selectedMethode,
      provider: selectedProvider,
      user: selectedUser,
      date: selectedDate,
      dateValue: selectedDateValue,
      ref: searchValue
    });
    setPaymentModalVisible(false);
  };
  const getReste = (reglements) => {
    if (reglements.length == 0) return 0;
    return reglements.reduce((a, b) => b.montant + a, 0);
  };

  const customStyles = {
    headCells: {
      style: {
        fontWeight: "bold",
        fontSize: "1rem"
      }
    }
  };

  return (
    <div id="achat">
      <Loading show={show} color="red" showSpinner={true} />
      <Form layout="vertical">
        <Row className="auto--custom-header">
          <Col span="4">
            <Typography.Title level={5}>LISTE DES FACTURES</Typography.Title>
          </Col>
          <Col span="3" style={{ textAlign: "right" }} justify="end">
            <Form.Item label="Réf,note,fournisseur...">
              <Input.Search onSearch={onSearch} enterButton />
            </Form.Item>
          </Col>
          <Col span="2">
            <Form.Item label="Crédit">
              <Select
                disabled={show}
                onSelect={onChangeCredit}
                style={{ width: "80%" }}
                defaultValue="2"
              >
                <Option value="2">Tout</Option>
                <Option value="0">Non payé</Option>
                <Option value="1">Payé</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span="4">
            <Form.Item label="Date">
              <Select
                disabled={show}
                showSearch
                onSelect={onChangeDate}
                style={{ width: "100%" }}
                defaultValue="0"
              >
                <Option value="0">Tout</Option>

                <Option value="1">Hier</Option>
                <Option value="2">Aujourd'hui</Option>
                <Option value="3">Ce mois</Option>
                {/* <Option value="4">Cette année</Option>*/}
                <Option value="5">Date Spécifique</Option>
                <Option value="6">Période Spécifique</Option>
              </Select>
            </Form.Item>
            {displayDate && (
              <DatePicker
                disabled={show}
                style={{ width: "100%" }}
                onChange={(e, date) => {
                  setSelectedDateValue(date);
                  // fetchFactures(0, {
                  //   paied: selectedMethode,
                  //   provider: selectedProvider,
                  //   user: selectedUser,
                  //   date: selectedDate,
                  //   dateValue: date,
                  //   ref: searchValue,
                  // });
                }}
              />
            )}
            {displayDateRange && (
              <DatePicker.RangePicker
                disabled={show}
                onChange={(e, date) => {
                  setSelectedDateValue(date);
                  // fetchFactures(0, {
                  //   paied: selectedMethode,
                  //   provider: selectedProvider,
                  //   user: selectedUser,
                  //   date: selectedDate,
                  //   dateValue: date,
                  //   ref: searchValue,
                  // });
                }}
              />
            )}
          </Col>

          <Col span="4">
            <Form.Item label="Fournisseur">
              <Select
                disabled={show}
                showSearch
                onSelect={onChangeFournisseur}
                style={{ width: "80%" }}
                defaultValue="Tout"
              >
                <Option value="Tout">Tout</Option>

                {providers.map((c) => (
                  <Option value={c.s__nom}>{c.s__nom}</Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span="4">
            <Form.Item label="Utilisateurs">
              <Select
                disabled={show}
                showSearch
                onSelect={onChangeUsers}
                style={{ width: "80%" }}
                defaultValue="0"
              >
                <Option value="0">Tout</Option>

                {users.map((u) => (
                  <Option value={u.username}>{u.username}</Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          {/* <Col span="3" style={{ textAlign: "right" }} justify="end">
            <Input.Search onSearch={onSearch} enterButton />
          </Col> */}
          <Col span="2" style={{ textAlign: "right" }}>
            {/* <Button
              type="link"
              onClick={(e) => setFilterModalVisible(true)}
              icon={
                <img
                  src="/images/filterIconVente.svg"
                  style={{ width: "3vh", height: "3vh" }}
                />
              }
              style={{ marginRight: "5px" }}
            ></Button> */}

            <Tooltip title="Actualiser la liste des factures">
              <Button
                onClick={() => actualise()}
                type="link"
                size="large"
                icon={<img src="/images/refresh.svg" />}
              ></Button>
            </Tooltip>
            <Tooltip
              title="Pour imprimer le résultat de la recherche"
              color="#58c5cc"
              placement="leftTop"
            >
              <Button
                type="link"
                onClick={(e) => printAllFactures()}
                icon={
                  <img
                    src="/images/print.svg"
                    className="auto--icon-list"
                  ></img>
                }
              ></Button>
            </Tooltip>
          </Col>
        </Row>
      </Form>{" "}
      <Row>
        <Col span="24">
          <DataTable
            customStyles={customStyles}
            columns={columns}
            data={factures}
            pagination
            //selectableRows
            noHeader
            striped
            selectableRowsHighlight
            fixedHeader
            //onSelectedRowsChange={(e) => onSelectRow(e)}
            persistTableHead
            noDataComponent={<span>Pas de facture(s) trouvés</span>}
            contextMessage={{
              singular: "élément",
              plural: "éléments",
              message: "séléctionné(s)"
            }}
            pagination
            paginationServer
            paginationTotalRows={totalRows}
            selectableRows
            onChangeRowsPerPage={handlePerRowsChange}
            onChangePage={handlePageChange}
          />
          {/*  <div className="table-wrapper auto--overflow">
            <table className="ant-table" style={{ width: "100%" }}>
              <thead className="ant-table-thead">
                <tr>
                  <th className="ant-table-cell">Date</th>
                  <th className="ant-table-cell">Référence</th>
                  <th className="ant-table-cell">Fournisseur</th>
                  <th className="ant-table-cell">
                    Référence facture fournisseur
                  </th>

                  <th className="ant-table-cell">Prix Total</th>
                  <th className="ant-table-cell">Reste</th>

                  <th className="ant-table-cell">Etat</th>
                  <th className="ant-table-cell"></th>
                </tr>
              </thead>
              <Skeleton active loading={loading} />
              <tbody className="ant-table-tbody">
                {factures &&
                  factures.map((facture, i) => (
                    <tr
                      key={facture.id}
                      className={i % 2 != 0 && "auto--background-grey"}
                    >
                      <td className="ant-table-cell">
                        {moment(facture.createdDate).format("D MMMM YYYY")}
                      </td>
                      <td className="ant-table-cell">{facture.s__ref}</td>
                      <td className="ant-table-cell">{facture.providerName}</td>
                      <td className="ant-table-cell">{facture.refProvider}</td>

                      <td className="ant-table-cell">
                        {parseFloat(facture.total).toFixed(3)}
                      </td>
                      <td className="ant-table-cell">
                        {parseFloat(
                          facture.total - getReste(facture.reglements)
                        ).toFixed(3)}
                      </td>
                      <td className="ant-table-cell">
                        {facture.b__paied ? (
                          <span className="auto--color-success">Payé</span>
                        ) : (
                          <span className="auto--color-danger">Non payé</span>
                        )}
                      </td>
                      <td className="ant-table-cell" style={{ float: "right" }}>
                        <Tooltip
                          title="Pour imprimer sans entête"
                          color="#2db7f5"
                        >
                          <Checkbox
                            onChange={() => toCheck(facture)}
                          ></Checkbox>
                        </Tooltip>
                        <Tooltip
                          title="Pour imprimer une facture détaillée"
                          color="#2db7f5"
                        >
                          <Button
                            onClick={(e) => printFacture(e, facture, true)}
                            onDoubleClick={(e) =>
                              printFacture(e, facture, true)
                            }
                            icon={<PrinterOutlined />}
                            style={{ marginRight: 1, marginLeft: 5 }}
                          ></Button>
                        </Tooltip>
                        <Tooltip
                          title="Pour imprimer une facture groupée"
                          color="#2db7f5"
                        >
                          <Button
                            icon={<PrinterOutlined />}
                            type="link"
                            style={{ marginRight: 1 }}
                            onClick={(e) => printFacture(e, facture, false)}
                          ></Button>
                        </Tooltip>
                        <Button
                          onClick={() => {
                            setFactureToInspect(facture);
                          }}
                          icon={<InfoCircleTwoTone />}
                          style={{ marginRight: 1 }}
                        ></Button>
                        <Button
                          disabled={facture.b__paied > 0}
                          onClick={() => {
                            setPaymentModalVisible(true);
                            setFactureToPay(facture);
                          }}
                          icon={<DollarOutlined />}
                          style={{ marginRight: 1 }}
                        ></Button>
                        <Popconfirm
                          title={`Voulez vous vraiment supprimer cette facture?`}
                          onConfirm={() => deleteFactureReception(facture)}
                          okText="Oui"
                          cancelText="Non"
                        >
                          <Button
                            disabled={facture.b__paied > 0}
                            icon={<DeleteOutlined />}
                          ></Button>
                        </Popconfirm>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
       */}{" "}
        </Col>
      </Row>
      <Skeleton active loading={loading} />
      <Modal
        title="Operation non autorisée"
        visible={isAdminModalVisible}
        onOk={() => deleteFactureReception(facture)}
        onCancel={() => setAdminModalVisible(false)}
      >
        <p>Cette opération n'est pas permise car la facture a été imprimée</p>
        <p>Pour valider l'opération merci d'entrer le code admin</p>
        {wrongAdminCode && (
          <Alert
            message="Code admin incorrect"
            type="error"
            style={{ marginBottom: 10 }}
          />
        )}
        <Input
          placeholder="Code admin"
          onChange={(e) => setAdminCode(e.target.value)}
        />
      </Modal>
      <PaymentFournisseur
        factureToPay={null != factureToPay && factureToPay}
        visible={paymentModalVisible}
        closePaymentModal={() => setPaymentModalVisible(false)}
        toSavePayment={() => toSavePayment()}
      />
      <FactureReceptionModal
        factureReception={factureToInspect}
        onClose={() => setFactureToInspect(null)}
      />
      <Modal
        className="modal-small"
        title={"Filtrer"}
        visible={filterModalVisible}
        footer={[]}
        onCancel={(e) => setFilterModalVisible(false)}
      >
        <Search
          toUpdateData={toUpdateData}
          fields={fields}
          list={facturesAll}
        />
      </Modal>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    setQuoteToUpdate: (quote) => dispatch(setItemsState(quote))
  };
};

export default connect(null, mapDispatchToProps)(FacturesList);
