import {
  Typography,
  Select,
  Row,
  Col,
  Form,
  DatePicker,
  Skeleton,
  Tag
} from "antd";
import { useEffect, useState } from "react";
import { toGetTotalUser } from "./APIs";
import moment from "moment";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  ReferenceLine,
  Cell
} from "recharts";
import Loading from "react-loading-bar";

import { toGetUsers } from "../Compte/Api";
import { getMax } from "../../Utils/Utils";
const { RangePicker } = DatePicker;

const { Option } = Select;
const colors = [
  "#00aeef",
  "#5c3494",
  "#108d9a",
  "#72cbf2",
  "#846eb1",
  "#4eb4e6",

  "#694a9e",

  "#5c3494",
  "#19b4bb",
  "#2696d3",
  "#eeedee",
  " #231f20",

  "#432c79",
  "#ef5350"
];

const StatsUser = ({ AppStore, ...props }) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [users, setUsers] = useState([]);
  const [all, setAll] = useState([]);
  const [selected, setSelectedUser] = useState([]);
  const [start, setStart] = useState(null);
  const [end, setEnd] = useState(null);
  const [y, setY] = useState(0);
  const [max, setMax] = useState(10000);

  useEffect(() => {
    AppStore.setFirst(2);
    AppStore.setSecond(14);

    toGetUsers().then((users) => {
      getData(
        moment(new Date(), "YYYY-MM-DD").subtract(7, "d"),
        moment(new Date(), "YYYY-MM-DD"),
        [users.filter((e) => e.active)[0].id]
      );
      setUsers(users.filter((e) => e.active));
    });
  }, []);

  function groupBy(list, keyGetter) {
    const map = new Map();
    list.forEach((item) => {
      const key = keyGetter(item);
      const collection = map.get(key);
      if (!collection) {
        map.set(key, [item]);
      } else {
        collection.push(item);
      }
    });
    return map;
  }
  const getData = (start, end, ids) => {
    setData([]);

    setLoading(true);
    setStart(start);
    setEnd(end);

    toGetTotalUser(start, end, ids).then((res) => {
      setAll(res);
      var results = [];
      var total = 0;
      res.forEach((element) => {
        results.push({ date: element.date, total: element.total });
        total += parseFloat(element.total);
      });
      setMax(Math.ceil(getMax(res) + 500));
      setTimeout(() => {
        setData(res);
        setLoading(false);
        setY(total / results.length);
      }, 500);
    });
  };

  const onChangeUsers = (option) => {
    setData([]);
    var ids = [];
    if (option.length == 0) {
      return;
    }
    option.forEach((element) => {
      ids.push(users.filter((el) => el.username == element)[0].id);
    });
    setSelectedUser(ids);
    getData(start, end, ids);

    // const grouped = groupBy(all, (p) => p.date);
    // var res = [];
    // grouped.forEach((element) => {
    //   if (id == -1) {
    //     var total = element.reduce((a, b) => a + parseFloat(b.total), 0);
    //     res.push({ date: element[0].date, total: total.toFixed(0) });
    //   } else {
    //     var el = element.filter((e) => e.id == id);
    //     if (el.length > 0) {
    //       res.push({ date: el[0].date, total: el[0].total });
    //     }
    //   }
    // });
    // setData(res);
  };
  const getUsername = (el) => {
    return users.filter((e) => e.id == el)[0].username;
  };

  return (
    <>
      <Loading show={loading} color="red" showSpinner={true} />

      <Row className="auto--custom-header">
        <Col span="8">
          <Typography.Title level={3}>
            Statistiques utilisateur
          </Typography.Title>
        </Col>
        <Col span="6">
          <Form.Item label="Date">
            <RangePicker
              onCalendarChange={(e, date) =>
                getData(date[0], date[1], selected)
              }
              defaultValue={[
                moment(new Date(), "YYYY-MM-DD").subtract(7, "d"),
                moment(new Date(), "YYYY-MM-DD")
              ]}
              format={"YYYY-MM-DD"}
            />
          </Form.Item>
        </Col>
        <Col span="4">
          <Form.Item label="Utilisateur">
            {users.length > 0 && (
              <Select
                showSearch
                mode="multiple"
                onChange={onChangeUsers}
                style={{ width: "80%" }}
                defaultValue={users.filter((e) => e.active)[0].username}
              >
                {users.map((u) => (
                  <Option value={u.username}>{u.username}</Option>
                ))}
              </Select>
            )}
          </Form.Item>
        </Col>
      </Row>
      <Skeleton active loading={loading} />

      {data.length > 0 && (
        <>
          <Row>
            <Col span="24">
              <div style={{ height: 600, marginTop: "4vh" }}>
                <ResponsiveContainer width="100%" height="100%">
                  <AreaChart
                    data={data}
                    margin={{
                      top: 10,
                      right: 30,
                      left: 0,
                      bottom: 0
                    }}
                  >
                    <CartesianGrid stroke="#ccc" strokeDasharray="3 3" />

                    <XAxis dataKey="date" />
                    <YAxis
                      type="number"
                      dataKey={(v) => parseInt(v.total)}
                      domain={[0, 30000]}
                    />
                    <ReferenceLine
                      y={y}
                      label={"Moyenne: " + Math.floor(y) + " dt"}
                      stroke="red"
                      strokeDasharray="3 3"
                    />

                    <Tooltip />
                    {selected.map((el, i) => (
                      <Area
                        type="monotone"
                        dataKey={getUsername(el)}
                        stroke={colors[i]}
                        fill={colors[i]}
                        stackId={i}
                      ></Area>
                    ))}
                  </AreaChart>
                </ResponsiveContainer>
              </div>
            </Col>
          </Row>
          <Row justify="center">
            {selected.map((el, i) => (
              <Tag color={colors[i]}>{getUsername(el)}</Tag>
            ))}
          </Row>
        </>
      )}
    </>
  );
};

export default StatsUser;
