import {
  Button,
  Col,
  Form,
  Input,
  InputNumber,
  Modal,
  Popconfirm,
  Row,
  Typography,
  Skeleton,
} from "antd";
import { useState, useEffect } from "react";
import { toGetTVAList, toDeleteTVA, toAddOrUpdateTVA } from "./Api";
import { BiPencil, BiTrash } from "react-icons/bi";
import moment from "moment";
import { errorMsg, successMsg } from "../../Utils/Utils";
import localization from "moment/locale/fr";
import "../../Styles/TwoTables.scss";
import { PlusOutlined } from "@ant-design/icons";

moment.updateLocale("fr", localization);

const TVA = () => {
  const [loading, setLoading] = useState(true);
  const [createModalVisible, setCreateModalVisible] = useState(false);
  const [TVAs, setTVAs] = useState([]);
  const [title, setTitle] = useState(0);
  const [id, setId] = useState(-1);
  const [form] = Form.useForm();

  const closeCreateModal = () => {
    if (title == "") {
      errorMsg("veuillez remplir tous les champs obligatoires!");
      return;
    }
    toAddOrUpdateTVA(title, id).then((res) => {
      successMsg(
        id != -1 ? "TVA mis à jours avec succées!" : "TVA ajoutée avec succées!"
      );
      if (id == -1) {
        setTVAs([...TVAs, res]);
      } else {
        const TVAIndex = TVAs.findIndex((m) => m.id == id);
        const newTVAs = [...TVAs];
        newTVAs[TVAIndex] = {
          ...TVAs[TVAIndex],
          ...res,
        };
        setTVAs(newTVAs);
      }
    });
    setId(-1);
    setCreateModalVisible(false);
    setTitle("");
  };

  useEffect(() => {
    toGetDatas();
  }, []);
  const toGetDatas = () => {
    toGetTVAList().then((res) => {
      setTVAs(res);
      setLoading(false);
    });
  };
  const toDelete = (id) => {
    toDeleteTVA(id).then((res) => {
      let newTVAs = TVAs.filter((t) => t.id != id);
      setTVAs(newTVAs);
    });
  };

  const toEdit = (id, title) => {
    setId(id);
    setTitle(title);
    form.setFieldsValue({ title: title });
    setCreateModalVisible(true);
  };
  const toAdd = () => {
    setId(-1);
    setTitle("");
    form.setFieldsValue({ title: "" });
    setCreateModalVisible(true);
  };
  const exitCreateModal = () => {
    setId(-1);
    setTitle("");

    setCreateModalVisible(false);
  };

  return (
    <>
      <Row>
        <Col span="12">
          <Typography.Title level="2">TVAs</Typography.Title>
        </Col>
        <Col span="12" style={{ textAlign: "right" }}>
          <Button
            icon={<PlusOutlined />}
            onClick={(e) => toAdd()}
            type="primary"
            size="large"
          >
            Ajouter un TVA
          </Button>
        </Col>
      </Row>

      <Row
        style={{
          overflowY: "hidden",
        }}
      >
        <Col span="24">
          <div className="table-wrapper auto--overflow">
            <table className="ant-table" style={{ width: "100%" }}>
              <thead className="ant-table-thead">
                <tr>
                  <th className="ant-table-cell">Date création</th>
                  <th className="ant-table-cell">Valeur</th>
                  <th className="ant-table-cell">Crée par</th>
                  <th className="ant-table-cell"></th>
                </tr>
              </thead>
              <Skeleton active loading={loading} />

              <tbody className="ant-table-tbody ">
                {null != TVAs &&
                  TVAs.length > 0 &&
                  TVAs.map((TVA, i) => (
                    <tr key={i}>
                      <td className="ant-table-cell">
                        {moment(TVA.createdDate).format("D MMMM YYYY")}
                      </td>
                      <td className="ant-table-cell">{TVA.title}</td>
                      <td className="ant-table-cell">{TVA.createdBy}</td>
                      <td className="ant-table-cell" style={{ float: "right" }}>
                        <Button
                          onClick={() => toEdit(TVA.id, TVA.title)}
                          icon={<BiPencil />}
                        />

                        <Popconfirm
                          title={`Voulez vous vraiment supprimer ${TVA.title}?`}
                          onConfirm={(e) => toDelete(TVA.id)}
                          okText="Oui"
                          cancelText="Non"
                        >
                          <Button icon={<BiTrash />} />
                        </Popconfirm>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </Col>
      </Row>
      <Modal
        title={id != -1 ? "Modifier un TVA" : "Ajouter un nouveau TVA"}
        visible={createModalVisible}
        onOk={closeCreateModal}
        onCancel={exitCreateModal}
      >
        <Form name="basic" form={form}>
          <Form.Item
            label="Valeur"
            name="title"
            rules={[{ required: true, message: "Champ obligatoire" }]}
          >
            <InputNumber
              style={{ width: "100%" }}
              onChange={(e) => setTitle(e)}
            />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default TVA;
