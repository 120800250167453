const { UI_ACTIONS } = require("../Actions/Actions")
const { Layouts } = require("../Utils/LayoutsEnum")

const default_ui = {
    layout : Layouts.LEFTNAV
}

const initialState = {
    layout : null
}

const UIReducer = (state = initialState, { type, data }) => {
    switch(type){
        case UI_ACTIONS.SET_LAYOUT : 
            const config = {...state, layout : data.layout };
            localStorage.setItem('ui_config', JSON.stringify(config));
            return config;
        case UI_ACTIONS.SET_CONFIG:
            localStorage.setItem("ui_config", JSON.stringify(data.config));
            return data.config;
        case UI_ACTIONS.LOAD_DEFAULT:
            localStorage.setItem("ui_config", JSON.stringify(default_ui));
            return default_ui;
        default:
            return state;
    }
}

export default UIReducer;