import {
  Tabs,
  Col,
  Descriptions,
  Modal,
  Row,
  Empty,
  Typography,
  Button,
  Tooltip,
  Divider,
  Switch,
  Form,
  Statistic,
  DatePicker,
  Select
} from "antd";
import {
  PieChart,
  Pie,
  Cell,
  Legend,
  Tooltip as TooltipRecharts
} from "recharts";
import dayjs from "dayjs";

import { useEffect, useState } from "react";
import StateTag from "../../../Components/StateTag";
import "../../../Styles/ClientModal.scss";
import { toConvertDate, toConvertDateTime } from "../../../Utils/Utils";
import { RiArrowLeftRightFill, RiFileHistoryFill } from "react-icons/ri";
import { FcStatistics } from "react-icons/fc";
import {
  getBlsOfClients,
  getFactureOfClients,
  getQuotesOfClients,
  getReglementsOfClients,
  onDeactivatePatient,
  toGetClientHistory,
  toPrintClientHistory
} from "../APIs";

import printJS from "print-js";
import QuoteModal from "../Quotes/QuoteModal";
import BonLivraisonModal from "../BL/BonLivraisonModal";
import FactureModal from "../Factures/FactureModal";
import cookie from "react-cookies";
import moment from "moment";
import { toGetUsers } from "../../Compte/Api";

const { TabPane } = Tabs;
const { Option } = Select;
const ClientDetail = ({ visible, client, dismiss, setBLocked }) => {
  const [selectedMethode, setSelectedMethode] = useState(0);
  const [clients, setClients] = useState([]);
  const [selectedClient, setSelectedClient] = useState("0");
  const [selectedDate, setSelectedDate] = useState(0);
  const [displayDate, setDisplayDate] = useState(false);
  const [displayDateRange, setDisplayDateRange] = useState(false);
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState("0");
  const [selectedDateValue, setSelectedDateValue] = useState("0");

  const [bls, setBls] = useState([]);
  const [blsAll, setBlsAll] = useState([]);
  const [quotes, setQuotes] = useState([]);
  const [factures, setFactures] = useState([]);
  const [reglements, setReglements] = useState([]);
  const [step, setStep] = useState(0);
  const [quoteToInspect, setQuoteToInspect] = useState(null);
  const [blToInspect, setBlToInspect] = useState(null);
  const [factureToInspect, setFactureToInspect] = useState(null);
  const [dataClient, setData] = useState([]);
  const [historyDate, setHistoryDate] = useState([
    moment().startOf("year").format("YYYY-MM-DD"),
    moment().format("YYYY-MM-DD")
  ]);

  useEffect(() => {
    setData([]);
    setStep(0);
    if (null != client.id) {
      var l__users = [];

      toGetUsers().then((users) => {
        users.forEach((user) => {
          l__users.push(user.username);
        });
        setUsers(l__users);
      });
      toGetClientHistory(
        client.id,
        moment().startOf("year").format("YYYY-MM-DD"),
        moment().format("YYYY-MM-DD")
      ).then((res) => {
        setData(res);
      });
      getBlsOfClients(client.id).then((res) => {
        setBls(res);
        setBlsAll(res);
      });

      getQuotesOfClients(client.id).then((res) => {
        setQuotes(res);
      });
      getFactureOfClients(client.id).then((res) => {
        setFactures(res);
      });

      getReglementsOfClients(client.id).then((res) => {
        setReglements(res.sort((a, b) => a.createdDate > b.createdDate));
      });
    }
  }, [client, visible]);

  const getReglementType = (reglement) => {
    switch (reglement.type) {
      case 0:
        return "Espèces";
      case 1:
        return "Chèques";

      case 2:
        return "Traites";

      case 3:
        return "Carte bancaire";

      case 4:
        return "Virement bancaire";
      case 5:
        return "Retenu à la source";
      case 5:
        return "Contre remboursement";

      default:
        break;
    }
  };
  const onChange = () => {
    onDeactivatePatient(client.id).then((res) => {
      null != setBLocked && setBLocked(client.id);
    });
  };

  const close = () => {
    setBls([]);
    setQuotes([]);
    setReglements([]);
    setFactures([]);
    dismiss();
  };

  const onChangeCredit = (option) => {
    setSelectedMethode(option);
    toFilterData(
      option,
      selectedDate,
      selectedClient,
      selectedUser,
      selectedDateValue
    );
  };

  const onChangeUsers = (option) => {
    setSelectedUser(option);
    toFilterData(
      selectedMethode,
      selectedDate,
      selectedClient,
      option,
      selectedDateValue
    );
  };

  const onChangeDate = (option) => {
    if (option == "5") {
      setSelectedDate(option);
      setDisplayDate(true);
      setDisplayDateRange(false);
      return;
    }
    if (option == "6") {
      setSelectedDate(option);
      setDisplayDateRange(true);
      setDisplayDate(false);
      return;
    }
    setDisplayDate(false);

    setSelectedDate(option);
    toFilterData(
      selectedMethode,
      option,
      selectedClient,
      selectedUser,
      selectedDateValue
    );
  };

  const toFilterData = (option, date, client, user, dateValue = "") => {
    let bons = [...blsAll];

    if (option != "0") {
      if (option == "1") {
        bons = bons.filter((a) => a.paied == false);
      } else {
        bons = bons.filter((a) => a.paied == true);
      }
    }

    if (date != 0) {
      switch (date) {
        case "1":
          //hier

          bons = bons.filter(
            (a) =>
              moment(a.createdDate).format("DD/MM/YYYY") ==
              moment().subtract(1, "days").format("DD/MM/YYYY")
          );
          break;
        case "2":
          //aujourd'hui
          bons = bons.filter(
            (a) =>
              moment(a.createdDate).format("DD/MM/YYYY") ==
              moment().format("DD/MM/YYYY")
          );
          break;
        case "3":
          //mois

          bons = bons.filter(
            (a) => moment(a.createdDate).month() + 1 == moment().month() + 1
          );

          break;

        case "4":
          //année
          bons = bons.filter(
            (a) => moment(a.createdDate).year() == moment().year()
          );

          break;

        case "5":
          bons = bons.filter(
            (a) =>
              moment(a.createdDate).format("DD/MM/YYYY") ==
              moment(dateValue).format("DD/MM/YYYY")
          );
          break;
        case "6":
          bons = bons.filter(
            (a) =>
              moment(a.createdDate).format("DD/MM/YYYY") >=
                moment(dateValue[0]).format("DD/MM/YYYY") &&
              moment(a.createdDate).format("DD/MM/YYYY") <=
                moment(dateValue[1]).format("DD/MM/YYYY")
          );
          break;

        default:
          break;
      }
    }

    if (user != "0") {
      bons = bons.filter((a) => a.createdBy == user);
    }

    setBls(bons);
  };

  const getHeader = () => {
    return "Historique Client " + client.s__name;
  };

  const data = [
    {
      name: "Nombre de devis non convertis en BL",
      value: quotes.filter((e) => null == e.blRef).length
    },
    {
      name: "Nombre de devis convetis en BL",
      value: quotes.filter((e) => null != e.blRef).length
    }
  ];

  const printHistory = () => {
    toPrintClientHistory(client.id, historyDate[0], historyDate[1]);
  };

  const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042"];

  const RADIAN = Math.PI / 180;
  const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
    index
  }: any) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <text
        x={x}
        y={y}
        fill="white"
        fontWeight="800"
        textAnchor={x > cx ? "start" : "end"}
        dominantBaseline="central"
      >
        {`${(percent * 100).toFixed(0)}%`}
      </text>
    );
  };
  const updateBl = (bl) => {
    const win = window.open(
      "/vente/bl/modifier/" + bl.id,

      "_blank"
    );
    if (win != null) {
      win.focus();
    }
  };

  return (
    <Modal
      className="smtv-modal modal-large"
      visible={visible}
      onCancel={close}
      title={
        <>
          {client.s__name + "  " + client.s__code}
          {cookie.load("role") == "Super Admin" && (
            <Switch
              checkedChildren="Actif"
              unCheckedChildren="Désactivé"
              size={"large"}
              onChange={() => onChange()}
              checked={!client.b__locked}
              style={{ marginLeft: "4vh" }}
            />
          )}
        </>
      }
    >
      <Row gutter={24}>
        <Col span={23}>
          <Row>
            <Col span={3}>
              <div
                className="auto--circle"
                style={{ backgroundColor: "#00aeef" }}
              >
                {client.s__code}
              </div>
              <span
                className="auto--client-name"
                style={{
                  backgroundColor: "#fff"
                }}
              >
                {client.s__name}
              </span>
            </Col>
            <Col span="20">
              <Descriptions bordered size="large">
                <Descriptions.Item
                  label="Chiffre d'affaires HT"
                  className="item-label"
                >
                  <Statistic
                    value={client.ca}
                    precision={2}
                    valueStyle={{ color: "#1b9e4d" }}
                    suffix=" DT"
                  />
                </Descriptions.Item>
                <Descriptions.Item label="Marge" className="item-label">
                  <Statistic
                    value={client.marge}
                    precision={2}
                    valueStyle={{ color: "#1b9e4d" }}
                    suffix=" DT"
                  />
                </Descriptions.Item>
                <Descriptions.Item label="Nombre de bl" className="item-label">
                  <Statistic
                    value={client.nb__bLs}
                    valueStyle={{ color: "darkOrange" }}
                  />
                </Descriptions.Item>
                <Descriptions.Item label="Crédit" className="item-label">
                  <Statistic
                    value={client.unpaied}
                    precision={2}
                    valueStyle={{ color: "#ef5350" }}
                    suffix=" DT"
                  />
                </Descriptions.Item>
                <Descriptions.Item
                  label="Date du 1er bl non payé"
                  className="item-label"
                >
                  <Statistic
                    value={
                      client.first__unpaiedBL
                        ? moment(client.first__unpaiedBL).format("YYYY-MM-DD")
                        : "-"
                    }
                    valueStyle={{ color: "#ef5350" }}
                  />
                </Descriptions.Item>
              </Descriptions>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              {step == 0 && (
                <>
                  <Divider>Infos de base</Divider>

                  <Descriptions bordered size="large">
                    <Descriptions.Item label="Nom" className="item-label">
                      {client.s__name}
                    </Descriptions.Item>
                    <Descriptions.Item label="Téléphone" className="item-label">
                      {client.s__phoneNumber}
                    </Descriptions.Item>
                    <Descriptions.Item label="Email">
                      {client.s__email}
                    </Descriptions.Item>
                    <Descriptions.Item label="Code TVA">
                      {client.s__codeTva}
                    </Descriptions.Item>
                    <Descriptions.Item label="Type">
                      {client.s__type}
                    </Descriptions.Item>

                    <Descriptions.Item label="Source">
                      {client.s__source}
                    </Descriptions.Item>
                  </Descriptions>
                  <Divider>Infos supplémentaires</Divider>

                  <Descriptions bordered size="large">
                    <Descriptions.Item label="Seuil">
                      {client.d__threshold}
                    </Descriptions.Item>
                    <Descriptions.Item label="Délai de paiement">
                      {client.d__paymentDelay}
                    </Descriptions.Item>
                  </Descriptions>

                  <Divider>Adresse</Divider>

                  <Descriptions bordered size="large">
                    <Descriptions.Item label="Région">
                      {client.s__region}
                    </Descriptions.Item>
                    <Descriptions.Item label="Ville">
                      {client.s__city}
                    </Descriptions.Item>
                    <Descriptions.Item label="Adresse">
                      {client.s__address}
                    </Descriptions.Item>
                  </Descriptions>

                  <Divider>Spécifiaction pour le client</Divider>

                  <Descriptions bordered size="large">
                    <Descriptions.Item label="Tarif 2 / Tarif 1">
                      <StateTag state={client.b__price1}> </StateTag>
                    </Descriptions.Item>
                    <Descriptions.Item label="Afficher Référence">
                      <StateTag state={client.b__ref}> </StateTag>
                    </Descriptions.Item>
                    <Descriptions.Item label="Afficher prix">
                      <StateTag state={client.b__displayPrix}> </StateTag>
                    </Descriptions.Item>
                    <Descriptions.Item label="TVA">
                      <StateTag state={client.b__tva}> </StateTag>
                    </Descriptions.Item>
                    <Descriptions.Item label="Timbre">
                      <StateTag state={client.b__stamp}> </StateTag>
                    </Descriptions.Item>
                    <Descriptions.Item label="SMS">
                      <StateTag state={client.b__sms}> </StateTag>
                    </Descriptions.Item>
                    <Descriptions.Item label="Facture/mois">
                      <StateTag state={client.b__billPerMonth}> </StateTag>
                    </Descriptions.Item>
                    <Descriptions.Item label="Facture/semaine">
                      <StateTag state={client.b__billPerWeek}> </StateTag>
                    </Descriptions.Item>
                    <Descriptions.Item label="Inclure dans le bonus">
                      <StateTag state={client.bonus}> </StateTag>
                    </Descriptions.Item>
                  </Descriptions>
                  <Divider>Véhicules</Divider>
                  <table className="ant-table" style={{ width: "100%" }}>
                    <thead className="ant-table-thead">
                      <tr>
                        <th className="ant-table-cell">Modèle</th>
                        <th className="ant-table-cell">Matricule</th>
                        <th className="ant-table-cell">VIN</th>
                        <th className="ant-table-cell"></th>
                      </tr>
                    </thead>
                    <tbody className="ant-table-tbody">
                      {client.vehicules.map((el) => (
                        <tr>
                          <td className="ant-table-cell">{el.s__model}</td>
                          <td className="ant-table-cell">{el.s__matricule}</td>
                          <td className="ant-table-cell">{el.s__vin}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </>
              )}
              {step == 1 && (
                <>
                  <Divider> </Divider>

                  <div className="table-wrapper auto--overflow">
                    <Tabs defaultActiveKey="1" centered>
                      <TabPane tab="Liste de devis" key="1">
                        <div
                          className="table-wrapper "
                          style={{
                            maxHeight: "40vh",
                            overflowY: "auto",
                            scrollbarWidth: "3px"
                          }}
                        >
                          <table
                            className="ant-table"
                            style={{ width: "100%" }}
                          >
                            <thead className="ant-table-thead">
                              <tr>
                                <th className="ant-table-cell">
                                  Date création
                                </th>
                                <th className="ant-table-cell">Référence</th>
                                <th className="ant-table-cell">
                                  Client affiché
                                </th>
                                <th className="ant-table-cell">MF affiché</th>
                                <th className="ant-table-cell">Prix Total</th>
                                <th className="ant-table-cell">BL</th>
                                <th className="ant-table-cell">Note</th>
                              </tr>
                            </thead>
                            <tbody className="ant-table-tbody">
                              {quotes &&
                                quotes.map((quote, i) => (
                                  <tr
                                    key={quote.id}
                                    className={
                                      i % 2 != 0 && "auto--background-grey"
                                    }
                                  >
                                    <td className="ant-table-cell">
                                      {toConvertDateTime(quote.createdDate)}
                                    </td>
                                    <td
                                      className="ant-table-cell"
                                      style={{ cursor: "pointer" }}
                                      onClick={() => setQuoteToInspect(quote)}
                                    >
                                      {quote.ref}
                                    </td>
                                    <td className="ant-table-cell">
                                      <span>{quote.displayableName}</span>
                                    </td>
                                    <td className="ant-table-cell">
                                      <span>{quote.displayableMF}</span>
                                    </td>

                                    <td className="ant-table-cell">
                                      {quote.total.toFixed(3)}
                                    </td>
                                    <td className="ant-table-cell">
                                      <Typography.Link>
                                        {quote.blRef && quote.blRef}
                                      </Typography.Link>
                                    </td>
                                    <td className="ant-table-cell">
                                      {quote.note}
                                    </td>
                                  </tr>
                                ))}
                            </tbody>
                          </table>
                        </div>
                        {quotes.length > 0 && (
                          <Descriptions
                            bordered
                            size="small"
                            style={{
                              backgroundColor: "white",
                              marginTop: "3px"
                            }}
                          >
                            <Descriptions.Item
                              label="Total TTC"
                              className="item-label"
                            >
                              {quotes
                                .reduce((a, b) => a + b.total, 0)
                                .toFixed(3) + " DT"}
                            </Descriptions.Item>
                          </Descriptions>
                        )}
                      </TabPane>
                      <TabPane tab="Liste des BL" key="2">
                        <Form layout="vertical">
                          <Row className="auto--custom-header">
                            <Col span="6">
                              <Form.Item label="Crédit">
                                <Select
                                  onSelect={onChangeCredit}
                                  style={{ width: "80%" }}
                                  defaultValue="0"
                                >
                                  <Option value="0">Tout</Option>
                                  <Option value="1">Non payé</Option>
                                  <Option value="2">Payé</Option>
                                </Select>
                              </Form.Item>
                            </Col>
                            <Col span="6">
                              <Form.Item label="Date">
                                <Select
                                  showSearch
                                  onSelect={onChangeDate}
                                  style={{ width: "100%" }}
                                  defaultValue="0"
                                >
                                  <Option value="0">Tout</Option>

                                  <Option value="1">Hier</Option>
                                  <Option value="2">Aujourd'hui</Option>
                                  <Option value="3">Ce mois</Option>
                                  <Option value="4">Cette année</Option>
                                  <Option value="5">Date Spécifique</Option>
                                  <Option value="6">Période Spécifique</Option>
                                </Select>
                              </Form.Item>
                              {displayDate && (
                                <DatePicker
                                  style={{ width: "100%" }}
                                  onChange={(e, date) => {
                                    setSelectedDateValue(date);
                                    toFilterData(
                                      selectedMethode,
                                      selectedDate,
                                      selectedClient,
                                      selectedUser,
                                      date
                                    );
                                  }}
                                />
                              )}
                              {displayDateRange && (
                                <DatePicker.RangePicker
                                  onChange={(e, date) => {
                                    setSelectedDateValue(date);
                                    toFilterData(
                                      selectedMethode,
                                      selectedDate,
                                      selectedClient,
                                      selectedUser,
                                      date
                                    );
                                  }}
                                />
                              )}
                            </Col>

                            <Col span="6">
                              <Form.Item label="Utilisateurs">
                                <Select
                                  showSearch
                                  onSelect={onChangeUsers}
                                  style={{ width: "80%" }}
                                  defaultValue="0"
                                >
                                  <Option value="0">Tout</Option>

                                  {users.map((u) => (
                                    <Option value={u}>{u}</Option>
                                  ))}
                                </Select>
                              </Form.Item>
                            </Col>
                            <Col span="4" style={{ float: "right" }}>
                              <Form.Item label="">
                                <Button
                                  type="primary"
                                  onClick={() =>
                                    printJS({
                                      printable: "blids",
                                      type: "html",
                                      css: "test.css",
                                      style: "background-color: red;",
                                      header: getHeader()
                                    })
                                  }
                                >
                                  Imprimer
                                </Button>
                              </Form.Item>
                            </Col>
                          </Row>
                        </Form>{" "}
                        <div
                          id="blids"
                          className="table-wrapper "
                          style={{
                            maxHeight: "40vh",
                            overflowY: "auto",
                            scrollbarWidth: "3px"
                          }}
                        >
                          <table
                            className="ant-table"
                            style={{ width: "100%" }}
                          >
                            <thead className="ant-table-thead">
                              <tr>
                                <th className="ant-table-cell">Date </th>
                                <th className="ant-table-cell">Crée par </th>
                                <th className="ant-table-cell">Référence</th>
                                <th className="ant-table-cell">
                                  Client affiché
                                </th>
                                <th className="ant-table-cell">MF affiché</th>
                                <th className="ant-table-cell">Total</th>
                                <th className="ant-table-cell">Note</th>
                                <th className="ant-table-cell">Reste</th>
                                <th className="ant-table-cell">Status</th>
                                <th className="ant-table-cell">Facture</th>
                                <th className="ant-table-cell"></th>
                              </tr>
                            </thead>
                            <tbody className="ant-table-tbody">
                              {null != bls &&
                                bls.map((bl, i) => (
                                  <tr
                                    key={bl.id}
                                    className={
                                      i % 2 != 0 && "auto--background-grey"
                                    }
                                  >
                                    <td className="ant-table-cell">
                                      {toConvertDate(bl.createdDate)}
                                    </td>
                                    <td className="ant-table-cell">
                                      {bl.createdBy}
                                    </td>
                                    <td
                                      className="ant-table-cell"
                                      style={{ cursor: "pointer" }}
                                      onClick={() => setBlToInspect(bl.id)}
                                    >
                                      {bl.ref}
                                    </td>
                                    <td className="ant-table-cell">
                                      <span>{bl.displayableName}</span>
                                    </td>
                                    <td className="ant-table-cell">
                                      <span>{bl.displayableMF}</span>
                                    </td>

                                    <td className="ant-table-cell">
                                      {bl.total.toFixed(3)} DT
                                    </td>
                                    <td className="ant-table-cell">
                                      {bl.note}
                                    </td>
                                    <td className="ant-table-cell">
                                      {bl.reste.toFixed(3)} DT
                                    </td>
                                    <td className="ant-table-cell">
                                      {bl.paied ? (
                                        <span className={"auto--color-success"}>
                                          Payé
                                        </span>
                                      ) : (
                                        <span className={"auto--color-danger"}>
                                          Non payé
                                        </span>
                                      )}
                                    </td>
                                    <td className="ant-table-cell">
                                      {bl.factureId && (
                                        <Typography.Link
                                        //onClick={() => inspectFacture(bl.factureId)}
                                        >
                                          {bl.factureRef}
                                        </Typography.Link>
                                      )}
                                    </td>
                                    <td className="ant-table-cell">
                                      {!bl.paied && bl.factureId == null && (
                                        <Tooltip
                                          title="Modifier le bon de livraison"
                                          color="#58c5cc"
                                          placement="leftTop"
                                        >
                                          <Button
                                            type="link"
                                            style={{ width: "2vh" }}
                                            onClick={() => updateBl(bl)}
                                            icon={
                                              <img
                                                src="/images/edit.svg"
                                                className="auto--icon-list"
                                              ></img>
                                            }
                                          ></Button>
                                        </Tooltip>
                                      )}
                                    </td>
                                  </tr>
                                ))}
                            </tbody>
                          </table>
                          <div style={{ border: "1px black solid" }}>
                            <span style={{ float: "right" }}>
                              <h2>
                                Total:{" "}
                                {bls
                                  .reduce((a, b) => b.total + a, 0)
                                  .toFixed(3) + " DT"}
                                <br />
                                <br />
                                Reste:{" "}
                                {bls
                                  .reduce((a, b) => b.reste + a, 0)
                                  .toFixed(3) + " DT"}
                              </h2>
                            </span>
                          </div>
                        </div>
                        {bls.length > 0 && (
                          <Descriptions
                            bordered
                            size="small"
                            style={{
                              backgroundColor: "white",
                              marginTop: "3px"
                            }}
                          >
                            <Descriptions.Item
                              label="Total TTC"
                              className="item-label"
                            >
                              {bls.reduce((a, b) => a + b.total, 0).toFixed(3) +
                                " DT"}
                            </Descriptions.Item>
                            <Descriptions.Item
                              label="Total payé"
                              className="item-label"
                            >
                              {(
                                bls.reduce((a, b) => a + b.total, 0) -
                                bls.reduce((a, b) => a + b.reste, 0)
                              ).toFixed(3) + " DT"}
                            </Descriptions.Item>
                            <Descriptions.Item
                              label="Total Reste"
                              className="item-label"
                            >
                              {(
                                bls.reduce((a, b) => a + b.total, 0) -
                                (bls.reduce((a, b) => a + b.total, 0) -
                                  bls.reduce((a, b) => a + b.reste, 0))
                              ).toFixed(3) + " DT"}
                            </Descriptions.Item>
                          </Descriptions>
                        )}
                      </TabPane>
                      <TabPane tab="Liste des factures" key="3">
                        <div
                          className="table-wrapper"
                          style={{
                            maxHeight: "40vh",
                            overflowY: "auto",
                            scrollbarWidth: "3px"
                          }}
                        >
                          <table
                            className="ant-table"
                            style={{ width: "100%" }}
                          >
                            <thead className="ant-table-thead">
                              <tr>
                                <th className="ant-table-cell">Date </th>
                                <th className="ant-table-cell">Référence</th>
                                <th className="ant-table-cell">Client</th>

                                <th className="ant-table-cell">Prix Total</th>
                                <th className="ant-table-cell">Reste</th>
                                <th className="ant-table-cell">Payé</th>

                                <th className="ant-table-cell">Crée par</th>
                              </tr>
                            </thead>
                            <tbody className="ant-table-tbody">
                              {factures &&
                                factures.map((facture, i) => (
                                  <tr
                                    key={facture.id}
                                    className={
                                      i % 2 != 0 && "auto--background-grey"
                                    }
                                  >
                                    <td className="ant-table-cell">
                                      {toConvertDate(facture.dt__createdDate)}
                                    </td>
                                    <td
                                      className="ant-table-cell"
                                      style={{ cursor: "pointer" }}
                                      onClick={() =>
                                        setFactureToInspect(facture)
                                      }
                                    >
                                      {facture.s__ref}
                                    </td>
                                    <td className="ant-table-cell">
                                      {facture.client.s__name}
                                    </td>

                                    <td className="ant-table-cell">
                                      {parseFloat(
                                        facture.d__totalFacture
                                      ).toFixed(3)}
                                    </td>
                                    <td className="ant-table-cell">
                                      {parseFloat(facture.d__reste).toFixed(3)}
                                    </td>
                                    <td className="ant-table-cell">
                                      {facture.b__paied ? (
                                        <span className={"auto--color-success"}>
                                          Payé
                                        </span>
                                      ) : (
                                        <span className={"auto--color-danger"}>
                                          Non payé
                                        </span>
                                      )}
                                    </td>
                                    <td className="ant-table-cell">
                                      {facture.l__createdBy.nom}
                                    </td>
                                  </tr>
                                ))}
                            </tbody>
                          </table>
                        </div>
                        {factures.length > 0 && (
                          <Descriptions
                            bordered
                            size="small"
                            style={{
                              backgroundColor: "white",
                              marginTop: "3px"
                            }}
                          >
                            <Descriptions.Item
                              label="Total TTC"
                              className="item-label"
                            >
                              {factures
                                .reduce((a, b) => a + b.d__totalFacture, 0)
                                .toFixed(3) + " DT"}
                            </Descriptions.Item>
                            <Descriptions.Item
                              label="Total payé"
                              className="item-label"
                            >
                              {(
                                factures.reduce(
                                  (a, b) => a + b.d__totalFacture,
                                  0
                                ) - factures.reduce((a, b) => a + b.d__reste, 0)
                              ).toFixed(3) + " DT"}
                            </Descriptions.Item>
                            <Descriptions.Item
                              label="Total Reste"
                              className="item-label"
                            >
                              {(
                                factures.reduce(
                                  (a, b) => a + b.d__totalFacture,
                                  0
                                ) -
                                (factures.reduce(
                                  (a, b) => a + b.d__totalFacture,
                                  0
                                ) -
                                  factures.reduce((a, b) => a + b.d__reste, 0))
                              ).toFixed(3) + " DT"}
                            </Descriptions.Item>
                          </Descriptions>
                        )}
                      </TabPane>
                      <TabPane tab="Liste des réglements" key="4">
                        <Row>
                          <Col span="24">
                            <div
                              className="table-wrapper"
                              style={{
                                maxHeight: "40vh",
                                overflowY: "auto",
                                scrollbarWidth: "3px"
                              }}
                            >
                              <table
                                className="ant-table"
                                style={{ width: "100%" }}
                              >
                                <thead className="ant-table-thead">
                                  <tr>
                                    <th className="ant-table-cell">
                                      Référence
                                    </th>
                                    <th className="ant-table-cell">
                                      Date de création
                                    </th>
                                    <th className="ant-table-cell">
                                      Facture/BL
                                    </th>
                                    <th className="ant-table-cell"></th>
                                    <th className="ant-table-cell"></th>
                                  </tr>
                                </thead>

                                <tbody className="ant-table-tbody">
                                  {reglements &&
                                    reglements.map((reglement, i) => (
                                      <tr key={i}>
                                        <td className="ant-table-cell">
                                          {reglement.s__ref}
                                        </td>
                                        <td className="ant-table-cell">
                                          {reglement.createdDate}
                                        </td>

                                        <td className="ant-table-cell">
                                          <b>{reglement.ref}</b>
                                        </td>
                                        <td className="ant-table-cell">
                                          <Descriptions
                                            column={6}
                                            bordered
                                            layout="vertical"
                                            size="small"
                                          >
                                            {reglement.type == 1 ||
                                            reglement.type == 2 ? (
                                              <>
                                                <Descriptions.Item className="item-label">
                                                  <b>
                                                    {getReglementType(
                                                      reglement
                                                    )}
                                                  </b>
                                                </Descriptions.Item>
                                                <Descriptions.Item
                                                  label="Nom"
                                                  className="item-label"
                                                >
                                                  {reglement.data.nom}
                                                </Descriptions.Item>
                                                <Descriptions.Item
                                                  label="Date"
                                                  className="item-label"
                                                >
                                                  {toConvertDate(
                                                    reglement.data.date
                                                  )}
                                                </Descriptions.Item>
                                                <Descriptions.Item
                                                  label="Banque"
                                                  className="item-label"
                                                >
                                                  {reglement.data.banque}
                                                </Descriptions.Item>
                                                <Descriptions.Item
                                                  label="Num"
                                                  className="item-label"
                                                >
                                                  {reglement.data.num}
                                                </Descriptions.Item>
                                                <Descriptions.Item
                                                  label="Montant"
                                                  className="item-label"
                                                >
                                                  {reglement.data.montant}
                                                </Descriptions.Item>
                                              </>
                                            ) : (
                                              <Descriptions.Item
                                                label="Montant"
                                                className="item-label"
                                              >
                                                {reglement.data.montant}
                                              </Descriptions.Item>
                                            )}
                                          </Descriptions>
                                        </td>
                                      </tr>
                                    ))}
                                </tbody>
                              </table>
                              {reglements.length < 1 && (
                                <Empty
                                  style={{ textAlign: "center" }}
                                  description="Aucun réglements disponible"
                                ></Empty>
                              )}
                            </div>
                          </Col>
                        </Row>
                      </TabPane>
                    </Tabs>
                  </div>
                </>
              )}
              {step == 2 && (
                <div className="table-wrapper auto--overflow">
                  <span style={{ color: "#00C49F" }}>
                    Liste de devis convertis en BL
                  </span>
                  <br />
                  <span style={{ color: "#0088FE" }}>
                    Liste de devis non convertis en BL
                  </span>
                  <PieChart width={400} height={400}>
                    <Pie
                      data={data}
                      cx={200}
                      cy={200}
                      labelLine={false}
                      label={renderCustomizedLabel}
                      outerRadius={80}
                      fill="#8884d8"
                      dataKey="value"
                    >
                      {data.map((entry, index) => (
                        <Cell
                          key={`cell-${index}`}
                          fill={COLORS[index % COLORS.length]}
                        />
                      ))}
                    </Pie>
                  </PieChart>
                </div>
              )}
              {step == 3 && (
                <div style={{ marginTop: "2vh" }}>
                  <DatePicker.RangePicker
                    onChange={(e, date) => {
                      setHistoryDate(date);
                      toGetClientHistory(client.id, date[0], date[1]).then(
                        (res) => {
                          setData(res);
                        }
                      );
                    }}
                  />
                  <Button
                    onClick={() => printHistory()}
                    type="primary"
                    style={{ float: "right" }}
                  >
                    Imprimer
                  </Button>
                  <Row style={{ marginTop: "2vh" }}>
                    <Col span="24">
                      <div className="table-wrapper auto--overflow">
                        <div
                          className="table-wrapper "
                          style={{
                            maxHeight: "40vh",
                            overflowY: "auto",
                            scrollbarWidth: "3px"
                          }}
                        >
                          <table
                            className="ant-table"
                            style={{ width: "100%" }}
                          >
                            <thead className="ant-table-thead">
                              <tr>
                                <th className="ant-table-cell">Référence</th>
                                <th className="ant-table-cell">
                                  Date de création
                                </th>
                                <th className="ant-table-cell">Total</th>
                                <th className="ant-table-cell">Crédit</th>
                                <th className="ant-table-cell">Facture</th>
                                <th className="ant-table-cell"></th>
                              </tr>
                            </thead>
                            <tbody className="ant-table-tbody">
                              {dataClient.map((el, x) => (
                                <>
                                  {el.facture ? (
                                    el.items.map((item, i) => (
                                      <>
                                        <tr key={x + i}>
                                          <td className="ant-table-cell">
                                            <b>{"BL:" + " " + item.ref}</b>
                                          </td>
                                          <td className="ant-table-cell">
                                            {toConvertDate(item.createdDate)}
                                          </td>
                                          <td className="ant-table-cell">
                                            {parseFloat(item.total).toFixed(3)}
                                          </td>
                                          <td className="ant-table-cell">
                                            {item.paied ? (
                                              <span
                                                className={
                                                  "auto--color-success"
                                                }
                                              >
                                                Payé
                                              </span>
                                            ) : (
                                              <span
                                                className={"auto--color-danger"}
                                              >
                                                Non payé
                                              </span>
                                            )}
                                          </td>
                                          <td className="ant-table-cell">
                                            <b>{el.s__ref}</b>
                                          </td>
                                        </tr>
                                      </>
                                    ))
                                  ) : (
                                    <>
                                      <tr key={x}>
                                        <td className="ant-table-cell">
                                          <b>{"BL:" + " " + el.ref}</b>
                                        </td>
                                        <td className="ant-table-cell">
                                          {toConvertDate(el.createdDate)}
                                        </td>
                                        <td className="ant-table-cell">
                                          {parseFloat(el.total).toFixed(3)}
                                        </td>
                                        <td className="ant-table-cell">
                                          {el.paied ? (
                                            <span
                                              className={"auto--color-success"}
                                            >
                                              Payé
                                            </span>
                                          ) : (
                                            <span
                                              className={"auto--color-danger"}
                                            >
                                              Non payé
                                            </span>
                                          )}
                                        </td>
                                      </tr>
                                      {el.reglements &&
                                        el.reglements.map((reglement, i) => (
                                          <tr key={i}>
                                            <td className="ant-table-cell">
                                              {reglement.s__ref}
                                            </td>
                                            <td className="ant-table-cell">
                                              {reglement.createdDate}
                                            </td>

                                            <td className="ant-table-cell">
                                              <b>{reglement.ref}</b>
                                            </td>
                                            <td className="ant-table-cell">
                                              <Descriptions
                                                column={6}
                                                bordered
                                                layout="vertical"
                                                size="small"
                                              >
                                                {reglement.type == 1 ||
                                                reglement.type == 2 ? (
                                                  <>
                                                    <Descriptions.Item className="item-label">
                                                      <b>
                                                        {getReglementType(
                                                          reglement
                                                        )}
                                                      </b>
                                                    </Descriptions.Item>
                                                    <Descriptions.Item
                                                      label="Nom"
                                                      className="item-label"
                                                    >
                                                      {reglement.data.nom}
                                                    </Descriptions.Item>
                                                    <Descriptions.Item
                                                      label="Date"
                                                      className="item-label"
                                                    >
                                                      {toConvertDate(
                                                        reglement.data.date
                                                      )}
                                                    </Descriptions.Item>
                                                    <Descriptions.Item
                                                      label="Banque"
                                                      className="item-label"
                                                    >
                                                      {reglement.data.banque}
                                                    </Descriptions.Item>
                                                    <Descriptions.Item
                                                      label="Num"
                                                      className="item-label"
                                                    >
                                                      {reglement.data.num}
                                                    </Descriptions.Item>
                                                    <Descriptions.Item
                                                      label="Montant"
                                                      className="item-label"
                                                    >
                                                      {reglement.data.montant}
                                                    </Descriptions.Item>
                                                  </>
                                                ) : (
                                                  <Descriptions.Item
                                                    label="Montant"
                                                    className="item-label"
                                                  >
                                                    {reglement.data.montant}
                                                  </Descriptions.Item>
                                                )}
                                              </Descriptions>
                                            </td>
                                          </tr>
                                        ))}
                                    </>
                                  )}
                                  {el.reglements &&
                                    el.reglements.map((reglement, i) => (
                                      <tr key={i}>
                                        <td className="ant-table-cell">
                                          {reglement.s__ref}
                                        </td>
                                        <td className="ant-table-cell">
                                          {reglement.createdDate}
                                        </td>

                                        <td className="ant-table-cell">
                                          <b>{reglement.ref}</b>
                                        </td>
                                        <td className="ant-table-cell">
                                          <Descriptions
                                            column={6}
                                            bordered
                                            layout="vertical"
                                            size="small"
                                          >
                                            {reglement.type == 1 ||
                                            reglement.type == 2 ? (
                                              <>
                                                <Descriptions.Item className="item-label">
                                                  <b>
                                                    {getReglementType(
                                                      reglement
                                                    )}
                                                  </b>
                                                </Descriptions.Item>
                                                <Descriptions.Item
                                                  label="Nom"
                                                  className="item-label"
                                                >
                                                  {reglement.data.nom}
                                                </Descriptions.Item>
                                                <Descriptions.Item
                                                  label="Date"
                                                  className="item-label"
                                                >
                                                  {toConvertDate(
                                                    reglement.data.date
                                                  )}
                                                </Descriptions.Item>
                                                <Descriptions.Item
                                                  label="Banque"
                                                  className="item-label"
                                                >
                                                  {reglement.data.banque}
                                                </Descriptions.Item>
                                                <Descriptions.Item
                                                  label="Num"
                                                  className="item-label"
                                                >
                                                  {reglement.data.num}
                                                </Descriptions.Item>
                                                <Descriptions.Item
                                                  label="Montant"
                                                  className="item-label"
                                                >
                                                  {reglement.data.montant}
                                                </Descriptions.Item>
                                              </>
                                            ) : (
                                              <Descriptions.Item
                                                label="Montant"
                                                className="item-label"
                                              >
                                                {reglement.data.montant}
                                              </Descriptions.Item>
                                            )}
                                          </Descriptions>
                                        </td>
                                      </tr>
                                    ))}
                                </>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              )}
            </Col>
          </Row>
        </Col>

        <Col span={1} className="auto--background-grey">
          <Tooltip title="Infos " color="#58c5cc" placement="leftTop">
            <Button
              type="link"
              style={{ marginTop: "2vh" }}
              onClick={() => setStep(0)}
              icon={
                <img src="/images/info.svg" className="auto--icon-list"></img>
              }
            ></Button>
          </Tooltip>
          <Tooltip title="Mouvements" color="#58c5cc" placement="leftTop">
            <Button
              type="link"
              style={{ marginTop: "2vh" }}
              onClick={() => setStep(1)}
              icon={
                <RiArrowLeftRightFill
                  size="1.5em"
                  className="auto--icon-list"
                />
              }
            ></Button>
          </Tooltip>

          <Tooltip title="Stats" color="#58c5cc" placement="leftTop">
            <Button
              type="link"
              style={{ marginTop: "2vh" }}
              onClick={() => setStep(2)}
              icon={<FcStatistics size="2em" className="auto--icon-list" />}
            ></Button>
          </Tooltip>
          <Tooltip
            title="Historique client"
            color="#58c5cc"
            placement="leftTop"
          >
            <Button
              type="link"
              style={{ marginTop: "2vh" }}
              onClick={() => setStep(3)}
              icon={
                <RiFileHistoryFill size="2em" className="auto--icon-list" />
              }
            ></Button>
          </Tooltip>
        </Col>
      </Row>

      <QuoteModal
        quote={quoteToInspect}
        entete={false}
        onClose={() => setQuoteToInspect(null)}
      />
      <BonLivraisonModal
        entete={false}
        id={blToInspect}
        onClose={() => setBlToInspect(null)}
      />
      <FactureModal
        id={null != factureToInspect && factureToInspect.id}
        onClose={() => setFactureToInspect(null)}
      />
    </Modal>
  );
};
export default ClientDetail;
