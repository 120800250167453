import { CheckOutlined, CloseOutlined } from "@ant-design/icons";

const StateTag = ({ state }) => {
  return state ? (
    <div style={{ ...wrapper, border: "1px solid #52c41a" }}>
      <CheckOutlined style={{ color: "#52c41a" }} />
    </div>
  ) : (
    <div style={{ ...wrapper, border: "1px solid #f5222d" }}>
      <CloseOutlined style={{ color: "#f5222d" }} />
    </div>
  );
};

export default StateTag;

const wrapper = {
  borderRadius: "100%",
  padding: "5px",
  width: "30px",
  height: "30px",
  color: "white",
  textAlign: "center",
  fontSize: "10pt",
};
