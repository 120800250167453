import { Form, Input, Modal, Button } from "antd";
import { successMsg } from "../../Utils/Utils";
import {
  sendEmailBL,
  sendEmailQuote,
  sendEmailFacture,
  sendEmailPo
} from "./APIs";

const Email = ({ id, type, visible, setEmailModal }) => {
  const [form] = Form.useForm();

  const envoyerEmail = () => {
    form.validateFields().then((values) => {
      if (type == "bl") {
        sendEmailBL(
          id,
          type,
          form.getFieldValue("to"),
          form.getFieldValue("msg")
        );
      } else if (type == "quote") {
        sendEmailQuote(
          id,
          type,
          form.getFieldValue("to"),
          form.getFieldValue("msg")
        );
      } else if (type == "facture") {
        sendEmailFacture(
          id,
          type,
          form.getFieldValue("to"),
          form.getFieldValue("msg")
        );
      } else if (type == "po") {
        sendEmailPo(
          id,
          type,
          form.getFieldValue("to"),
          form.getFieldValue("msg")
        );
      }

      successMsg("Email envoyé avec succé!!");
      setEmailModal(false);
    });
  };
  return (
    <Modal
      visible={visible}
      title="Email"
      onCancel={() => setEmailModal(false)}
      footer={[
        <Button type="primary" onClick={() => envoyerEmail()}>
          Envoyer
        </Button>
      ]}
    >
      <Form form={form}>
        <Form.Item
          rules={[{ required: true, message: "Champ obligatoire" }]}
          label="A"
          name="to"
        >
          <Input></Input>
        </Form.Item>
        <Form.Item
          rules={[{ required: true, message: "Champ obligatoire" }]}
          label="Message"
          name="msg"
        >
          <Input.TextArea />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default Email;
