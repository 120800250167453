import { connect } from "react-redux";
import { Redirect, Route } from "react-router-dom";

function PrivateRoute({ children, user, layout, ...rest }) {
  return (
    <Route
      {...rest}
      render={({ location }) => {
        return (user ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: location },
            }}
          />
        ))

      }
      }
    />
  );
}

const mapStateToProps = ({ authState, uiState }) => {
  return {
    user: authState.user,
    loaded: authState.loaded,
    layout: uiState.layout,
  };
};

export default connect(mapStateToProps)(PrivateRoute);
