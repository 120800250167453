import { Layout } from "antd";
import React, { useEffect } from "react";
import CustomMenu from "../Components/CustomMenu";
import Navigation from "../Components/Navigation";
import Ticker from "react-ticker";
import StockTicker from "./StockTicker";

const { Header, Content } = Layout;

const TopNavLayout = ({ children, AppStore, props }) => {
  const data = ["text 1", "text 2", "farouk", "last"];
  useEffect(() => {}, []);

  return (
    <Layout style={{ height: "100vh" }}>
      <Header style={{ padding: 0 }}>
        <Navigation AppStore={AppStore} mode="horizontal" />
      </Header>
      <Layout style={{ flex: 1 }}>
        <CustomMenu AppStore={AppStore} />
        <Content
          style={{
            padding: 24,
            overflowY: "auto",
          }}
        >
          {children}
        </Content>
      </Layout>
    </Layout>
  );
};

export default TopNavLayout;
