import { UI_ACTIONS } from './Actions'

const setLayout = (layout) => {
    return {
        type : UI_ACTIONS.SET_LAYOUT,
        data : {layout}
    }
}

const loadUIConfig = () => {
    const config = localStorage.getItem('ui_config');
    if(config) {
        return {
            type : UI_ACTIONS.SET_CONFIG,
            data : { config : JSON.parse(config) }
        }
    } else {
        return { type: UI_ACTIONS.LOAD_DEFAULT }
    }
}


export {
    setLayout,
    loadUIConfig
}