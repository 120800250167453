import history from "../Utils/History";
import { AUTH_ACTIONS } from "./Actions";
import cookie from "react-cookies";

const login = (user) => {
  localStorage.setItem("user", JSON.stringify(user));
  return {
    type: AUTH_ACTIONS.LOGIN,
    data: { user },
  };
};

const logout = () => {
  return (dispatch, getState) => {
    const ls = require("localstorage-ttl");

    const { authState } = getState();
    if (authState.user) {
      history.push("/login");
      localStorage.removeItem("user");
      localStorage.removeItem("index");
      localStorage.clear();
      cookie.remove("mode", { path: "/" });
      cookie.remove("token", { path: "/" });
      cookie.remove("selectedMenu", { path: "/" });

      dispatch(removeUser());
    }
  };
};

const removeUser = () => {
  return {
    type: AUTH_ACTIONS.LOGOUT,
  };
};

const loadUser = () => {
  const user = localStorage.getItem("user");
  return {
    type: AUTH_ACTIONS.LOAD_USER,
    data: user ? { user: JSON.parse(user) } : {},
  };
};

export { login, logout, loadUser };
