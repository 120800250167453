export default class Client {
  id;
  s__code = "";
  s__type = "PARTICULIER";
  s__source = "PASSAGER";
  s__name = "";
  s__phoneNumber = "";
  s__email = "";
  s__register;
  s__region = "";
  s__city = "";
  s__address = "";
  s__respName = "";
  s__respPhone = "";
  d__threshold = 0;
  d__paymentDelay = 0;
  d__discount = 0;
  d__rest = 0;
  b__tva = true;
  b__stamp = true;
  b__cri = false;
  b__sms = false;
  b__wholesaler = false;
  b__billPerMonth = false;
  b__billPerWeek = false;
  b__title1 = true;
  b__price1 = true;
  b__ref = true;
  bonus = true;
  b__displayPrix = true;
  l__created_by;
  dt__createdDate;
  dt__updatedDate;
  vehicules = [];
  notes = [];
}
