import { useEffect, useState } from "react";
import { toGetAudit } from "../Utils/Api";
import { getText } from "../Utils/Utils";
import { Collapse } from "antd";

const { Panel } = Collapse;

const Audit = ({ data }) => {
  const [result, setResult] = useState([]);
  useEffect(() => {
    toGetDatas(data);
  }, [data]);
  const toGetDatas = (data) => {
    toGetAudit(data).then((res) => {
      setResult(res);
    });
  };

  return (
    <>
      {result.map((element, i) => (
        <Collapse accordion>
          <Panel header={getText(element)} key={i}>
            {element.changedValues.map(
              (changedValue) =>
                changedValue.col != "l__createdBy" && (
                  <>
                    <tr>
                      <td>{changedValue.old}</td>
                      <td>{changedValue.new}</td>
                    </tr>
                  </>
                )
            )}
          </Panel>
        </Collapse>
      ))}
    </>
  );
};
export default Audit;
