import Modal from "antd/lib/modal/Modal";
import { Button, Row, Col } from "antd";
import {
  toGetinventory,
  toGetBonsReception,
  toGetInventory,
  toPrintBR,
} from "../APIs";
import { PrinterOutlined } from "@ant-design/icons";
import { toConvertDate } from "../../../Utils/Utils";
import { useState, useEffect } from "react";

const InventoryModal = ({ inventory, onClose }) => {
  const [bon, setBon] = useState(null);

  useEffect(() => {
    if (null != inventory) {
      toGetInventory(inventory.id).then((res) => {
        setBon(res);
      });
    }
  }, [inventory]);

  return (
    <Modal
      className="smtv-modal modal-large"
      visible={inventory != null}
      onCancel={onClose}
      title="Details de l'inventaire"
      footer={[
        <Button key="close" onClick={onClose}>
          Fermer
        </Button>,
      ]}
    >
      {inventory && (
        <>
          <Row>
            <Col className="info-box" md={24}>
              <p>
                <b>Ref</b> : {inventory.ref}
              </p>
              <p>
                <b>Date</b> : {toConvertDate(inventory.date)}
              </p>
              <p>
                <b>Emplacement</b> : {inventory.emplacement}
              </p>
              <p>
                <b>Chargé client</b> :{" "}
                {inventory.username && inventory.username}
              </p>
            </Col>
          </Row>
          <h1 style={{ textAlign: "center", marginTop: "20px" }}>Articles</h1>
          <table className="ant-table" style={{ width: "100%" }}>
            <thead className="ant-table-thead">
              <tr>
                <th className="ant-table-cell" style={{ width: "8%" }}>
                  Ref
                </th>
                <th className="ant-table-cell">Désignation</th>

                <th className="ant-table-cell" style={{ width: "8%" }}>
                  Ancienne Quantité
                </th>
                <th className="ant-table-cell" style={{ width: "8%" }}>
                  Nouvelle Quantité
                </th>
                <th className="ant-table-cell" style={{ width: "8%" }}>
                  Details
                </th>
              </tr>
            </thead>
            <tbody className="ant-table-tbody">
              {null != bon &&
                bon.items &&
                bon.items.map((item) => (
                  <tr>
                    <td className="ant-table-cell" style={{ width: "5%" }}>
                      {item.ref}
                    </td>

                    <td className="ant-table-cell" style={{ width: "8%" }}>
                      {item.article}
                    </td>
                    <td className="ant-table-cell">{item.oldQuantity}</td>
                    <td className="ant-table-cell" style={{ width: "12%" }}>
                      {item.newQuantity}
                    </td>
                    <td className="ant-table-cell" style={{ width: "12%" }}>
                      <span
                        className={
                          item.newQuantity < item.oldQuantity
                            ? "auto--color-danger"
                            : item.newQuantity == item.oldQuantity
                            ? "auto--color-warning"
                            : "auto--color-success"
                        }
                      >
                        {item.newQuantity - item.oldQuantity}
                      </span>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </>
      )}
    </Modal>
  );
};

export default InventoryModal;
