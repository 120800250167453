import { Button, Row, Col, Skeleton } from "antd";
import Modal from "antd/lib/modal/Modal";
import { toGetQuote, toPrintQuote } from "../APIs";
import { PrinterOutlined } from "@ant-design/icons";
import { toConvertDate } from "../../../Utils/Utils";
import { useEffect, useState } from "react";
import GaugeChart from "react-gauge-chart";

const QuoteModal = ({ quote, onClose, entete }) => {
  const [quoteItems, setQuoteItems] = useState(null);
  const [loading, setLoading] = useState(false);
    const [marge, setMarge] = useState(-1);

  useEffect(() => {
    if (null != quote) {
      setLoading(true);
      toGetQuote(quote.id).then((res) => {
        
        setQuoteItems(res.quote__items);
        
        const items = res.quote__items;
        var prix_achat = items.reduce(
          (a, b) => a + b.article.l__prixAchatHT * b.d__quantity,
          0
        );
        var margeTotal = 0;
        if (items.length > 0) {
          margeTotal = Math.round(
            ((quote.total - quote.tva - prix_achat) / prix_achat) * 100
          );
          if (margeTotal > 0) {
            setMarge(parseInt(margeTotal));
          } else {
            setMarge(0);
          }
        } else {
          setMarge(-1);
        }
        setLoading(false);
      });
    }
  }, [quote]);
  const printQuote = (e) => {
    e.preventDefault();
    var titre1 = true;
    if (e.type == "contextmenu") {
      titre1 = false;
    }

    toPrintQuote(quote.id, titre1, entete);
  };

  return (
    <Modal
      className="smtv-modal modal-large"
      visible={quote != null}
      onCancel={onClose}
      title="Details devis"
      footer={[
        <Button key="close" onClick={onClose}>
          Fermer
        </Button>,
        <Button
          key="submit"
          type="primary"
          icon={<PrinterOutlined />}
          onClick={printQuote}
          onContextMenu={(e) => printQuote(e)}
        >
          Imprimer
        </Button>,
      ]}
    >
      {quote && (
        <>
          <h1 style={{ textAlign: "center" }}>Devis</h1>
          <Row>
            <Col className="info-box" md={11}>
              <p>
                <b>Ref</b> : {quote.ref}
              </p>
              <p>
                <b>Date</b> : {toConvertDate(quote.createdDate)}
              </p>
              <p>
                <b>Chargé client</b> : {quote.createdBy && quote.createdBy}
              </p>
            </Col>
            <Col className="info-box" md={11}>
              <h1>Client</h1>
              <p>
                <b>Nom</b> :{" "}
                {quote.displayableName
                  ? quote.displayableName
                  : quote.clientName && quote.clientName}
              </p>
              <p></p>
            </Col>
          </Row>
                      <Row
              justify="center"
              //style={{ width: "200px", textAlign: "center" }}
            >
              <Col span="8">
                {marge != -1 && (
                  <>
                    <span style={{ marginLeft: "10%" }}>{marge + " %"}</span>
                    <br />
                    <GaugeChart
                      id="gauge-chart5"
                      nrOfLevels={100}
                      arcsLength={[0.1, 0.25, 0.65]}
                      colors={["#EA4228", "#F5CD19", "#5BE12C"]}
                      percent={marge / 100}
                      textColor={"transparent"}
                      arcPadding={0.01}
                      style={{ height: "50%", width: "50%" }}
                    ></GaugeChart>
                  </>
                )}
              </Col>
            </Row>

          <h2>Articles</h2>

          <table className="ant-table" style={{ width: "100%" }}>
            <thead className="ant-table-thead">
              <tr>
                <th className="ant-table-cell" style={{ width: "8%" }}>
                  Ref
                </th>
                <th className="ant-table-cell">Désignation</th>
                <th className="ant-table-cell" style={{ width: "8%" }}>
                  Prix Unitaire HT
                </th>
                <th className="ant-table-cell" style={{ width: "8%" }}>
                  Prix Unitaire TTC
                </th>
                <th className="ant-table-cell" style={{ width: "8%" }}>
                  Quantité
                </th>
                <th className="ant-table-cell" style={{ width: "8%" }}>
                  Remise
                </th>
                <th className="ant-table-cell" style={{ width: "5%" }}>
                  TVA
                </th>
                <th className="ant-table-cell" style={{ width: "8%" }}>
                  Prix Total
                </th>
              </tr>
            </thead>
            <tbody className="ant-table-tbody">
              {quoteItems &&
                quoteItems.map((item) =>
                  loading ? (
                    <Skeleton active loading={true} />
                  ) : (
                    <tr>
                      <td className="ant-table-cell" style={{ width: "8%" }}>
                        {item.article.s__reference}
                      </td>
                      <td className="ant-table-cell">
                        {item.article.s__secondTitle}
                      </td>
                      <td className="ant-table-cell" style={{ width: "12%" }}>
                        {item.d__unitPriceHT.toFixed(3)}
                      </td>
                      <td className="ant-table-cell" style={{ width: "12%" }}>
                        {item.d__unitPriceTTC.toFixed(3)}
                      </td>
                      <td className="ant-table-cell" style={{ width: "10%" }}>
                        {item.d__quantity}
                      </td>
                      <td className="ant-table-cell" style={{ width: "10%" }}>
                        {item.d__discount} %
                      </td>

                      <td className="ant-table-cell" style={{ width: "5%" }}>
                        {item.d__tva} %
                      </td>
                      <td className="ant-table-cell" style={{ float: "right" }}>
                        {item.d__total.toFixed(3)} Dt
                      </td>
                    </tr>
                  )
                )}
            </tbody>
          </table>
          <div style={{ textAlign: "right", marginTop: 20 }}>
            <div
              style={{
                display: "inline-block",
                textAlign: "right",
              }}
            >
              <p>
                <b>Tota HT</b> : {(quote.total - quote.tva).toFixed(3)} DT
              </p>
              <p>
                <b>TVA</b> : {quote.tva.toFixed(3)} DT
              </p>
              <p>
                <b>Remise Totale</b> : {quote.discount} %
              </p>
              <p>
                <b>Net à Payer</b> : {quote.total.toFixed(3)} DT
              </p>
            </div>
          </div>
        </>
      )}
    </Modal>
  );
};

export default QuoteModal;
