import {
  Button,
  Col,
  Form,
  Input,
  Modal,
  Popconfirm,
  Row,
  Typography,
  Skeleton,
} from "antd";
import { useState, useEffect } from "react";
import { toGetMarqueList, toDeleteMarque, toAddOrUpdateMarque } from "./Api";
import { BiPencil, BiTrash } from "react-icons/bi";
import moment from "moment";
import { errorMsg, successMsg } from "../../Utils/Utils";
import localization from "moment/locale/fr";
import "../../Styles/TwoTables.scss";
import { PlusOutlined } from "@ant-design/icons";

moment.updateLocale("fr", localization);

const Marque = () => {
  const [loading, setLoading] = useState(true);
  const [createModalVisible, setCreateModalVisible] = useState(false);
  const [marques, setMarques] = useState([]);
  const [category, setCategory] = useState("");
  const [categoryTecdoc, setCategoryTecdoc] = useState("");
  const [id, setId] = useState(-1);
  const [form] = Form.useForm();

  const closeCreateModal = () => {
    if (category == "") {
      errorMsg("veuillez remplir tous les champs obligatoires!");
      return;
    }
    toAddOrUpdateMarque(category, categoryTecdoc, id).then((res) => {
      successMsg(
        id != -1
          ? "Marque mis à jours avec succées!"
          : "Marque ajoutée avec succées!"
      );
      if (id == -1) {
        setMarques([...marques, res]);
      } else {
        const marqueIndex = marques.findIndex((m) => m.id == id);
        const newMarques = [...marques];
        newMarques[marqueIndex] = {
          ...marques[marqueIndex],
          ...res,
        };
        setMarques(newMarques);
      }
    });
    setId(-1);
    setCreateModalVisible(false);
    setCategory("");
    setCategoryTecdoc("");
  };

  useEffect(() => {
    toGetDatas();
  }, []);
  const toGetDatas = () => {
    toGetMarqueList().then((res) => {
      setMarques(res);
      setLoading(false);
    });
  };
  const toDelete = (id) => {
    toDeleteMarque(id).then((res) => {
      let newMarques = marques.filter((m) => m.id != id);
      setMarques(newMarques);
    });
  };

  const toEdit = (id, category, categoryTecdoc) => {
    setId(id);
    setCategoryTecdoc(categoryTecdoc);
    setCategory(category);
    form.setFieldsValue({ category: category, categoryTecdoc: categoryTecdoc });
    setCreateModalVisible(true);
  };
  const toAdd = () => {
    setId(-1);
    setCategory("");
    setCategoryTecdoc("");
    form.setFieldsValue({ category: "", categoryTecdoc: "" });

    setCreateModalVisible(true);
  };
  const exitCreateModal = () => {
    setId(-1);
    setCategory("");
    setCategoryTecdoc("");
    setCreateModalVisible(false);
  };

  return (
    <>
      <Row>
        <Col span="12">
          <Typography.Title level="2">Marques</Typography.Title>
        </Col>
        <Col span="12" style={{ textAlign: "right" }}>
          <Button
            icon={<PlusOutlined />}
            onClick={(e) => toAdd()}
            type="primary"
            size="large"
          >
            Ajouter une marque
          </Button>
        </Col>
      </Row>

      <Row
        style={{
          overflowY: "hidden",
        }}
      >
        <Col span="24">
          <div className="table-wrapper auto--overflow">
            <table className="ant-table" style={{ width: "100%" }}>
              <thead className="ant-table-thead">
                <tr>
                  <th className="ant-table-cell">Date création</th>
                  <th className="ant-table-cell">Catégorie</th>
                  <th className="ant-table-cell">Catégorie Tecdoc</th>
                  <th className="ant-table-cell">Crée par</th>
                  <th className="ant-table-cell"></th>
                </tr>
              </thead>
              <Skeleton active loading={loading} />

              <tbody className="ant-table-tbody ">
                {null != marques &&
                  marques.length > 0 &&
                  marques.map((marque, i) => (
                    <tr key={i}>
                      <td className="ant-table-cell">
                        {moment(marque.createdDate).format("D MMMM YYYY")}
                      </td>
                      <td className="ant-table-cell">{marque.title}</td>
                      <td className="ant-table-cell">{marque.titleTecdoc}</td>
                      <td className="ant-table-cell">{marque.createdBy}</td>
                      <td className="ant-table-cell" style={{ float: "right" }}>
                        <Button
                          onClick={() =>
                            toEdit(marque.id, marque.title, marque.titleTecdoc)
                          }
                          icon={<BiPencil />}
                        />

                        <Popconfirm
                          title={`Voulez vous vraiment supprimer ${marque.title}?`}
                          onConfirm={(e) => toDelete(marque.id)}
                          okText="Oui"
                          cancelText="Non"
                        >
                          <Button icon={<BiTrash />} />
                        </Popconfirm>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </Col>
      </Row>
      <Modal
        title={id != -1 ? "Modifier une marque" : "Ajouter une nouvelle marque"}
        visible={createModalVisible}
        onOk={closeCreateModal}
        onCancel={exitCreateModal}
      >
        <Form form={form} name="basic">
          <Form.Item
            label="Catégorie"
            name="category"
            value={category}
            rules={[{ required: true, message: "Champ obligatoire" }]}
          >
            <Input onChange={(e) => setCategory(e.target.value)} />
          </Form.Item>

          <Form.Item
            value={categoryTecdoc}
            label="Catégorie tecdoc"
            name="categoryTecdoc"
          >
            <Input onChange={(e) => setCategoryTecdoc(e.target.value)} />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default Marque;
