import Modal from "antd/lib/modal/Modal";
import { Button, Row, Col } from "antd";
import { toGetBe, toGetBonsReception, toPrintBR } from "../../APIs";
import { PrinterOutlined } from "@ant-design/icons";
import { toConvertDate } from "../../../../Utils/Utils";
import { useState, useEffect } from "react";

const BonEntreeModal = ({ bs, onClose }) => {
  const [bon, setBon] = useState(null);

  useEffect(() => {
    if (null != bs) {
      toGetBe(bs.id).then((res) => {
        setBon(res);
      });
    }
  }, [bs]);

  return (
    <Modal
      className="smtv-modal modal-large"
      visible={bs != null}
      onCancel={onClose}
      title="Details du bon de sortie"
      footer={[
        <Button key="close" onClick={onClose}>
          Fermer
        </Button>
      ]}
    >
      {bs && (
        <>
          <Row>
            <Col className="info-box" md={24}>
              <p>
                <b>Ref</b> : {bs.ref}
              </p>
              <p>
                <b>Date</b> : {toConvertDate(bs.date)}
              </p>

              <p>
                <b>Chargé client</b> : {bs.username && bs.username}
              </p>
            </Col>
          </Row>
          <h1 style={{ textAlign: "center", marginTop: "20px" }}>Articles</h1>
          <table className="ant-table" style={{ width: "100%" }}>
            <thead className="ant-table-thead">
              <tr>
                <th className="ant-table-cell" style={{ width: "8%" }}>
                  Ref
                </th>
                <th className="ant-table-cell">Désignation</th>

                <th className="ant-table-cell" style={{ width: "8%" }}>
                  Quantité
                </th>
              </tr>
            </thead>
            <tbody className="ant-table-tbody">
              {null != bon &&
                bon.items &&
                bon.items.map((item) => (
                  <tr>
                    <td className="ant-table-cell" style={{ width: "5%" }}>
                      {item.ref}
                    </td>

                    <td className="ant-table-cell" style={{ width: "8%" }}>
                      {item.article}
                    </td>
                    <td className="ant-table-cell" style={{ width: "12%" }}>
                      {item.quantity}
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </>
      )}
    </Modal>
  );
};

export default BonEntreeModal;
